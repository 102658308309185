import React, { useCallback, useRef, useState } from 'react'
import cls from './StaffSingleCard.module.scss'
import { Button } from 'primereact/button' 
import { Chip } from 'primereact/chip';
import { Dialog } from 'primereact/dialog';
import ReactCrop from 'react-image-crop';
import PermissionButton from '../Authorized/permission-button';


function StaffSingleCard({
    name,
    login,
    email,
    phone,
    img,
    roles,
    block,
    handleEdit,
    handleDelete,

}){

    const [openCropModal, setOpenCropModal] = useState(false)
    const [upImg, setUpImg] = useState();
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const imgRef = useRef(null);

    const onLoad = useCallback((img) => {
        imgRef.current = img;
      }, []);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    
    const handleCloseCroptModal = () => {
        setOpenCropModal(false)
    }

    const handleOpenCroptModal = () => {
        setOpenCropModal(true)
    }

    const customCropFooter = () => {
        return(
            <div className="p-d-flex p-jc-between">
                {
                    upImg?
                    <Button
                        icon="pi pi-refresh"
                        label="Заново"
                        className="p-button-text"
                        onClick={()=>setUpImg(null)}
                    />
                    :""
                }
                <div>
                    <Button
                        icon="pi pi-times"
                        label="Отмена"
                        className="p-button-text"
                        onClick={handleCloseCroptModal}
                    />
                    <Button
                        icon="pi pi-check"
                        label="Сохранить"
                    />
                </div>
            </div>
        )
    }

    return(
        <div className={`${cls.card} p-shadow-2 author-card`}>
            <div className="p-grid">
                <div className="p-col-12 p-sm-12 p-lg-3 p-md-3 p-p-0">
                    <div className={cls.thumb}>
                        <div 
                            className={cls.overlay} 
                            onClick={handleOpenCroptModal}
                        >
                            <span className="pi pi-upload"></span>
                        </div>
                        <img 
                            src={img} 
                            width="100%" 
                            height="100%" 
                            alt="record"
                        />
                    </div>
                </div>
                <div className={`p-col-12 p-sm-12 p-lg-9 p-md-9 ${cls.container}`}>
                    <div className={cls.header}>
                        <div className={cls.typography}>
                            <h1 className={cls.record}>{name}</h1>
                            {/* <h2 className={cls.author}>{roles.join(' , ')}</h2> */}
                            <div>
                                {
                                    roles && roles.length?
                                        roles.map((item,i) =>
                                            <Chip 
                                                label={item} 
                                                key={i}
                                                className="chip-title"
                                            />
                                        ):""
                                }
                            </div>
                        </div>
                        <div className={cls.actions}>
                            
                            <div className={cls.core}>
                                <PermissionButton
                                    icon="pi pi-pencil" 
                                    iconPos="right" 
                                    className="p-button-rounded p-button-outlined p-button-primary p-mr-2"
                                    tooltip="Изменить"
                                    block={block}
                                    permission_key={'edit'}
                                />
                                <PermissionButton
                                    icon="pi pi-trash" 
                                    iconPos="right" 
                                    className="p-button-rounded p-button-outlined p-button-danger"
                                    tooltip="Удалить"
                                    block={block}
                                    permission_key={'edit'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={cls.body}>
                        <dl className={cls.info}>
                            <dt>логин:</dt>
                            <dd>{login}</dd>
                        </dl>
                        <dl className={cls.info}>
                            <dt>email:</dt>
                            <dd>{email}</dd>
                        </dl>
                        <dl className={cls.info}>
                            <dt>номер телефона:</dt>
                            <dd>{phone}</dd>
                        </dl>
                    </div>
                </div>
            </div>
            <Dialog
                visible={openCropModal} 
                style={{ width: '70vw' }} 
                breakpoints={{'960px': '100vw'}}
                onHide={handleCloseCroptModal}
                header="Изменить каринку"
                footer={customCropFooter}
            >

                {
                    upImg?
                    <div className={cls.cropped_thumb}>
                        <ReactCrop
                            src={upImg}
                            onImageLoaded={onLoad}
                            crop={crop}
                            onChange={(c) => setCrop(c)}
                            onComplete={(c) => setCompletedCrop(c)}
                        />
                    </div>
                    :
                    <label 
                        className={cls.thumb_area} 
                        htmlFor="thumb-upload"
                    >
                        <input 
                            type="file" 
                            id="thumb-upload" 
                            name="thumb-upload"
                            onChange={onSelectFile}
                        />
                        <div className={cls.upload_thumb}>
                            <img 
                                src="/images/image-gallery.svg"
                                alt="thumb upload"
                                width={100}
                            />
                            <span className="p-my-2">Выберите картинку для фона</span>
                        </div>
                    </label>
                }
            </Dialog>
        </div>
    )
}

export default StaffSingleCard 