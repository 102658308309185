import { Navigate } from 'react-router'
import assetRoutes from './assets'
import authRoutes from './auth'
import authorRoutes from './authors'
import settingsRoutes from './settings'
import staffRoutes from './staff'

const routes = [
  {
    path: '',
    children: [
      {
        path: '',
        element: <Navigate to="/assets/records" />,
      },
      {
        path: 'redirect',
        element: '',
      },
      {
        path: '404',
        element: '',
      },
      {
        path: '*',
        element: <Navigate to="/assets/records" />,
      },
      {
        path: 'staff',
        element: 'staff',
      },
    ],
  },
  { ...authRoutes },
  { ...assetRoutes },
  { ...authorRoutes },
  { ...staffRoutes },
  { ...settingsRoutes },
]
export default routes
