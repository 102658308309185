import React from 'react'
import { useNavigate } from 'react-router'
import cls from './StaffCard.module.scss'
import { Card } from 'primereact/card';

const StaffCard = ({
    img = '/images/user.png',
    name = 'Otabek Nosirov',
    id = '1',
    roles = ['Админ', 'Модератор']
}) => {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate(`/staff/${id}`)
    }

    const header = (
        <img 
            alt="Card"
            width="100%" 
            height="100%"
            src={img} 
        />
    );

    return(
        <div className={cls.card} onClick={handleClick}>
            <Card 
                title={name}
                subTitle={ roles.join(' , ') } 
                // style={{ width: '25em' }} 
                header={header}
            >
            </Card>
        </div>
    )
}

export default StaffCard