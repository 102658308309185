import React from 'react'
import cls from './RecordCard.module.scss'
import { useNavigate } from 'react-router';

function RecordCard({
    name,
    singer,
    composer,
    author,
    isrc,
    asset_yt
}){
    
    const navigate = useNavigate()
    const handleNavigate = () => {
        navigate(`/assets/records/${name}`)
    }

    return(
        <div className={`${cls.card} p-shadow-3`} onClick={handleNavigate}>
            <span className={cls.song}>{name}</span>
            <span className={cls.singer}>{singer}</span>
            <span className={cls.composer}>
                <span className="p-mx-2 p-mt-1">
                    <img src="/images/music.svg" width={12} alt={name}/>
                </span>
                {composer}
                <span className="p-ml-1 pi pi-check-circle checked-icon"></span>
            </span>
            <span className={cls.composer}>
                <span className="p-mx-2 p-mb-1 pi pi-pencil"></span>
                {author}
            </span>
            <div className={cls.infos}>
                <span >{isrc}</span>
                <span >{asset_yt}</span>
            </div>
        </div>
    )
}

export default RecordCard