import React, { useCallback, useRef, useState } from 'react'
import cls from './AuthorCard.module.scss'
import { Button } from 'primereact/button' 
import { Chip } from 'primereact/chip';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch'
import ReactCrop from 'react-image-crop';
import PermissionButton from '../Authorized/permission-button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';

function AuthorCard({
    nickname,
    fullName,
    added_at, 
    records,
    roles,
    block,
    handleDelete,
    person_id,
    contract,
    setData,
    handleEdit,
}){
    const [statuses] = useState([
        "Оплачен",
        "В ожидании",
        "В работе",
        "Отклонено",
        "Перенесена",
    ])
    const [openReportModal, setOpenReportModal] = useState(false)
    const [openCropModal, setOpenCropModal] = useState(false)
    const [form, setForm] = useState({
        nickname:"",
        payment:{
            date:"2021-09-09",
            amount:"",
            card_number:""
        },
        date:'',
        document:"",
        status:"",
        notification: true,
        attachments:""

    })
    const [upImg, setUpImg] = useState();
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [isEdit,setEdit] = useState(false)
    const imgRef = useRef(null);

    const levels = [
        "Исполнитель",
        "Артист",
        "Композитор",
        "Автор строк"
    ]

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
        console.log(completedCrop);
    };

    const confirmDelete = () => {
        confirmDialog({
            message: 'Вы уверены в ваших действий?',
            header: 'Подтверждение',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Удалить',
            rejectLabel: 'Отмена',
            accept: () => handleDelete(),
            reject: () => {}
        });
    };

    const handleEditInfo = () => {
        setEdit(true)
    }

    const handleSaveInfo = () => {
        setEdit(false)
    }

    const handleCloseReportModal = () => {
        setOpenReportModal(false)
    }

    const handleOpenReportModal = () => {
        setOpenReportModal(true)
    }

    const handleCloseCroptModal = () => {
        setOpenCropModal(false)
    }

    const handleOpenCroptModal = () => {
        setOpenCropModal(true)
    }

    const handleChangeReportForm = (e) => {
        setForm(
            {
                ...form,
                [e.target.name]:e.target.value
            }
        )
    }

    const handleChangeReportPayment = (e) => {
        setForm(
            {
                ...form,
                payment:{
                    ...form.payment,
                    [e.target.name]: e.target.value
                }
            }
        )
    }

    const handleChange = (e) => {
        const {name,value} = e.target
        setData(name,value)
    }

    const customFooter = () => {
        return(
            <div>
                <Button
                    icon="pi pi-times"
                    label="Отмена"
                    className="p-button-text"
                    onClick={handleCloseReportModal}
                />
                <Button
                    icon="pi pi-check"
                    label="Создать"
                />
            </div>
        )
    }

    const customCropFooter = () => {
        return(
            <div className="p-d-flex p-jc-between">
                {
                    upImg?
                    <Button
                        icon="pi pi-refresh"
                        label="Заново"
                        className="p-button-text"
                        onClick={()=>setUpImg(null)}
                    />
                    :""
                }
                <div>
                    <Button
                        icon="pi pi-times"
                        label="Отмена"
                        className="p-button-text"
                        onClick={handleCloseCroptModal}
                    />
                    <Button
                        icon="pi pi-check"
                        label="Сохранить"
                    />
                </div>
            </div>
        )
    }

    return(
        <div className={`${cls.card} p-shadow-2 author-card`}>
            <div className="p-grid">
                <div className="p-col-12 p-sm-12 p-lg-3 p-md-3 p-p-0" >
                    <div className={cls.thumb}>
                        <div 
                            className={cls.overlay} 
                            onClick={handleOpenCroptModal}
                        >
                            <span className="pi pi-upload"></span>
                        </div>
                        <img 
                            src="/images/weeknd.jpeg" 
                            width="100%" 
                            height="100%" 
                            alt="record"
                        />
                    </div>
                </div>
                <div className={`p-col-12 p-sm-12 p-lg-9 p-md-9 ${cls.container}`}>
                    <div className={cls.header}>
                        <div className={cls.typography}>
                            {
                                isEdit?
                                <span className="p-float-label">
                                    <InputText 
                                        name={'nickname'} 
                                        value={nickname} 
                                        onChange={handleChange} 
                                        id="nickname"
                                    />
                                    <label htmlFor="nickname">Название</label>
                                </span>:
                                <h1 className={cls.record}>{nickname}</h1>    
                            }
                            {
                                isEdit
                                ?
                                <span className="p-float-label p-my-3">
                                    <InputText 
                                        name={'fullName'} 
                                        value={fullName} 
                                        onChange={handleChange} 
                                        id="fullName"
                                    />
                                    <label htmlFor="fullName">Артист</label>
                                </span>
                                :
                                <h2 className={cls.author}>{fullName}</h2>
                            }
                            {
                                isEdit?
                                <MultiSelect 
                                    value={roles} 
                                    options={levels} 
                                    onChange={handleChange} 
                                    name="roles"
                                    placeholder="Выбирайте роль" 
                                    // display="chip" 
                                    maxSelectedLabels={2}
                                    selectedItemsLabel="{0} выбранных ролей"
                                />
                                :
                                <div>
                                    {
                                        roles && roles.length?
                                            roles.map((item,i) =>
                                                <Chip 
                                                    label={item} 
                                                    key={i}
                                                    className="chip-title"
                                                />
                                            ):""
                                    }
                                </div>
                            }
                        </div>
                        <div className={cls.actions}>
                            
                            <div className={cls.core}>
                            {
                                !(records && records.length>0)?
                            <>
                                <PermissionButton
                                    className="p-button-rounded p-button-outlined p-button-success p-d-flex p-jc-center "
                                    tooltip="Добавить отчет"
                                    icon="pi pi-plus-circle"
                                    onClick={handleOpenReportModal}
                                    block={block}
                                    permission_key = "create_report"
                                />
                                <PermissionButton
                                    className="p-button-rounded p-button-outlined p-button-primary p-d-flex p-jc-center "
                                    tooltip="Посмотреть отчет"
                                    icon="pi pi-eye"
                                    block={block}
                                    permission_key = "read_report"
                                />
                                <Button 
                                    className="p-button-rounded p-button-outlined p-button-primary p-d-flex p-jc-center "
                                    tooltip="Отправить сообщение "
                                    icon="pi pi-send"
                                />
                            </>
                            :''
                            }
                                <PermissionButton
                                    icon={!isEdit ? "pi pi-pencil" :"pi pi-save"}
                                    iconPos="right" 
                                    className="p-button-rounded p-button-outlined p-button-primary p-mr-2"
                                    tooltip={isEdit ? "Сохранить" :"Изменить"}
                                    block = {block}
                                    permission_key = "edit"
                                    onClick={isEdit ? handleSaveInfo : handleEditInfo }
                                />
                                <PermissionButton
                                    icon="pi pi-trash" 
                                    iconPos="right" 
                                    className="p-button-rounded p-button-outlined p-button-danger"
                                    tooltip="Удалить"
                                    block = {block}
                                    permission_key = "delete"
                                    onClick={confirmDelete}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={cls.body}>
                        {
                            added_at?
                            <dl className={cls.info}>
                                <dt>добавлен:</dt>
                                <dd>{added_at}</dd>
                            </dl>   
                            :''
                        }
                        {
                            person_id?
                            <dl className={cls.info}>
                                <dt>Person ID:</dt>
                                <dd>{person_id}</dd>
                            </dl>   
                            :''
                        }
                        {
                            contract?
                            <dl className={cls.info}>
                                <dt>Контракт:</dt>
                                <dd>{contract}</dd>
                            </dl>   
                            :''
                        }
                    </div>
                </div>
            </div>
            <Dialog
                visible={openReportModal} 
                style={{ width: '80vw' }} 
                breakpoints={{'960px': '100vw'}}
                onHide={handleCloseReportModal}
                header="Добавить отчёт"
                footer={customFooter}
            >
                <div className="p-grid p-mt-2">
                    <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3" >
                        <span className="p-float-label">
                            <InputText 
                                name={'nickname'} 
                                value={form.nickname} 
                                onChange={handleChangeReportForm} 
                                id="report-nickname"
                            />
                            <label htmlFor="report-nickname">Псевдоним</label>
                        </span>
                    </div>
                    <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3" >
                        <span className="p-float-label">
                            <InputText 
                                type="date"
                                name={'date'} 
                                value={form.payment.date} 
                                onChange={handleChangeReportPayment}
                                placeholder={'Дата оплаты'}
                                id="report-payment-date"
                            />
                            <label htmlFor="report-payment-date">Дата оплаты</label>
                        </span>
                    </div>
                    <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3" >
                        <span className="p-float-label">
                            <InputText 
                                name={'amount'} 
                                value={form.payment.amount} 
                                onChange={handleChangeReportPayment}
                                id="report-payment-amount"
                            />
                            <label htmlFor="report-payment-amount">Сумма</label>
                        </span>
                    </div>
                    <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
                        <span className="p-float-label">
                            <InputMask 
                                mask="9999-9999-9999" 
                                name="card_number"
                                value={form.payment.card_number} 
                                onChange={handleChangeReportPayment}
                            ></InputMask>
                            <label htmlFor="middle_name">Номер карты</label>
                        </span>
                    </div>
                    <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3 p-d-flex p-ai-center p-jc-between">
                        <span className="p-float-label" style={{width: '100%'}}>
                            <Dropdown 
                                value={form.status} 
                                name={'status'} 
                                options={statuses} 
                                onChange={handleChangeReportForm}
                                placeholder="Статусы"
                                style={{
                                    padding: '7.5px',
                                    width: '100%'
                                }}
                            />
                        </span>
                    </div>
                    <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3 p-d-flex p-ai-center p-jc-between" >
                        <span>Уведомления</span>
                        <InputSwitch 
                            checked={form.notification} 
                            className="p-mx-2"
                            name="notification"
                            onChange={handleChangeReportForm}
                        />
                    </div>
                    <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3 p-d-flex p-ai-center p-jc-between" >
                        <Calendar 
                            id="range" 
                            value={form.date} 
                            onChange={handleChangeReportForm} 
                            selectionMode="range" 
                            name="date"
                            placeholder="Период"
                            readOnlyInput 
                            mask="dd.mm.yyyy"
                            icon="pi pi-calendar"
                            showIcon
                            dateFormat="dd.mm.yy"
                            className="p-pb-0"
                            style={{
                                width:'100%'
                            }}
                        />
                    </div>
                    <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3 p-d-flex p-ai-center p-jc-between p-mt-2 p-pt-2" >
                        <input
                            type={"file"}
                            // value={item['file'].value} 
                            name="attachments" 
                            onChange={handleChangeReportForm} 
                            id="attachments"
                            className="modal-file"
                        />
                        <label 
                            className="modal-upload"
                            htmlFor="attachments"
                        >
                            <span className="pi pi-upload p-mr-1"></span>
                            <span>{
                                form.attachments ?
                                    form.attachments
                                : 'Загрузить'
                            }</span>
                        </label>
                    </div>
                </div>   
            </Dialog>
            <Dialog
                visible={openCropModal} 
                style={{ width: '70vw' }} 
                breakpoints={{'960px': '100vw'}}
                onHide={handleCloseCroptModal}
                header="Изменить каринку"
                footer={customCropFooter}
            >

                {
                    upImg?
                    <div className={cls.cropped_thumb}>
                        <ReactCrop
                            src={upImg}
                            onImageLoaded={onLoad}
                            crop={crop}
                            onChange={(c) => setCrop(c)}
                            onComplete={(c) => setCompletedCrop(c)}
                        />
                    </div>
                    :
                    <label 
                        className={cls.thumb_area} 
                        htmlFor="thumb-upload"
                    >
                        <input 
                            type="file" 
                            id="thumb-upload" 
                            name="thumb-upload"
                            onChange={onSelectFile}
                        />
                        <div className={cls.upload_thumb}>
                            <img 
                                src="/images/image-gallery.svg"
                                alt="thumb upload"
                                width={100}
                            />
                            <span className="p-my-2">Выберите картинку для фона</span>
                        </div>
                    </label>
                }
            </Dialog>
        </div>
    )
}

export default AuthorCard  