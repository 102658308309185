import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { MultiSelect } from 'primereact/multiselect'
import { useNavigate } from 'react-router'
import { requests } from '../../../../services/requests'
import { labels, labels_arr } from '../../../../constants/consts'
import FilterTab from '../../../ui/filter'
import RecordCard from '../../../ui/RecordCard'
import Box from '../../../ui/box'
import { Calendar } from 'primereact/calendar'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../../redux/actions/commonReducer'

function Records() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [singer, setSinger] = useState('')
  const [composer, setComposer] = useState('')
  const [author, setAuthors] = useState('')
  const [label, setLabel] = useState('')
  const [date, setDate] = useState('')
  const [isGrid, setGrid] = useState(true)
  const [selectedLabel, setSelectedLabel] = useState()
  const [selectedRelease, setSelectedRelease] = useState()
  const [selectedCreate, setSelectedCreate] = useState()
  const authors = [{ name: 'Otabek Nosirov' }, { name: 'The weeknd' }]

  const [columns, setColumns] = useState([
    {
      value: 'title',
      label: 'Название',
      body: '',
      active: true,
      attrs: {
        filter: true,
        filterPlaceholder: 'Поиск по названию',
        filterMatchMode: 'contains',
      },
    },
    {
      value: 'authors',
      label: 'Исполнители',
      body: (r) => ColumnSinger(r),
      active: true,
      attrs: {
        filter: true,
        filterPlaceholder: 'Поиск по исполнительям',
        filterMatchMode: 'contains',
      },
    },
    // {
    //     value:"author",
    //     label:"Композиторы",
    //     body: (r) => ColumnComposer(r),
    //     active:true
    // },
    // {
    //     value:"author",
    //     label:"Автор текста",
    //     body: (r) => ColumnAuthor(r),
    //     active:true
    // },
    {
      value: 'label',
      label: 'Лейблы',
      body: (r) => ColumnLabel(r),
      active: true,
      attrs: {
        filter: true,
        filterPlaceholder: 'Поиск по label',
        filterField: 'col.label',
      },
    },
    {
      value: 'isrc',
      label: 'ISRC',
      body: '',
      active: true,
      attrs: {},
    },
    {
      value: 'youtube_id',
      label: 'Youtube ID',
      body: '',
      active: true,
      attrs: {},
    },
    {
      value: 'id',
      label: 'ASSET ID',
      body: '',
      active: true,
      attrs: {},
    },
    {
      value: 'created_at',
      label: 'Дата добавления',
      body: '',
      active: true,
      attrs: {
        filter: true,
        filterPlaceholder: 'Поиск по дате релиза',
      },
    },
    {
      value: 'release_date',
      label: 'Дата релиза',
      body: '',
      active: true,
      attrs: {
        filter: true,
        filterPlaceholder: 'Поиск по label',
      },
    },
  ])
  const [records, setRecords] = useState([])

  useEffect(() => {
    dispatch(setLoading(true))
    requests.partner
      .getAssetsList()
      .then((res) => {
        dispatch(setLoading(false))
        console.log(res.data)
        setRecords([...res.data])
      })
      .catch((err) => {
        dispatch(setLoading(false))
        console.log(err)
      })
  }, [])

  const handleChangeLabel = (e) => {
    setSelectedLabel(e.value)
  }

  const handleChangeRelease = (e) => (forCreate) => {
    if (forCreate) setSelectedCreate(e.value)
    else setSelectedRelease(e.value)
  }

  const handleNavigate = (e) => {
    navigate(`/assets/records/${e.value.id}`)
  }

  const Checked = () => {
    return <span className="pi pi-check-circle checked-icon"></span>
  }

  const ColumnLabel = (rowData) => {
    return (
      <React.Fragment>
        <span>{labels[rowData.label]}</span>
      </React.Fragment>
    )
  }

  const ColumnSinger = (rowData) => {
    return (
      <React.Fragment className="table-column">
        <Checked />
        <span>{rowData.authors}</span>
      </React.Fragment>
    )
  }

  const renderLabelFilter = () => {
    return (
      <MultiSelect
        className="p-column-filter"
        value={selectedLabel}
        options={labels_arr}
        placeholder="Label"
        optionLabel="name"
        optionValue="value"
        onChange={handleChangeLabel}
      />
    )
  }

  const renderReleaseDate = () => {
    return (
      <Calendar
        value={selectedRelease}
        onChange={handleChangeRelease}
        placeholder="Дата релиза"
        dateFormat="dd.mm.yy"
        className="p-column-filter"
      />
    )
  }

  const renderCreateDate = () => {
    return (
      <Calendar
        value={selectedCreate}
        onChange={handleChangeRelease(true)}
        placeholder="Дата создания"
        dateFormat="dd.mm.yy"
        className="p-column-filter"
      />
    )
  }

  const FilterLabel = renderLabelFilter()
  const FilterCreateDate = renderCreateDate()
  const FilterReleaseDate = renderReleaseDate()

  return (
    <Box allow={true}>
      <div className="grid">
        <FilterTab
          singer={singer}
          composer={composer}
          author={author}
          label={label}
          isGrid={isGrid}
          setSinger={setSinger}
          setComposer={setComposer}
          setAuthor={setAuthors}
          setLabel={setLabel}
          setGrid={setGrid}
          authors={authors}
          composers={authors}
          labels={labels}
          singers={authors}
          columns={columns}
          setColumns={setColumns}
          date={date}
          setDate={setDate}
        />
      </div>
      <div className="grid p-grid">
        {isGrid ? (
          <div className="datatable-responsive-demo p-shadow-3 p-my-3">
            <div className="card">
              <DataTable
                value={records}
                paginator
                rows={10}
                stripedRows
                className="p-datatable-responsive-demo"
                selectionMode="single"
                onSelectionChange={handleNavigate}
                dataKey="id"
              >
                {columns.map(
                  (col, i) =>
                    col.active && (
                      <Column
                        field={col.value}
                        header={col.label}
                        body={col.body}
                        {...col.attrs}
                        filterElement={
                          col.value === 'release_date'
                            ? FilterReleaseDate
                            : col.value === 'label'
                            ? FilterLabel
                            : col.value === 'created_at'
                            ? FilterCreateDate
                            : null
                        }
                        key={i}
                      />
                    )
                )}
              </DataTable>
            </div>
          </div>
        ) : (
          records.map((rec, r) => (
            <div className="p-col-6 p-sm-4 p-md-3 p-lg-2" key={r}>
              <RecordCard
                name={rec.title}
                singer={rec.author}
                composer={rec.author}
                author={rec.author}
                isrc={rec.isrc}
                asset_yt={rec.yt_asset}
              />
            </div>
          ))
        )}
      </div>
    </Box>
  )
}

export default Records
