import { authRequest, request } from "../api"

const pay_api = `api/v1/payments/`

export const payment = {
    // International Cards API
    getInterList: () => authRequest.get(`${pay_api}card_international/`) ,
    getSingelInter: (id) => authRequest.get(`${pay_api}card_international/${id}/`) ,
    deleteInter: (id) => authRequest.delete(`${pay_api}card_international/${id}/`) ,
    createInter: (
        card_number,
        expiry_date,
        card_holder,
        card_type,
        currency,
        bank,
        person,
        partner
    ) => 
        authRequest.post(`${pay_api}card_international/`, {
            card_number,
            expiry_date,
            card_holder,
            card_type,
            currency,
            bank,
            person,
            partner
        }),
    putInter: (
        id,
        card_number,
        expiry_date,
        card_holder,
        card_type,
        currency,
        bank,
        person,
        partner
    ) => 
        authRequest.put(`${pay_api}card_international/${id}/`, {
            card_number,
            expiry_date,
            card_holder,
            card_type,
            currency,
            bank,
            person,
            partner
    }),
    patchInter: (
        id,
        card_number,
        expiry_date,
        card_holder,
        card_type,
        currency,
        bank,
        person,
        partner
    ) => 
        authRequest.patch(`${pay_api}card_international/${id}/`, {
            card_number,
            expiry_date,
            card_holder,
            card_type,
            currency,
            bank,
            person,
            partner
    }),

    // Local Cards API
    getLocalList: () => authRequest.get(`${pay_api}card_local/`) ,
    getSingleLocal: (id) => authRequest.get(`${pay_api}card_local/${id}/`) ,
    deleteLocal: (id) => authRequest.delete(`${pay_api}card_local/${id}/`) ,
    createLocal: (
        card_number,
        expiry_date,
        card_holder,
        card_type,
        currency,
        bank,
        person,
        partner
    ) => 
        authRequest.post(`${pay_api}card_local/`, {
            card_number,
            expiry_date,
            card_holder,
            card_type,
            currency,
            bank,
            person,
            partner
    }),  
    putLocal: (
        id,
        card_number,
        expiry_date,
        card_holder,
        card_type,
        currency,
        bank,
        person,
        partner
    ) => 
        authRequest.put(`${pay_api}card_local/${id}/`, {
            card_number,
            expiry_date,
            card_holder,
            card_type,
            currency,
            bank,
            person,
            partner
    }),
    patchLocal: (
        id,
        card_number,
        expiry_date,
        card_holder,
        card_type,
        currency,
        bank,
        person,
        partner
    ) => 
        authRequest.patch(`${pay_api}card_local/${id}/`, {
            card_number,
            expiry_date,
            card_holder,
            card_type,
            currency,
            bank,
            person,
            partner
    }),

    // Swift API
    getSwiftList: () => authRequest.get(`${pay_api}swift/`) ,
    getSingleSwift: (id) => authRequest.get(`${pay_api}swift/${id}/`) ,
    deleteSwiftCard: (id) => authRequest.delete(`${pay_api}swift/${id}/`) ,
    createSwift: (
        account_holder,
        swift_code,
        bio_code,
        bank,
        correspondent_bank,
        correspondent_swift_code,
        person,
        partner,
    ) => 
        authRequest.post(`${pay_api}swift/`, {
            account_holder,
            swift_code,
            bio_code,
            account_holder,
            bank,
            correspondent_bank,
            correspondent_swift_code,
            person,
            partner,
    }),
    putSwift: (
        id,
        account_holder,
        swift_code,
        bio_code,
        bank,
        correspondent_bank,
        correspondent_swift_code,
        person,
        partner,
    ) => 
        authRequest.put(`${pay_api}swift/${id}/`, {
            account_holder,
            swift_code,
            bio_code,
            account_holder,
            bank,
            correspondent_bank,
            correspondent_swift_code,
            person,
            partner, 
    }),
    patchSwift: (
        id,
        account_holder,
        swift_code,
        bio_code,
        bank,
        correspondent_bank,
        correspondent_swift_code,
        person,
        partner,
    ) => 
        authRequest.patch(`${pay_api}swift/${id}/`, {
            account_holder,
            swift_code,
            bio_code,
            account_holder,
            bank,
            correspondent_bank,
            correspondent_swift_code,
            person,
            partner, 
    }),

    // Details API
    getDetailList: () => authRequest.get(`${pay_api}details/`) ,
    getSingleDetail: (id) => authRequest.get(`${pay_api}details/${id}/`) ,
    deleteDetail: (id) => authRequest.delete(`${pay_api}details/${id}/`) ,
    createDetail: (
        amount,
        for_month,
        payment_date,
        notification,
        status,
        comment,
        person,
        partner
    ) => 
        authRequest.post(`${pay_api}details/`, {
            amount,
            for_month,
            payment_date,
            notification,
            status,
            comment,
            person,
            partner
    }),
    putDetail: (
        id,
        amount,
        for_month,
        payment_date,
        notification,
        status,
        comment,
        person,
        partner
    ) => 
        authRequest.put(`${pay_api}details/${id}/`, {
            amount,
            for_month,
            payment_date,
            notification,
            status,
            comment,
            person,
            partner
    }),
    patchDetail: (
        id,
        amount,
        for_month,
        payment_date,
        notification,
        status,
        comment,
        person,
        partner
    ) => 
        authRequest.patch(`${pay_api}details/${id}/`, {
            amount,
            for_month,
            payment_date,
            notification,
            status,
            comment,
            person,
            partner
        }),
}