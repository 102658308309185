import { authRequest } from '../api'

const acc_api = `api/v1/accounts/`

export const account = {
  // User API
  getAllUsers: () => authRequest.get(`${acc_api}user/`),
  getUser: (id) => authRequest.get(`${acc_api}user/${id}/`),
  deleteUser: (id) => authRequest.delete(`${acc_api}user${id}/`),
  createUser: () => authRequest.post(`${acc_api}user/`),
  putUser: () => authRequest.put(`${acc_api}user/`),
  patchUser: () => authRequest.patch(`${acc_api}user/`),

  // Partner API
  getAllPartners: () => authRequest.get(`${acc_api}partner/`),
  getPartner: (id) => authRequest.get(`${acc_api}partner/${id}/`),
  deletePartner: (id) => authRequest.delete(`${acc_api}partner/${id}/`),
  createPartner: (username, password, title, payment_method) =>
    authRequest.post(`${acc_api}partner/`, {
      username,
      password,
      title,
      payment_method,
    }),
  putPartner: (id, username, password, title, payment_method) =>
    authRequest.put(`${acc_api}partner/${id}/`, {
      username,
      password,
      title,
      payment_method,
    }),
  patchPartner: (id, username, password, title, payment_method) =>
    authRequest.patch(`${acc_api}partner/${id}/`, {
      username,
      password,
      title,
      payment_method,
    }),

  // Person API
  getAllPersons: () => authRequest.get(`${acc_api}person/`),
  getPerson: (id) => authRequest.get(`${acc_api}person/${id}/`),
  deletePerson: (id) => authRequest.delete(`${acc_api}partner/${id}/`),
  createPerson: (data) => authRequest.post(`${acc_api}person/`, data),
  putPerson: (data) => authRequest.put(`${acc_api}person/${data.id}/`, data),
  patchPerson: (data) =>
    authRequest.patch(`${acc_api}person/${data.id}/`, data),
  // Person Info API
  getAllPersonalInfos: () => authRequest.get(`${acc_api}personal_info/`),
  getPersonalInfo: (id) => authRequest.get(`${acc_api}personal_info/${id}/`),
  deletePersonalInfo: (id) =>
    authRequest.delete(`${acc_api}personal_info/${id}/`),
  createPersonalInfo: (data) =>
    authRequest.post(`${acc_api}personal_info/${data.id}/`, data),
  putPersonalInfo: (data) =>
    authRequest.put(`${acc_api}personal_info/${data.id}/`, data),
  patchPersonalInfo: (data) =>
    authRequest.patch(`${acc_api}personal_info/${data.id}/`, data),

  // Person Telegram Account API
  getAllTgAccounts: () => authRequest.get(`${acc_api}person/telegram_account/`),
  getTgAccount: (id) =>
    authRequest.get(`${acc_api}person/telegram_account/${id}/`),
  deleteTgAccount: (id) =>
    authRequest.delete(`${acc_api}person/telegram_account/${id}/`),
  createTgAccount: (id, username, password, is_active) =>
    authRequest.post(`${acc_api}person/telegram_account/${id}/`, {
      id,
      username,
      password,
      is_active,
    }),
  putTgAccount: (id, username, password, is_active) =>
    authRequest.put(`${acc_api}person/telegram_account/${id}/`, {
      id,
      username,
      password,
      is_active,
    }),
  patchTgAccount: (id, username, password, is_active) =>
    authRequest.patch(`${acc_api}person/telegram_account/${id}/`, {
      id,
      username,
      password,
      is_active,
    }),
}
