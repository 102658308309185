import React, { useCallback, useRef, useState } from 'react'
import cls from './AssetCard.module.scss'
import { Button } from 'primereact/button' 
import { Chip } from 'primereact/chip';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import ReactCrop from 'react-image-crop';
import RecordsTable from '../table';
import AudioPlayer from 'react-h5-audio-player';
import PermissionButton from '../Authorized/permission-button';
import { confirmDialog } from 'primereact/confirmdialog';

function AssetCard({
    record,
    author,
    added_at,
    nv_asset,
    yt_asset,
    released_at,
    records,
    roles,
    block,
    handleDelete,
    setData,
    handleEdit,
}){
    const [openModal,setOpenModal] = useState(false)
    const [openCropModal, setOpenCropModal] = useState(false)
    const [audioSrc,setAudioSrc] = useState('')
    const [upImg, setUpImg] = useState();
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [isEdit,setEdit] = useState(false)
    const imgRef = useRef(null);
    let ref = useRef(null)

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
        console.log(completedCrop);
    };

    const confirmDelete = () => {
        confirmDialog({
            message: 'Вы уверены в ваших действий?',
            header: 'Подтверждение',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Удалить',
            rejectLabel: 'Отмена',
            accept: () => handleDelete(),
            reject: () => {}
        });
    };

    const handleEditInfo = () => {
        setEdit(true)
    }

    const handleSaveInfo = () => {
        setEdit(false)
    }
    const handleCloseCroptModal = () => {
        setOpenCropModal(false)
    }

    const handleOpenCroptModal = () => {
        setOpenCropModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleOnPlay = arg_ref =>(e) => {
        arg_ref.current.audio.current.play()
    }

    const handleChange = (e) => {
        const {name,value} = e.target
        setData(name,value)
    }

    const customHeader = () => {
        return(
            <div>
                <span><b>The Weeknd</b></span> - <span>Blinding Lights</span>
            </div>
        )
    }

    const customCropFooter = () => {
        return(
            <div className="p-d-flex p-jc-between">
                {
                    upImg?
                    <Button
                        icon="pi pi-refresh"
                        label="Заново"
                        className="p-button-text"
                        onClick={()=>setUpImg(null)}
                    />
                    :""
                }
                <div>
                    <Button
                        icon="pi pi-times"
                        label="Отмена"
                        className="p-button-text"
                        onClick={handleCloseCroptModal}
                    />
                    <Button
                        icon="pi pi-check"
                        label="Сохранить"
                    />
                </div>
            </div>
        )
    }

    return(
        <div className={`${cls.card} p-shadow-2 author-card`}>
            <div className="p-grid">
                <div className="p-col-12 p-sm-12 p-lg-3 p-md-3 p-p-0" >
                    <div className={cls.thumb}>
                        <div 
                            className={cls.overlay} 
                            onClick={handleOpenCroptModal}
                        >
                            <span className="pi pi-upload"></span>
                        </div>
                        <img 
                            src="/images/weeknd.jpeg" 
                            width="100%" 
                            height="100%" 
                            alt="record"
                        />
                    </div>
                </div>
                <div className={`p-col-12 p-sm-12 p-lg-9 p-md-9 ${cls.container}`}>
                    <div className={cls.header}>
                        <div className={cls.typography}>
                            {
                                isEdit
                                ?
                                <span className="p-float-label">
                                    <InputText 
                                        name={'record'} 
                                        value={record} 
                                        onChange={handleChange} 
                                        id="record"
                                    />
                                    <label htmlFor="report-nickname">Название</label>
                                </span>
                                :
                                <h1 className={cls.record}>{record}</h1>
                            }
                            {
                                isEdit
                                ?
                                <span className="p-float-label p-mt-3">
                                    <InputText 
                                        name={'author'} 
                                        value={author} 
                                        onChange={handleChange} 
                                        id="author"
                                    />
                                    <label htmlFor="author">Артист</label>
                                </span>
                                :
                                <h2 className={cls.author}>{author}</h2>
                            }
                            <div>
                                {
                                    roles && roles.length?
                                        roles.map((item,i) =>
                                            <Chip 
                                                label={item} 
                                                key={i}
                                                className="chip-title"
                                            />
                                        )
                                    :""
                                }
                            </div>
                        </div>
                        <div className={cls.actions}>
                            <div className={cls.core}>
                                <PermissionButton
                                    icon={!isEdit ? "pi pi-pencil" :"pi pi-save"}
                                    iconPos="right" 
                                    className="p-button-rounded p-button-outlined p-button-primary p-mr-2"
                                    tooltip={isEdit ? "Сохранить" :"Изменить"}
                                    block = {block}
                                    permission_key = "edit"
                                    onClick={isEdit ? handleSaveInfo : handleEditInfo }
                                />
                                <PermissionButton
                                    icon="pi pi-trash" 
                                    iconPos="right" 
                                    className="p-button-rounded p-button-outlined p-button-danger"
                                    tooltip="Удалить"
                                    block = {block}
                                    permission_key = "delete"
                                    onClick={confirmDelete}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={cls.body}>
                        {
                            added_at?
                            <dl className={cls.info}>
                                <dt>добавлен:</dt>
                                <dd>{added_at}</dd>
                            </dl>   
                            :''
                        }
                        {
                            nv_asset?
                            <dl className={cls.info}>
                                <dt>asset id (nv):</dt>
                                <dd>{nv_asset}</dd>
                            </dl>    
                            :''
                        }
                        {
                            yt_asset?
                            <dl className={cls.info}>
                                <dt>asset id (YT):</dt>
                                <dd>{yt_asset}</dd>
                            </dl>   
                            :''
                        }
                        {
                            released_at?
                            <dl className={cls.info}>
                                <dt>релиз:</dt>
                                <dd>{released_at}</dd>
                            </dl>   
                            :''
                        }
                    </div>
                </div>
            </div>
            {
                records && records.length>0?
                    <div className="p-grid">
                        <RecordsTable 
                            handleCloseModal = {handleCloseModal}
                            handleOpenModal = {handleOpenModal}
                            audioSrc = {audioSrc}
                            setAudioSrc = {setAudioSrc}
                            data={records}
                            audioRef={ref}
                            handlePlay={handleOnPlay}
                        />
                    </div>:""
            }
            <Dialog 
                visible={openModal} 
                style={{ width: '50vw' }} 
                breakpoints={{'960px': '100vw'}}
                header={customHeader}
                onHide={handleCloseModal}
                modal
                resizable
                draggable
                position="bottom-left"
            >
                <AudioPlayer
                    src={audioSrc}
                    ref={ref}
                    autoPlay
                />
            </Dialog>
            <Dialog
                visible={openCropModal} 
                style={{ width: '70vw' }} 
                breakpoints={{'960px': '100vw'}}
                onHide={handleCloseCroptModal}
                header="Изменить каринку"
                footer={customCropFooter}
            >

                {
                    upImg?
                    <div className={cls.cropped_thumb}>
                        <ReactCrop
                            src={upImg}
                            onImageLoaded={onLoad}
                            crop={crop}
                            onChange={(c) => setCrop(c)}
                            onComplete={(c) => setCompletedCrop(c)}
                        />
                    </div>
                    :
                    <label 
                        className={cls.thumb_area} 
                        htmlFor="thumb-upload"
                    >
                        <input 
                            type="file" 
                            id="thumb-upload" 
                            name="thumb-upload"
                            onChange={onSelectFile}
                        />
                        <div className={cls.upload_thumb}>
                            <img 
                                src="/images/image-gallery.svg"
                                alt="thumb upload"
                                width={100}
                            />
                            <span className="p-my-2">Выберите картинку для фона</span>
                        </div>
                    </label>
                }
            </Dialog>
        </div>
    )
}

export default AssetCard  