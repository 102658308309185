import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Password } from 'primereact/password';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenModalStaff } from '../../../redux/actions/commonReducer';
import PermissionTable from '../permission-table';
 

const DialogStaff = () => {
    const [form,setForm] = useState({
        name: '',
        login: '',
        email: '',
        phone: '',
        password: '',
        img:'',
        permissions: [
            {
                label: "Блок авторов",
                permissions: [
                    {
                        label: 'Просмотр',
                        key: 'read',
                        value: true
                    },
                    {
                        label: 'Просмотр паспортные данные ',
                        key: 'read_passport',
                        value: false
                    },
                    {
                        label: 'Просмотр контракта',
                        key: 'read_contract',
                        value: false
                    },
                    {
                        label: 'Просмотр аналитику',
                        key: 'read_analytics',
                        value: true
                    },
                    {
                        label: 'Создать',
                        key: 'create',
                        value: false
                    },
                    {
                        label: 'Редактировать',
                        key: 'edit',
                        value: false
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        value: false
                    },
                ]
            },
            {
                label: "Блок объектов",
                permissions: [
                    {
                        label: 'Просмотр',
                        key: 'read',
                        value: true
                    },
                    {
                        label: 'Просмотр аналитику',
                        key: 'read_analytics',
                        value: true
                    },
                    {
                        label: 'Создать',
                        key: 'create',
                        value: false
                    },
                    {
                        label: 'Редактировать',
                        key: 'edit',
                        value: false
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        value: false
                    },
                ]
            },
            {
                label: "Блок партнеров",
                permissions: [
                    {
                        label: 'Просмотр',
                        key: 'read',
                        value: true
                    },
                    {
                        label: 'Создать',
                        key: 'create',
                        value: false
                    },
                    {
                        label: 'Редактировать',
                        key: 'edit',
                        value: false
                    },
                    {
                        label: 'Принимать отчет',
                        key: 'accept',
                        value: false
                    },
                ]
            },
            {
                label: "Блок пользователей",
                permissions: [
                    {
                        label: 'Просмотр',
                        key: 'read',
                        value: true
                    },
                    {
                        label: 'Просмотр отчетов',
                        key: 'read_report',
                        value: false
                    },
                    {
                        label: 'Создать',
                        key: 'create',
                        value: false
                    },
                    {
                        label: 'Редактировать',
                        key: 'edit',
                        value: false
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        value: false
                    },
                ]
            },
        ]
    }) 
    const openModal = useSelector(store => store.common.openModalStaff)
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(setOpenModalStaff(false))
    }

    const handleChange = (e) => {
        let item = {...form}
        item[e.target.name] = e.target.value
        setForm({...item})
    }

    const handleChangePermission = (idx, per , value) => {
        let items = [...form.permissions]
        items[idx].permissions[per].value = value
        setForm({
            ...form,
            permissions:[...items]
        })
    }
    const renderFooter = () => {
        return (
            <>
                <Button 
                    label="Отмена" 
                    icon="pi pi-times" 
                    onClick={handleClose} 
                    className="p-button-text" 
                />
                <Button 
                    label="Сохранить" 
                    icon="pi pi-save" 
                    onClick={handleClose} 
                    autoFocus 
                />
            </>
        );
    }

    return(
        <>
            <Dialog
                header={'Добавление сотрудника'} 
                footer={renderFooter}
                visible={openModal} 
                onHide={handleClose} 
                breakpoints={{'960px': '75vw'}} 
                style={{width: '90vw'}} 
                closable={false}
            >
                <div className="p-grid p-mt-2">
                    <div className="p-col-12">
                        <h3>Персональные данные</h3>
                    </div>
                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-d-flex p-jc-center">
                        <label className="create-staff_img" htmlFor="create-staff-img" style={{
                            cursor: 'pointer'
                        }}>
                            <img src="/images/user.png" width={200}/>
                        </label>
                        <input 
                            id="create-staff-img" 
                            type="file"
                            className="p-d-none"
                        />
                    </div>
                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                        <div className="p-grid">
                            <div className="p-col-12 p-sm-6 p-md-4 p-lg-3">
                                <span className="p-float-label">
                                    <InputText 
                                        id="name" 
                                        value={form.name} 
                                        onChange={handleChange} 
                                        name="name"
                                    />
                                    <label htmlFor="name">ФИО</label>
                                </span>
                            </div>
                            <div className="p-col-12 p-sm-6 p-md-4 p-lg-3">
                                <span className="p-float-label">
                                    <InputText 
                                        id="login" 
                                        value={form.login} 
                                        onChange={handleChange} 
                                        name="login"
                                    />
                                    <label htmlFor="login">Логин</label>
                                </span>
                            </div>
                            <div className="p-col-12 p-sm-6 p-md-4 p-lg-3">
                                <span className="p-float-label">
                                    <InputText 
                                        id="email" 
                                        value={form.email} 
                                        onChange={handleChange} 
                                        type="email"
                                        name="email"
                                    />
                                    <label htmlFor="email">Email</label>
                                </span>
                            </div>
                            <div className="p-col-12 p-sm-6 p-md-4 p-lg-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <p className="">+998</p>
                                    </span>
                                    <InputMask 
                                        mask="(99)-999 9999" 
                                        id="phone" 
                                        value={form.phone} 
                                        onChange={handleChange}
                                        name="phone"
                                    >
                                    </InputMask>
                                </div>
                            </div>
                            <div className="p-col-12 p-sm-6 p-md-6 p-lg-3">
                                <span className="p-float-label">
                                    <Password 
                                        value={form.password} 
                                        name={"password"} 
                                        onChange={handleChange} 
                                        id={"password"}
                                        required
                                        type="password"
                                        toggleMask
                                        feedback={false}
                                        className="p-w-100"
                                    />
                                    <label htmlFor={'password'}>Пароль</label>
                                </span>
                            </div>
                        </div>    
                    </div>
                </div>
                <div className="p-grid p-mt-3">
                    <div className="p-col-12">
                        <h3>Разрешения</h3>
                    </div>  
                    <div className="p-col-12">
                        <PermissionTable 
                            data={form.permissions} 
                            handleChange={handleChangePermission}
                        />
                    </div>  
                </div>
            </Dialog>
        </>
    )
}

export default DialogStaff