import React, { useEffect, useRef, useState } from 'react'
import cls from './AuthNavbar.module.scss'
import { Menubar } from 'primereact/menubar'
import { Button } from 'primereact/button'
import { nav_items } from './const'
import { useDispatch, useSelector } from 'react-redux'
import { OverlayPanel } from 'primereact/overlaypanel'
import {
  setOpenModal,
  setOpenModalSinger,
  setOpenModalStaff,
} from '../../../../redux/actions/commonReducer'
import { getPermission } from '../../../../utils/permission'
import LanguagePicker from '../../../ui/LanguagePicker/LanguagePicker'
import Box from '../../../ui/box'
import { NavLink } from 'react-router-dom'

function AuthNavbar() {
  const dispatch = useDispatch()
  const op = useRef(null)
  const actions = [
    {
      key: 'author',
      name: 'Артист',
      onClick: () => {
        dispatch(setOpenModalSinger(true))
      },
    },
    {
      key: 'asset',
      name: 'Объект',
      onClick: () => {
        dispatch(setOpenModal(true))
      },
    },
    {
      key: 'staff',
      name: 'Сотрудник',
      onClick: () => {
        dispatch(setOpenModalStaff(true))
      },
    },
  ]
  const [types, setTypes] = useState([])
  const permissions = useSelector((store) => store.common.permissions)

  useEffect(() => {
    let items = []
    actions.map((item) => {
      if (getPermission(permissions, item.key, 'create')) items.push(item)
    })
    setTypes([...items])
  }, [permissions])

  const Overlay = () => {
    return (
      <OverlayPanel
        ref={op}
        id="overlay_panel"
        style={{ width: '200px' }}
        className="overlaypanel-demo"
      >
        <div className="overlay-list">
          {types.map((item, i) => (
            <span key={i} onClick={item.onClick}>
              {item.name}
            </span>
          ))}
        </div>
      </OverlayPanel>
    )
  }

  return (
    <nav className={cls.nav}>
      <Box className="navbar-menu" allow={true}>
        <Menubar
          model={nav_items}
          className={cls.menu}
          start={
            <NavLink to="/" className={cls.brand}>
              nevo
            </NavLink>
          }
          end={types.length > 0 ? <Actions op={op} /> : ''}
        />
      </Box>
      {Overlay()}
    </nav>
  )
}

function Actions({ op }) {
  const handleOpen = (e) => {
    op.current.toggle(e)
  }

  return (
    <div className={cls.actions}>
      <Button
        label="Создать"
        icon="pi pi-plus"
        iconPos="left"
        className="p-p-2"
        onClick={handleOpen}
      />
      <LanguagePicker />
    </div>
  )
}

export default AuthNavbar
