import { QueryClientProvider, QueryClient } from 'react-query'
import { Provider } from 'react-redux'
import { useStore } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import i18n from './i18n'
import { I18nextProvider } from 'react-i18next'

function Providers({children}){

    const queryClient = new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
      })
    
    const store = useStore()
    const persistor = persistStore(store)
    
    return(
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <QueryClientProvider client={queryClient}>
                        {children}
                    </QueryClientProvider>
                </PersistGate>
            </Provider>
        </I18nextProvider>
    )
}

export default Providers