import RecordItem from '../components/pages/assets/record/item'
import Records from '../components/pages/assets/record'
import AuthorizedLayout from '../components/layouts/Authorized/index'

const assetRoutes = {
  path: 'assets',
  element: <AuthorizedLayout />,
  children: [
    {
      path: 'records',
      children: [
        {
          path: '*',
          element: <RecordItem block="asset" />,
        },
        {
          path: '',
          element: <Records block="asset" />,
        },
      ],
    },
  ],
}

export default assetRoutes
