import React, { useEffect, useRef, useState } from 'react'
import AuthorCard from '../../../ui/AuthorCard'
import { RecordFaker } from '../../assets/record/const'
import { Panel } from 'primereact/panel'
import { Ripple } from 'primereact/ripple'
import { InputText } from 'primereact/inputtext'
import { Chip } from 'primereact/chip'
import { Button } from 'primereact/button'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Dropdown } from 'primereact/dropdown'
import { InputMask } from 'primereact/inputmask'
import { FileUpload } from 'primereact/fileupload'
import { Dialog } from 'primereact/dialog'
import HistoryTable from '../../../ui/histroy-table'
import { getPermission } from '../../../../utils/permission'
import { useSelector } from 'react-redux'
import { confirmDialog } from 'primereact/confirmdialog'
import PermissionButton from '../../../ui/Authorized/permission-button'
import Box from '../../../ui/box'

function SingerItem(props) {
  const { block } = props

  const item = RecordFaker

  const op = useRef(null)

  const [contract_types] = useState(['UzAvtor', 'NEVO'])

  const [payment_types] = useState([
    {
      name: 'UzAvtor',
      title: 'Банковский перевод S.W.I.F.T.',
      value: 'swift',
      fields: [
        {
          label: 'Владелец банковского счета',
          name: 'owner',
          value: '',
        },
        {
          label: 'Банк',
          name: 'bank',
          value: '',
        },
        {
          label: 'SWIFT',
          name: 'swift_number',
          value: '',
        },
        {
          label: 'Владелец банковского счета',
          name: 'owner',
          value: '',
        },
        {
          label: 'БИК',
          name: 'bik',
          value: '',
        },
        {
          label: 'Номер счета',
          name: 'check_number',
          value: '',
        },
      ],
      disabled: true,
    },
    {
      name: '',
      title: 'Master Card',
      value: 'master_card',
      fields: [
        {
          label: 'Владелец банковского счета',
          name: 'owner',
          value: '',
        },
        {
          label: 'Банк',
          name: 'bank',
          value: '',
        },
        {
          label: 'Срок действия карты',
          name: 'expires_date',
          value: '',
        },
        {
          label: 'Валюта карты',
          name: 'card_currency',
          value: '',
        },
        {
          label: 'Номер карты',
          name: 'card_number',
          value: '',
        },
      ],
      disabled: true,
    },
  ])

  const [payments] = useState([
    {
      name: 'Банковский перевод S.W.I.F.T.',
      value: 'swift',
    },
    {
      name: 'Master Card',
      value: 'master_card',
    },
  ])

  const [contract_templ] = useState({
    type: 'UzAvtor',
    number: {
      value: '',
      name: 'number',
      title: 'Номер контракта',
      type: 'string',
    },
    deal_date: {
      value: '22.10.2021',
      name: 'deal_date',
      title: 'Дата заключение',
      type: 'date',
    },
    condition: {
      value: 65,
      name: 'condition',
      title: 'Условия',
      type: 'number',
    },
    file: {
      template: '',
      uploaded: '',
    },
    requisite: payment_types[0],
    disabled: true,
  })

  const [form, setForm] = useState({
    contracts: [contract_templ],
    passport_data: {
      first_name: '',
      last_name: '',
      middle_name: '',
      gender: '',
      birth_date: '28.10.1999',
      citizenship: '',
      document_type: '',
      passport_serie: '',
      given_date: '28.10.1999',
      given_by: '',
      address: '',
      inn: '',
      passport_copy: '',
      other_doc: ['/images/pass.jpeg', '/images/pass.jpeg'],
      disabled: true,
    },
    requisites: [contract_templ.requisite],
    contact_data: {
      cell_phone: '',
      home_phone: '',
      email: '',
      manager_phone: '',
      disabled: true,
    },
    account_data: {
      login: '',
      password: '',
      email: '',
      activity: {
        number: '+998998154828',
        is_active: false,
      },
      disabled: true,
    },
  })

  const [genders] = useState(['Мужчина', 'Женщина'])

  const [citizenships] = useState([
    'Узбекистан',
    'Казахстан',
    'Россия',
    'Украина',
  ])

  const [doc_types] = useState([
    'Пасспорт Узбекистана',
    'Пасспорт Казахстана',
    'Пасспорт России',
    'Пасспорт Украины',
  ])

  const [showDialog, setShowDialog] = useState(false)

  const roles = ['Исполнитель', 'Композитор', 'Автор строк']

  const [data, setData] = useState({
    nickname: 'The Weeknd',
    fullName: 'Abel Makkonen Tesfaye',
    roles: roles,
  })

  const handleActive = (field, id) => (e) => {
    let frm = { ...form }
    if (id >= 0) {
      let obj = frm[field][id]
      obj = { ...obj, disabled: !obj.disabled }
      frm[field][id] = obj
      setForm({ ...frm })
    } else {
      let obj = frm[field]
      obj = { ...obj, disabled: !obj.disabled }
      frm[field] = obj
      setForm({ ...frm })
    }
  }

  const confirm = (key) => (e) => {
    let res = {
      message: 'Вы уверены в ваших действий?',
      header: 'Подтверждение',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Удалить',
      rejectLabel: 'Отмена',
      accept: () => {
        switch (key) {
          case 'account':
            deleteAccount()
            return
          case 'contact':
            deleteContact()
            return
          case 'passport':
            deletePassport()
            return
        }
      },
      reject: () => {},
    }

    confirmDialog({ ...res })
  }

  const deletePassport = () => {
    console.log('passport')
  }

  const deleteAccount = () => {
    console.log('account')
  }

  const deleteContact = () => {
    console.log('contact')
  }

  const deleteInfo = () => {
    console.log('info')
  }

  const handleChange =
    (field, id, addtnl = null, child_id = null) =>
    (e) => {
      let { name, value } = e.target
      let obj = null
      let frm = { ...form }

      if (name === 'condition' && (value > 100 || value < 0)) return
      if (!addtnl) {
        if (id >= 0) {
          obj = frm[field][id]
          obj = {
            ...obj,
            [name]: {
              ...obj[name],
              value,
            },
          }
          frm[field][id] = obj
        } else {
          obj = frm[field]
          obj = {
            ...obj,
            [name]: value,
          }
          frm[field] = obj
        }
      } else {
        obj = frm[field][id][addtnl][child_id]
        obj = {
          ...obj,
          value,
        }
        frm[field][id][addtnl][child_id] = obj
      }

      setForm({ ...frm })
    }

  const handleAddContract = (type) => (e) => {
    let frm = { ...form }
    let templ = { ...contract_templ }
    let reqst = { ...templ.requisite }

    templ.type = type
    reqst.name = type

    frm.contracts.push(templ)
    frm.requisites.push(reqst)

    setForm({ ...frm })
  }

  const handleDeleteContract = (id) => (_) => {
    let frm = form
    let items = frm.contracts.filter((_, i) => i !== id)
    let reqsts = frm.requisites.filter((_, i) => i !== id)

    frm.contracts = [...items]
    frm.requisites = [...reqsts]

    setForm({ ...frm })
  }

  const handleChangePayment = (id, label_name) => (e) => {
    const { value } = e
    let frm = { ...form }
    let reqsts = [...frm.requisites]
    let pymnt = null
    let pymnt_types = payment_types

    pymnt_types.map((it, _) => {
      if (it.value === value) pymnt = it

      return it
    })
    pymnt.disabled = false
    pymnt.name = label_name
    reqsts[id] = { ...pymnt }

    setForm({ ...form, requisites: [...reqsts] })
  }

  const handleChangeData = (name, value) => {
    setData({ ...data, [name]: value })
  }

  const toggleOverlay = (e) => {
    op.current.toggle(e)
  }

  const onUploadDocs = (e) => {
    console.log(e)
  }

  const onSelectDocs = (e) => {
    console.log(e)
  }

  const ContractFields = () => {
    return (
      <>
        {form.contracts.map((item, i) => (
          <div className="p-grid p-px-2" key={i}>
            <div className="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
              <span>{item.type}</span>
            </div>
            <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
              <span className="p-float-label">
                <InputText
                  type={item['number'].type}
                  value={item['number'].value}
                  name={item['number'].name}
                  onChange={handleChange('contracts', i)}
                  id={item['number'].name}
                  disabled={item.disabled}
                />
                <label htmlFor={item['number'].name}>
                  {item['number'].title}
                </label>
              </span>
            </div>
            <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
              <span className="p-float-label">
                <InputText
                  type={item['deal_date'].type}
                  value={item['deal_date'].value}
                  name={item['deal_date'].name}
                  onChange={handleChange('contracts', i)}
                  id={item['deal_date'].name}
                  disabled={item.disabled}
                />
                <label htmlFor={item['deal_date'].name}>
                  {item['deal_date'].title}
                </label>
              </span>
            </div>
            <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-2">
              <span className="p-float-label">
                <InputText
                  type={item['condition'].type}
                  value={item['condition'].value}
                  name={item['condition'].name}
                  onChange={handleChange('contracts', i)}
                  max={100}
                  id={item['condition'].name}
                  disabled={item.disabled}
                />
                <label htmlFor={item['condition'].name}>
                  {item['condition'].title}
                </label>
              </span>
            </div>
            <div className="p-field p-col-12 p-sm-6 p-md-6 p-lg-3">
              <div className="attached-files">
                <div className="p-grid">
                  <div className="p-col-5 p-d-flex p-jc-center">
                    <a
                      href={item['file'].template}
                      download
                      className="p-mr-2 template-download"
                    >
                      <Chip label={'Шаблон'} className="chip-title" />
                    </a>
                  </div>
                  <div className="p-col-7">
                    {item['file'].uploaded ? (
                      <a
                        href={item['file'].template}
                        download
                        className="template-download"
                      >
                        <Chip label={'Шаблон'} className="chip-title" />
                      </a>
                    ) : (
                      <>
                        <input
                          type={'file'}
                          value={item['file'].value}
                          name={item['file'].name}
                          onChange={handleChange('contracts', i)}
                          id={`upload-` + i}
                          className="modal-file"
                          disabled={item['file'].disabled}
                        />
                        <label className="modal-upload" htmlFor={`upload-` + i}>
                          <span className="pi pi-upload p-mr-1"></span>
                          <span>
                            {item['file'].value
                              ? item['file'].value
                              : 'Загрузить'}
                          </span>
                        </label>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="p-field p-col-12 p-sm-6 p-md-6 p-lg-1 p-d-flex">
              <PermissionButton
                icon={item.disabled ? 'pi pi-pencil' : 'pi pi-save'}
                tooltip={item.disabled ? 'Изменить' : 'Сохранить'}
                iconPos="right"
                className="p-button-outlined p-button-primary p-mr-2"
                onClick={handleActive('contracts', i)}
                block={block}
                permission_key="edit_contract"
              />
              <PermissionButton
                icon="pi pi-trash"
                iconPos="right"
                className="p-button-outlined p-button-danger"
                tooltip="Удалить"
                onClick={handleDeleteContract(i)}
                block={block}
                permission_key="delete_contract"
              />
            </div>
          </div>
        ))}
      </>
    )
  }

  const PassportDatas = () => {
    return (
      <>
        {' '}
        <div className="p-grid p-px-2 passport-data">
          <div className="p-field p-col-12 p-sm-11 p-md-11 p-lg-11">
            <div className="p-grid p-my-1">
              <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
                <span className="p-float-label">
                  <InputText
                    value={form['passport_data'].first_name}
                    name={'first_name'}
                    onChange={handleChange('passport_data')}
                    id="first_name"
                    disabled={form['passport_data'].disabled}
                  />
                  <label htmlFor="first_name">Имя</label>
                </span>
              </div>
              <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
                <span className="p-float-label">
                  <InputText
                    value={form['passport_data'].last_name}
                    name={'last_name'}
                    onChange={handleChange('passport_data')}
                    id="last_name"
                    disabled={form['passport_data'].disabled}
                  />
                  <label htmlFor="last_name">Фамилия</label>
                </span>
              </div>
              <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
                <span className="p-float-label">
                  <InputText
                    value={form['passport_data'].middle_name}
                    name={'middle_name'}
                    onChange={handleChange('passport_data')}
                    id="middle_name"
                    disabled={form['passport_data'].disabled}
                  />
                  <label htmlFor="middle_name">Отчество</label>
                </span>
              </div>
              <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
                <span className="p-float-label">
                  <InputText
                    value={form['passport_data'].address}
                    name={'address'}
                    onChange={handleChange('passport_data')}
                    id="address"
                    disabled={form['passport_data'].disabled}
                  />
                  <label htmlFor="address">Адрес</label>
                </span>
              </div>
            </div>
            <div className="p-grid p-my-1">
              <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
                <span className="p-float-label">
                  <InputText
                    type="date"
                    value={form['passport_data'].birth_date}
                    name={'birth_date'}
                    onChange={handleChange('passport_data')}
                    id="birth_date"
                    disabled={form['passport_data'].disabled}
                  />
                  <label htmlFor="birth_date">Дата рождения</label>
                </span>
              </div>
              <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
                <span className="p-float-label">
                  <Dropdown
                    value={form['passport_data'].gender}
                    name={'gender'}
                    options={genders}
                    onChange={handleChange('passport_data')}
                    placeholder="Пол"
                    disabled={form['passport_data'].disabled}
                  />
                </span>
              </div>
              <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
                <span className="p-float-label">
                  <Dropdown
                    value={form['passport_data'].citizenship}
                    name="citizenship"
                    options={citizenships}
                    onChange={handleChange('passport_data')}
                    placeholder="Гражданство"
                    disabled={form['passport_data'].disabled}
                  />
                </span>
              </div>
              <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
                <span className="p-float-label">
                  <Dropdown
                    value={form['passport_data'].document_type}
                    name="document_type"
                    options={doc_types}
                    onChange={handleChange('passport_data')}
                    placeholder="Тип документа"
                    disabled={form['passport_data'].disabled}
                  />
                </span>
              </div>
            </div>
            <div className="p-grid p-my-1">
              <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
                <span className="p-float-label">
                  <InputMask
                    mask="aa9999999"
                    name="passport_serie"
                    value={form['passport_data'].passport_serie}
                    onChange={handleChange('passport_data')}
                    disabled={form['passport_data'].disabled}
                  ></InputMask>
                  <label htmlFor="middle_name">Серия пасспорта </label>
                </span>
              </div>
              <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
                <span className="p-float-label">
                  <InputText
                    type="date"
                    value={form['passport_data'].given_date}
                    name={'given_date'}
                    onChange={handleChange('passport_data')}
                    id="given_date"
                    disabled={form['passport_data'].disabled}
                  />
                  <label htmlFor="given_date">Дата выдачи документа</label>
                </span>
              </div>
              <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
                <span className="p-float-label">
                  <InputMask
                    mask="999 999 999"
                    value={form['passport_data'].inn}
                    name={'inn'}
                    onChange={handleChange('passport_data')}
                    disabled={form['passport_data'].disabled}
                  ></InputMask>
                  <label htmlFor="inn">ИНН</label>
                </span>
              </div>
              <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
                <span className="p-float-label">
                  <InputText
                    value={form['passport_data'].given_by}
                    name={'given_by'}
                    onChange={handleChange('passport_data')}
                    id="given_by"
                    disabled={form['passport_data'].disabled}
                  />
                  <label htmlFor="given_by">Кем выдан</label>
                </span>
              </div>
            </div>
            <div className="p-grid p-my-1">
              <div className="p-field p-col-12 p-sm-6 p-md-6 p-lg-6">
                <input
                  type={'file'}
                  // value={form['passport_data'].passport_copy}
                  name={'passport_copy'}
                  onChange={handleChange('passport_data')}
                  className="modal-file"
                  disabled={form['passport_data'].disabled}
                  id="passport_copy"
                />
                <label
                  className={
                    form['passport_data'].passport_copy
                      ? 'modal-docs_has'
                      : 'modal-docs'
                  }
                  htmlFor="passport_copy"
                >
                  {form['passport_data'].passport_copy ? (
                    <img
                      src={form['passport_data'].passport_copy}
                      className="passport-data_img"
                      alt="passport"
                    />
                  ) : (
                    <>
                      <img
                        src="/images/id-card.png"
                        alt="passport"
                        width={60}
                      />
                      <span>Скан-копия пасспорта</span>
                    </>
                  )}
                </label>
              </div>
              <div className="p-field p-col-12 p-sm-6 p-md-6 p-lg-6">
                <div
                  className={
                    form['passport_data'].other_doc.length > 0 &&
                    form['passport_data'].other_doc[0]
                      ? 'modal-docs_has'
                      : 'modal-docs'
                  }
                  onClick={() => setShowDialog(true)}
                >
                  {form.passport_data.other_doc.length > 0 &&
                  form['passport_data'].other_doc[0] ? (
                    form.passport_data.other_doc.map((item, i) => (
                      <img
                        src={item}
                        className="passport-data_img"
                        alt="passport"
                        key={i}
                      />
                    ))
                  ) : (
                    <>
                      <img
                        src="/images/document.png"
                        alt="passport"
                        width={60}
                      />
                      <span>Документы</span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="p-field p-col-12 p-sm-1 p-md-1 p-lg-1s p-d-flex p-ai-end">
            <PermissionButton
              icon={
                form['passport_data'].disabled ? 'pi pi-pencil' : 'pi pi-save'
              }
              tooltip={
                form['passport_data'].disabled ? 'Изменить' : 'Сохранить'
              }
              iconPos="right"
              className="p-button-outlined p-button-primary p-mr-2"
              onClick={handleActive('passport_data')}
              block={block}
              permission_key="edit_passport"
              style={{
                maxHeight: '50px',
              }}
            />
            <PermissionButton
              icon="pi pi-trash"
              iconPos="right"
              className="p-button-outlined p-button-danger"
              tooltip="Удалить"
              onClick={confirm('passport')}
              block={block}
              permission_key="delete_passport"
              style={{
                maxHeight: '50px',
              }}
            />
          </div>
        </div>
      </>
    )
  }

  const Requisites = () => {
    return (
      <>
        {form.requisites?.map((item, i) => (
          <div className="p-mt-3" key={i}>
            <CustomPanel
              title={`Реквизиты (${item.name})`}
              block={block}
              per_key="read_contract"
            >
              <div className="p-grid p-px-2">
                <div className="p-field p-col-12 p-sm-12 p-md-11 p-lg-11">
                  <div className="p-grid">
                    <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
                      <span className="p-float-label">
                        <Dropdown
                          value={item.value}
                          name={item.title}
                          options={payments}
                          optionLabel="name"
                          optionValue="value"
                          onChange={handleChangePayment(i, item.name)}
                          placeholder="Тип оплаты"
                          disabled={item.disabled}
                        />
                      </span>
                    </div>
                    {item.fields.map((fl, f) => (
                      <div
                        className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3"
                        key={f}
                      >
                        <span className="p-float-label">
                          <InputText
                            value={fl.value}
                            name={fl.name}
                            onChange={handleChange(
                              'requisites',
                              i,
                              'fields',
                              f
                            )}
                            id={`pay-${fl.name}`}
                            disabled={item.disabled}
                          />
                          <label htmlFor={`pay-${fl.name}`}>{fl.label}</label>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="p-field p-col-12 p-sm-12 p-md-1 p-lg-1 p-d-flex p-ai-end">
                  <Button
                    icon={item.disabled ? 'pi pi-pencil' : 'pi pi-save'}
                    tooltip={item.disabled ? 'Изменить' : 'Сохранить'}
                    iconPos="right"
                    className="p-button-outlined p-button-primary p-mr-2"
                    style={{
                      maxHeight: '50px',
                    }}
                    onClick={handleActive('requisites', i)}
                  />
                  <Button
                    icon="pi pi-trash"
                    iconPos="right"
                    className="p-button-outlined p-button-danger"
                    tooltip="Удалить"
                    style={{
                      maxHeight: '50px',
                    }}
                    // onClick={handleDeleteContract(i)}
                  />
                </div>
              </div>
            </CustomPanel>
          </div>
        ))}
      </>
    )
  }

  const ContactDatas = () => {
    return (
      <div className="p-grid p-px-2">
        <div className="p-field p-col-12 p-sm-12 p-md-11 p-lg-11">
          <div className="p-grid">
            <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
              <span className="p-float-label">
                <InputText
                  value={form['contact_data'].cell_phone}
                  name={'cell_phone'}
                  onChange={handleChange('contact_data')}
                  id="cell_phone"
                  disabled={form['contact_data'].disabled}
                />
                <label htmlFor="cell_phone">Номер телефона(мобильный)</label>
              </span>
            </div>
            <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
              <span className="p-float-label">
                <InputText
                  value={form['contact_data'].home_phone}
                  name={'home_phone'}
                  onChange={handleChange('contact_data')}
                  id="home_phone"
                  disabled={form['contact_data'].disabled}
                />
                <label htmlFor="home_phone">Номер телефона(домашний)</label>
              </span>
            </div>
            <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
              <span className="p-float-label">
                <InputText
                  value={form['contact_data'].email}
                  name={'email'}
                  onChange={handleChange('contact_data')}
                  id="email"
                  disabled={form['contact_data'].disabled}
                />
                <label htmlFor="email">Email</label>
              </span>
            </div>
            <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
              <span className="p-float-label">
                <InputText
                  value={form['contact_data'].manager_phone}
                  name={'manager_phone'}
                  onChange={handleChange('contact_data')}
                  id="manager_phone"
                  disabled={form['contact_data'].disabled}
                />
                <label htmlFor="manager_phone">Номер менеджера</label>
              </span>
            </div>
          </div>
        </div>
        <div className="p-field p-col-12 p-sm-12 p-md-1 p-lg-1 p-d-flex p-ai-end">
          <PermissionButton
            icon={form['contact_data'].disabled ? 'pi pi-pencil' : 'pi pi-save'}
            tooltip={form['contact_data'].disabled ? 'Изменить' : 'Сохранить'}
            iconPos="right"
            className="p-button-outlined p-button-primary p-mr-2"
            style={{
              maxHeight: '50px',
            }}
            onClick={handleActive('contact_data')}
            block={block}
            permission_key="edit_contact"
          />
          <PermissionButton
            icon="pi pi-trash"
            iconPos="right"
            className="p-button-outlined p-button-danger"
            tooltip="Удалить"
            style={{
              maxHeight: '50px',
            }}
            block={block}
            permission_key="delete_contact"
            onClick={confirm('contact')}
          />
        </div>
      </div>
    )
  }

  const AccountData = () => {
    return (
      <div className="p-grid p-px-2">
        <div className="p-field p-col-12 p-sm-12 p-md-11 p-lg-11">
          <div className="p-grid">
            <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
              <span className="p-float-label">
                <InputText
                  value={form['account_data'].login}
                  name={'login'}
                  onChange={handleChange('account_data')}
                  id="login"
                  disabled={form['account_data'].disabled}
                />
                <label htmlFor="login">Логин</label>
              </span>
            </div>
            <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
              <span className="p-float-label">
                <InputText
                  value={form['account_data'].password}
                  name={'password'}
                  onChange={handleChange('account_data')}
                  id="password"
                  disabled={form['account_data'].disabled}
                />
                <label htmlFor="password">Пароль</label>
              </span>
            </div>
            <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
              <span className="p-float-label">
                <InputText
                  value={form['account_data'].email}
                  name={'email'}
                  type="email"
                  onChange={handleChange('account_data')}
                  id="email_account"
                  disabled={form['account_data'].disabled}
                />
                <label htmlFor="email_account">Email</label>
              </span>
            </div>
            <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3 p-mb-0 p-pt-0 p-d-flex p-jc-start p-flex-column">
              <span className="active-title">Активность</span>
              <div>
                <Chip
                  label={form['account_data'].activity.number}
                  className="chip chip-success"
                />
                {!form['account_data'].activity.number ? (
                  <Chip label={'Неактивен'} className="chip chip-danger" />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="p-field p-col-12 p-sm-12 p-md-1 p-lg-1 p-d-flex p-ai-end">
          <PermissionButton
            icon={form['account_data'].disabled ? 'pi pi-pencil' : 'pi pi-save'}
            tooltip={form['account_data'].disabled ? 'Изменить' : 'Сохранить'}
            iconPos="right"
            className="p-button-outlined p-button-primary p-mr-2"
            style={{
              maxHeight: '50px',
            }}
            onClick={handleActive('account_data')}
            block={block}
            permission_key="edit_account"
          />
          <PermissionButton
            icon="pi pi-trash"
            iconPos="right"
            className="p-button-outlined p-button-danger"
            tooltip="Удалить"
            style={{
              maxHeight: '50px',
            }}
            block={block}
            permission_key="delete_account"
            onClick={confirm('account')}
          />
        </div>
      </div>
    )
  }

  const Overlay = () => {
    return form.requisites.length < 2 ? (
      <OverlayPanel
        ref={op}
        id="overlay_panel"
        style={{ width: '200px' }}
        className="overlaypanel-demo"
      >
        <div className="overlay-list">
          {contract_types.map((item, i) =>
            form.requisites.length > 0 && item !== form.requisites[0].name ? (
              <span key={i} onClick={handleAddContract(item)}>
                {item}
              </span>
            ) : form.requisites.length === 0 ? (
              <span key={i} onClick={handleAddContract(item)}>
                {item}
              </span>
            ) : (
              ''
            )
          )}
        </div>
      </OverlayPanel>
    ) : (
      ''
    )
  }

  return (
    <Box block={block} permission_key="read">
      <div className="p-mt-3">
        <div className="p-mt-3">
          <AuthorCard
            nickname={data.nickname}
            fullName={data.fullName}
            added_at={item.added_at}
            person_id="159958581650"
            contract="C-004655"
            roles={data.roles}
            block={block}
            handleDelete={deleteInfo}
            setData={handleChangeData}
            // nv_asset={item.nv_asset}
            // yt_asset={item.yt_asset}
            // released_at={item.released_at}
          />
        </div>
        <div className="p-mt-3">
          <CustomPanel
            title="Контракт"
            handleClick={
              form.requisites.length >= 0 && form.requisites.length < 2
                ? (e) => toggleOverlay(e)
                : ''
            }
            block={block}
            per_key="read_contract"
          >
            {ContractFields()}
          </CustomPanel>
        </div>
        <div className="p-mt-3">
          <CustomPanel
            block={block}
            per_key="read_passport"
            title="Пасспортные данные"
          >
            {PassportDatas()}
          </CustomPanel>
        </div>
        {Requisites()}
        {/* <Requisites /> */}
        <div className="p-my-3">
          <CustomPanel
            block={block}
            per_key="read_contact"
            title="Контактные данные"
          >
            {ContactDatas()}
          </CustomPanel>
        </div>
        <div className="p-my-3">
          <CustomPanel
            block={block}
            per_key="read_account"
            title="Данные аккаунта автора"
          >
            {AccountData()}
          </CustomPanel>
        </div>
        <div className="p-my-3">
          <CustomPanel
            block={block}
            per_key="read_history"
            title="История изменений"
          >
            <HistoryTable history={item.history} />
          </CustomPanel>
        </div>
      </div>
      {Overlay()}

      <Dialog
        header="Добавить файл"
        visible={showDialog}
        style={{ width: '70vw' }}
        // footer={renderFooter('displayBasic')}
        onHide={() => setShowDialog(false)}
      >
        <FileUpload
          name="demo[]"
          url="https://primefaces.org/primereact/showcase/upload.php"
          onUpload={onUploadDocs}
          onSelect={onSelectDocs}
          multiple
          mode="advanced"
          fileLimit="5"
          accept="image/*"
          maxFileSize={1000000}
          emptyTemplate={
            <p className="p-m-0">Перетащите файл чтобы загрузить.</p>
          }
          chooseLabel="Выбирать"
          uploadLabel="Загрузить"
          cancelLabel="Отменить"
          chooseOptions={{
            className: `${
              form.passport_data.other_doc.length === 5 ? 'p-d-none' : ''
            } p-button-text`,
          }}
          uploadOptions={{ className: 'p-button-text' }}
          cancelOptions={{ className: 'p-button-text' }}
        />
      </Dialog>
    </Box>
  )
}

function CustomPanel(props) {
  const { children, title, handleClick, block, per_key, allow = false } = props
  const [active, setActive] = useState(true)
  const [create_active, setActiveCreate] = useState(false)
  const permissions = useSelector((store) => store.common.permissions)

  useEffect(() => {
    setActive(getPermission(permissions, block, per_key, allow))
    setActiveCreate(getPermission(permissions, block, 'create_contract'))
  }, [permissions])

  const template = (options) => {
    const toggleIcon = options.collapsed
      ? 'pi pi-chevron-down'
      : 'pi pi-chevron-up'
    const className = `${options.className} p-d-flex p-jc-between`
    const titleClassName = `${options.titleClassName} p-pl-1`

    return (
      <div className={className}>
        <span className={titleClassName}>{title}</span>
        <div>
          {handleClick && create_active ? (
            <button className="p-button add-button" onClick={handleClick}>
              <span className="pi pi-plus"></span>
              <span className="panel-action-title">Добавить контракт</span>
            </button>
          ) : (
            ''
          )}

          <button
            className={options.togglerClassName}
            onClick={options.onTogglerClick}
          >
            <span className={toggleIcon}></span>
            <Ripple />
          </button>
        </div>
      </div>
    )
  }
  return active ? (
    <Panel headerTemplate={template} toggleable>
      {children}
    </Panel>
  ) : (
    ''
  )
}

export default SingerItem
