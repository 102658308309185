import { account } from './account'
import { partner } from './partner'
import { payment } from './payment'
import { auth } from './auth'

export const requests = {
    account,
    partner,
    payment,
    auth,
}
