import AuthorizedLayout from '../components/layouts/Authorized/index'
import Singers from '../components/pages/authors/singers'
import SingerItem from '../components/pages/authors/singers/item'

const authorRoutes = {
  path: 'authors',
  element: <AuthorizedLayout />,
  children: [
    {
      path: ':role',
      children: [
        { path: '', element: <Singers block="author" /> },
        { path: ':id', element: <SingerItem block="author" /> },
      ],
    },
  ],
}

export default authorRoutes
