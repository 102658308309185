import nProgress from 'nprogress'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router'
import Box from '../../ui/box'
import BreadcrumbNevo from '../../ui/breadcrumb'
import AuthNavbar from './Navbar/navbar'

function AuthorizedLayout() {
  const user = useSelector((store) => store.common.user)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      label: 'Обьекты',
    },
    {
      label: 'Звукозаписи',
      url: '/',
    },
  ])

  useEffect(() => {
    if (!user) navigate('/auth/login')
  }, [user])

  useEffect(() => {
    let paths = location.pathname.split('/')
    let brds = []
    paths.map((it, i) => {
      it = it.replace('%20', ' ')
      if (i !== 0)
        brds.push({
          label: t(it),
          url: i === paths.length - 1 ? location.pathname : `/${it}`,
        })
    })
    setBreadcrumbs([...brds])
  }, [location])

  useEffect(() => {
    nProgress.done()
    return () => {
      nProgress.start()
    }
  }, [])

  return user ? (
    <>
      <AuthNavbar />
      <Box allow={true}>
        <BreadcrumbNevo items={breadcrumbs} />
      </Box>
      <Outlet />
    </>
  ) : (
    ''
  )
}

export default AuthorizedLayout
