import React, { useState, useEffect } from 'react'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { Button } from 'primereact/button'
import { toast } from 'react-toastify'
import { requests } from '../../../services/requests'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import nProgress from 'nprogress'
import { setLoading } from '../../../redux/actions/commonReducer'

function randomise(min, max) {
  return Math.floor(Math.random() * (max - min) + min)
}

const Register = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((store) => store.common.user)
  const token = localStorage.getItem('access_token')
  const [data, setData] = useState({
    username: '',
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    password: '',
    confirm_password: '',
  })

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(setLoading(true))
    if (data.password.length !== data.confirm_password.length) {
      toast.error('Пароль не совпадает!')
      return
    } else {
      for (let i = 0; i < data.password.length; i++) {
        if (data.password[i] !== data.confirm_password[i]) {
          toast.error('Пароль не совпадает!')
          return
        }
      }
    }
    requests.auth
      .createUser({
        username: data.username,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        id: randomise(1, 1000),
        password: data.password,
        re_password: data.confirm_password,
      })
      .then(() => {
        toast.success('Проверьте почту для активации !')
        navigate('/auth/login')
        dispatch(setLoading(false))
      })
      .catch((err) => {
        console.log(err)
        dispatch(setLoading(false))
        toast.warn('Пользователь с такими данными существуеть')
      })
  }

  useEffect(() => {
    if (user && token) navigate(`/`)
    nProgress.done()
  }, [])

  return !user || !token ? (
    <div className="login">
      <div className="login-img"></div>
      <div className="login-form">
        <div className="p-grid p-d-flex p-jc-center">
          <div className="p-lg-8">
            <form>
              <h1 className="p-my-3">Регистрация</h1>
              <div className="p-grid">
                <div className="p-lg-12 p-md-12 p-sm-12 p-col-12">
                  <span className="p-float-label">
                    <InputText
                      value={data.username}
                      name="username"
                      onChange={handleChange}
                      id="username"
                      required
                    />
                    <label htmlFor="username">Логин</label>
                  </span>
                </div>
                <div className="p-lg-12 p-md-12 p-sm-12 p-col-12">
                  <span className="p-float-label">
                    <InputText
                      value={data.first_name}
                      name="first_name"
                      onChange={handleChange}
                      id={'first_name'}
                      required
                    />
                    <label htmlFor="first_name">Имя</label>
                  </span>
                </div>
                <div className="p-lg-12 p-md-12 p-sm-12 p-col-12">
                  <span className="p-float-label">
                    <InputText
                      value={data.last_name}
                      name="last_name"
                      onChange={handleChange}
                      id="last_name"
                      required
                    />
                    <label htmlFor="last_name">Фамилия</label>
                  </span>
                </div>
                <div className="p-lg-12 p-md-12 p-sm-12 p-col-12">
                  <span className="p-float-label">
                    <InputText
                      value={data.email}
                      name="email"
                      onChange={handleChange}
                      id="email"
                      required
                    />
                    <label htmlFor="email">Email</label>
                  </span>
                </div>
                <div className="p-lg-12 p-md-12 p-sm-12 p-col-12">
                  <span className="p-float-label">
                    <Password
                      value={data.password}
                      name="password"
                      onChange={handleChange}
                      id="password"
                      required
                      type="password"
                      toggleMask
                      feedback={false}
                      className="p-w-100"
                    />
                    <label htmlFor={data.nickname}>Пароль</label>
                  </span>
                </div>
                <div className="p-lg-12 p-md-12 p-sm-12 p-col-12">
                  <span className="p-float-label">
                    <Password
                      value={data.confirm_password}
                      name="confirm_password"
                      onChange={handleChange}
                      id="confirm_password"
                      required
                      type="password"
                      toggleMask
                      feedback={false}
                      className="p-w-100"
                    />
                    <label htmlFor="confirm_password">Подтвердите пароль</label>
                  </span>
                </div>

                <div className="p-lg-12 p-md-12 p-sm-12 p-col-12 p-d-flex p-jc-center">
                  <span>У вас есть аккаунт ? </span>&nbsp;
                  <a className="p-button-text" href="/auth/login">
                    <b>Войти</b>
                  </a>
                </div>
                <div className="p-lg-12 p-md-12 p-sm-12 p-col-12">
                  <Button
                    label="Регистрация"
                    style={{ width: '100%' }}
                    onClick={handleLogin}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  )
}

export default Register
