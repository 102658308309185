import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import {
  setLoading,
  setOpenModalSinger,
} from '../../../redux/actions/commonReducer'
import { toast } from 'react-toastify'
import { requests } from '../../../services/requests'

const template = {
  pseudonym: '',
  username: '',
  img: '/images/user.svg',
  password: '',
  phone_number1: '',
  phone_number1: '',
  email: '',
  address_line1: '',
  address_line2: '',
  comment: '',
}

function DialogSinger() {
  const [data, setData] = useState(template)
  const openModal = useSelector((store) => store.common.openModalSinger)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setOpenModalSinger(false))
  }

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleCreate = () => {
    dispatch(setLoading(true))

    if (!data.pseudonym) toast.warn('Заполните поле "Псевдоним"')
    if (!data.username) toast.warn('Заполните поле "Логин"')
    if (!data.password) toast.warn('Заполните поле "Пароль"')
    if (data.password && data.username && data.pseudonym)
      requests.account
        .createPerson({
          pseudonym: data.pseudonym,
          username: data.username,
          password: data.password,
          phone_number1: data.phone_number1,
          phone_number2: data.phone_number2,
          email: data.email,
          address_line1: data.address_line1,
          address_line2: data.address_line2,
          comment: data.comment,
        })
        .then((res) => {
          if (res.status === 201) {
            toast.success('Артист добавлен успешно')
            handleClose()
            setData({ ...template })
            dispatch(setLoading(false))
          } else toast.warn('Ошибка при создании артиста')
        })
        .catch((_) => {
          dispatch(setLoading(false))
          toast.warn('Ошибка при создании артиста')
        })
  }

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Отмена"
          icon="pi pi-times"
          onClick={handleClose}
          className="p-button-text"
        />
        <Button
          label="Создать"
          icon="pi pi-save"
          onClick={handleCreate}
          autoFocus
        />
      </>
    )
  }

  return (
    <>
      <Dialog
        header={'Добавление артиста'}
        footer={renderFooter}
        visible={openModal}
        onHide={handleClose}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '80vw' }}
        closable={false}
      >
        <div className="p-grid p-pt-3">
          <div className="p-col-12 p-md-2 p-lg-2">
            <label
              className="p-p-1 add-singer"
              htmlFor="artist-img"
              style={{ cursor: 'pointer' }}
            >
              <input type="file" name="artist-img" id="artist-img" />
              <img src={data.img} name="img" width="100%" alt="img" />
            </label>
          </div>
          <div className="p-col-12 p-md-10 p-lg-10">
            <div className="p-grid">
              <div className="p-col-12 p-sm-6 p-md-4 p-lg-4">
                <span className="p-float-label">
                  <InputText
                    value={data.pseudonym}
                    name={'pseudonym'}
                    onChange={handleChange}
                    id={'pseudonym'}
                    required
                  />
                  <label htmlFor={data.pseudonym}>Псевдоним</label>
                </span>
              </div>
              <div className="p-col-12 p-sm-6 p-md-4 p-lg-4">
                <span className="p-float-label">
                  <InputText
                    value={data.username}
                    name={'username'}
                    onChange={handleChange}
                    id={'username'}
                    required
                  />
                  <label htmlFor={data.username}>Логин</label>
                </span>
              </div>
              <div className="p-col-12 p-sm-6 p-md-4 p-lg-4">
                <span className="p-float-label">
                  <InputText
                    value={data.password}
                    name={'password'}
                    onChange={handleChange}
                    id={'password'}
                    required
                  />
                  <label htmlFor={data.password}>Пароль</label>
                </span>
              </div>
              <div className="p-col-12 p-sm-6 p-md-4 p-lg-4">
                <span className="p-float-label">
                  <InputText
                    value={data.phone_number1}
                    name={'phone_number1'}
                    onChange={handleChange}
                    id={'phone_number1'}
                  />
                  <label htmlFor={'phone_number1'}>Номер телефона(1)</label>
                </span>
              </div>
              <div className="p-col-12 p-sm-6 p-md-4 p-lg-4">
                <span className="p-float-label">
                  <InputText
                    value={data.phone_number2}
                    name={'phone_number2'}
                    onChange={handleChange}
                    id={'phone_number2'}
                  />
                  <label htmlFor={'phone_number2'}>Номер телефона(2)</label>
                </span>
              </div>
              <div className="p-col-12 p-sm-6 p-md-4 p-lg-4">
                <span className="p-float-label">
                  <InputText
                    value={data.email}
                    name={'email'}
                    onChange={handleChange}
                    id={'email'}
                  />
                  <label htmlFor={'email'}>Email</label>
                </span>
              </div>
              <div className="p-col-12 p-sm-6 p-md-4 p-lg-4">
                <span className="p-float-label">
                  <InputText
                    value={data.address_line1}
                    name={'address_line1'}
                    onChange={handleChange}
                    id={'address_line1'}
                  />
                  <label htmlFor={'address_line1'}>Адрес</label>
                </span>
              </div>
              <div className="p-col-12 p-sm-6 p-md-4 p-lg-4">
                <span className="p-float-label">
                  <InputText
                    value={data.address_line2}
                    name={'address_line2'}
                    onChange={handleChange}
                    id={'address_line2'}
                  />
                  <label htmlFor={'address_line2'}>Дополнительный адрес</label>
                </span>
              </div>
              <div className="p-col-12 p-sm-6 p-md-4 p-lg-4">
                <span className="p-float-label">
                  <InputText
                    value={data.comment}
                    name={'comment'}
                    onChange={handleChange}
                    id={'comment'}
                  />
                  <label htmlFor={'comment'}>Комментария</label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default DialogSinger
