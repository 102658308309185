import AuthorizedLayout from '../components/layouts/Authorized/index'
import Settings from '../components/pages/settings'

const settingsRoutes = {
  path: 'settings',
  element: <AuthorizedLayout />,
  children: [
    {
      path: '',
      element: <Settings />,
    },
  ],
}

export default settingsRoutes
