import React, { useEffect, useState } from 'react'
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import { toast } from 'react-toastify';
import { getPermission } from '../../utils/permission';
import { useSelector } from 'react-redux';
 

const PermissionTable = ({
    data, 
    handleChange,
    block,
    permission_key,
    allow,
    showAllCheck = true
}) => {

    const [active,setActive] = useState(false)
    const [checkAll, setCheckAll] = useState(false)
    const permissions = useSelector(store => store.common.permissions)
    useEffect(()=>{
        setActive(
            getPermission(
                permissions,
                block,
                permission_key,
                allow
            )
        )
    },[permissions])

    const handleDetect = (id,per) => (e) => {
        handleChange(id,per,e.target.checked)
        if(e.target.checked)
            toast.info(`Поля "${data[id].permissions[per].label}" включен`)
        else 
            toast.warn(`Поля "${data[id].permissions[per].label}" отключен`)
    }

    const handleSwitchAll = (e) => {
        if(e.value)
            data.map((item,i) => 
                item.permissions.map((_,p)=>
                    handleChange(i,p,true)
                )
            )
            
        setCheckAll(e.value)
    }

    return(
        <div className="permission-table">
            <div className="permission-inner">
                {
                    showAllCheck?
                    <div className="p-d-flex p-jc-end p-ai-center">
                        <span className="p-mx-2">Отметить всё</span>
                        <InputSwitch checked={checkAll} onChange={handleSwitchAll} />
                    </div>
                    :''
                }
                {
                    data?.map((item,i)=>
                        <div className="permission-item" key={i}>
                            <span className="permission-title">
                                {item.label}
                            </span>
                            <div className="permission-action">
                                {
                                    item.permissions?.map((per,p) => 
                                        <div className="permission-action-item p-mt-2" key={p}>
                                            <div className="permission-action-check p-d-flex p-ai-center p-jc-between">
                                                <label 
                                                    htmlFor="binary"
                                                    className="p-mr-2"
                                                >{per.label}</label>
                                                <Checkbox 
                                                    inputId="binary" 
                                                    checked={per.value} 
                                                    name={per.key}
                                                    onChange={handleDetect(i,p)} 
                                                    disabled={!active}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default PermissionTable