import React from 'react'
import { useNavigate } from 'react-router'
import cls from './SingerCard.module.scss'

function SingerCard({ id, name, img, checked }) {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/authors/singers/${id}`)
  }

  return (
    <div className={cls.card} onClick={handleClick}>
      <img src={img} alt={name} className={cls.img} />
      <div className={cls.info}>
        <span>{name}</span>
        {checked ? (
          <span className={`${cls.icon} pi pi-check-circle`}></span>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default SingerCard
