import Register from "../components/pages/auth/register"
import Login from '../components/pages/auth/login'
import Activate from '../components/pages/auth/activate'

const authRoutes = {
    path: 'auth',
    children:[
        {
            path: 'login',
            element: <Login />,
          },
          {
            path: 'register',
            element: <Register />,
          },
          {
            path: 'activate',
            element: <Activate />,
            children:[
              {
                path:'*',
                element: <Activate />,
              }
            ]
          },
    ]
}

export default authRoutes