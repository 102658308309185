import React, { useState } from 'react'
import StaffCard from '../../ui/StaffCard'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown';
import Box from '../../ui/box';

const Staffs = (props) => {
    const types = [
        'Нет',
        'Админ',
        'Модератор',
    ]
    const [search,setSearch] = useState('')
    const [type,setType] = useState('')

    const handleChangeType = (e) => {
        setType(e.target.value)
    }

    return (
        <Box
            block={props.block}
            permission_key="read"
        >
            <div className="p-grid p-d-flex p-ai-center">
                <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-my-2">
                    <h1>Сотрудники</h1>
                </div>
                <div className="p-col-12 p-sm-6 p-md-8 p-lg-8 p-my-2 p-d-flex p-jc-end">
                    <Dropdown 
                        value={type} 
                        options={types} 
                        onChange={handleChangeType} 
                        placeholder="Выберите тип"
                        className="p-mx-2"
                        style={{
                            maxWidth: '150px'
                        }}
                    />
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText 
                            value={search} 
                            onChange={(e) => setSearch(e.target.value)} 
                            placeholder="Поиск" 
                        />
                    </span>
                </div>
            </div>
            <div className="p-grid">
                <div className="p-col-6 p-sm-4 p-md-3 p-lg-2">
                    <StaffCard/>
                </div>
                <div className="p-col-6 p-sm-4 p-md-3 p-lg-2">
                    <StaffCard/>
                </div>
                <div className="p-col-6 p-sm-4 p-md-3 p-lg-2">
                    <StaffCard/>
                </div>
                <div className="p-col-6 p-sm-4 p-md-3 p-lg-2">
                    <StaffCard/>
                </div>
                <div className="p-col-6 p-sm-4 p-md-3 p-lg-2">
                    <StaffCard/>
                </div>
                <div className="p-col-6 p-sm-4 p-md-3 p-lg-2">
                    <StaffCard/>
                </div>
                <div className="p-col-6 p-sm-4 p-md-3 p-lg-2">
                    <StaffCard/>
                </div>
            </div>
        </Box>
    )
}

export default Staffs