import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';

function FilterTab({
    singer,
    setSinger,
    composer,
    setComposer,
    author,
    setAuthor,
    label,
    setLabel,
    authors,
    singers,
    composers,
    isGrid,
    setGrid,
    labels,
    columns,
    setColumns,
    date,
    setDate
}){
    const [selectAll,setSelectAll] = useState(true)
    const [selectedItems, setSelectedItems] = useState([])

    useEffect(()=>{
        let items = []
        columns.map(item => {
            if(item.active)
                items.push(item.label)     
            
            return item
        })
        setSelectedItems([...items])
    },[columns])

    const handleChangeSinger = (e) => {
        setSinger(e.target.value)
    }
    const handleChangeComposer = (e) => {
        setComposer(e.target.value)
    }
    const handleChangeAuthor = (e) => {
        setAuthor(e.target.value)
    }
    const handleChangeLabel = (e) => {
        setLabel(e.target.value)
    }
    const handleChangeGrid = () => {
        setGrid(!isGrid)
    }
    const handleChangeDate = e => {
        setDate(e.value)
    }
    const handleSelectColumn = (e) => {
        const { value } = e
        let items = [...columns]

        if(value.length > 0){
            items.map(it => {
                if(value.includes(it.label))
                    it.active = true
                else 
                    it.active = false
    
                return it
            })
    
            setSelectAll(e.value.length === columns.length )
            setColumns([...items])
        }
    }
    
    const handleSelectAllColumn = (e) => {
        let items = columns
        if(!e.checked)
            setColumns(items = items.map(item=> item = {...item,active: true}))
        setSelectAll(!e.checked)
    }

    return(
        <div className="filter">
            <div className="p-grid p-white">
                {/* <div className="p-value p-col-6 p-sm-6 p-md-4 p-lg-2 ">
                    <Dropdown 
                        optionLabel="name" 
                        value={singer} 
                        options={singers} 
                        onChange={handleChangeSinger} 
                        placeholder="Исполнитель"
                    />
                </div>
                <div className="p-value p-col-6 p-sm-6 p-md-4 p-lg-2 ">
                    <Dropdown 
                        optionLabel="name" 
                        value={composer} 
                        options={composers} 
                        onChange={handleChangeComposer} 
                        placeholder="Композиторы"
                    />
                </div>
                <div className="p-value p-col-6 p-sm-6 p-md-4 p-lg-2 ">
                    <Dropdown 
                        optionLabel="name" 
                        value={author} 
                        options={authors} 
                        onChange={handleChangeAuthor} 
                        placeholder="Авторы"
                    />
                </div>
                <div className="p-value p-col-6 p-sm-6 p-md-4 p-lg-2 ">
                    <Dropdown 
                        optionLabel="name" 
                        value={label} 
                        options={labels} 
                        onChange={handleChangeLabel} 
                        placeholder="Лейблы"
                    />
                </div>
                <div className="p-value p-col-6 p-sm-6 p-md-4 p-lg-2 ">
                    <Calendar 
                        id="range" 
                        value={date} 
                        onChange={handleChangeDate} 
                        selectionMode="range" 
                        placeholder="Период"
                        readOnlyInput 
                        mask="dd.mm.yyyy"
                        // icon="pi pi-calendar"
                        showIcon
                        dateFormat="dd.mm.yy"
                        style={{
                            width: '100%'
                        }}
                    />
                </div> */}
                {/* <div className="p-value p-col-6 p-sm-6 p-md-4 p-lg-2 "> */}
                <div className="p-value p-col-6 p-sm-6 p-md-12 p-lg-12 ">
                    <div className="filter-switch">
                        <span 
                            className="switch-grid pi pi-table ${isGrid? ' grid-active' :''}" 
                            onClick={handleChangeGrid}>
                        </span>
                        <div className="grid-add">
                            <MultiSelect 
                                value={selectedItems} 
                                options={columns} 
                                optionValue="label"
                                onChange={handleSelectColumn} 
                                selectAll={selectAll} 
                                maxSelectedLabels={2}
                                onSelectAll={handleSelectAllColumn} 
                                placeholder="Дата добавления"
                                selectedItemsLabel="{0} столбцы"
                                dropdownIcon="pi pi-align-left"
                                disabled={!isGrid}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterTab