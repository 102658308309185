import { NavLink } from 'react-router-dom'
import { artist_types } from '../../../../constants/consts'

const redirect = (item, opt) => (
  <NavLink className={opt.className} to={item.url}>
    <span className="p-menuitem-text">{item.label}</span>
  </NavLink>
)

export const nav_items = [
  {
    label: 'Объекты',
    items: [
      {
        label: 'Звукозаписи',
        url: '/records',
        template: (item, options) => redirect(item, options),
      },
      {
        label: 'Соундтреки',
        url: '/soundtracks',
        template: (item, options) => redirect(item, options),
      },
      {
        label: 'Фильмы',
        url: '/films',
        template: (item, options) => redirect(item, options),
      },
    ],
  },
  {
    label: 'Авторы',
    items: [...artist_types],
  },
  {
    label: 'Партнеры',
    url: '#',
    template: (item, options) => redirect(item, options),
  },
  {
    label: 'Отчеты',
    url: '#',
    template: (item, options) => redirect(item, options),
  },
  {
    label: 'Сотрудники',
    url: '/staff',
    template: (item, options) => redirect(item, options),
  },
  {
    label: 'Настройки',
    url: '/settings',
    template: (item, options) => redirect(item, options),
  },
  // {
  //     label:"Мессенджер",
  //     url:"/messenger",
  // },
  // {
  //     label:"Аналитика",
  //     url:"/analytics",
  // }
]
