const toCapitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export const getCSV = (file,setData) => {
    let _importedCols = []
    let _importedData = []
    const reader = new FileReader();
    reader.onload = (e) => {
        const csv = e.target.result;
        const data = csv.split('\n');

        // Prepare DataTable
        const cols = data[0].split(',');
        data.shift();

        cols.map(col => _importedCols.push(({ field: col, header: toCapitalize(col.replace(/['"]+/g, ''))})));
        data.map(d => {
            d = d.split(',');
            return cols.reduce((obj, c, i) => {
                obj[c] = d[i].replace(/['"]+/g, '');
                _importedData.push(obj)
                return obj;
            }, {});
        });
        
        setData(_importedData)
    };

    reader.readAsText(file, 'UTF-8');
}

export const getExcel = (file , setData) => {
    let _importedCols = []
    let _importedData = []
    
    import('xlsx').then(xlsx => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const wb = xlsx.read(e.target.result, { type: 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = xlsx.utils.sheet_to_json(ws, { header: 1 });

            // Prepare DataTable
            const cols = data[0];
            data.shift();
             
            cols.map(col => _importedCols.push(({ field: col, header: toCapitalize(col) })));
            data.map(d => {
                return cols.reduce((obj, c, i) => {
                    obj[c] = d[i];
                    if(obj)
                        _importedData.push(obj)
                    return obj;
                }, {});
            });
            setData(_importedData)
        };

        reader.readAsArrayBuffer(file);
    })    
}
