export const getPermission = (
    permissions,
    block,
    key,
    allow = false
) => {
    let res = false
    
    if(permissions)
        permissions.map(item => {
            if(item.key === block || allow)
                item.permissions.map(per => {
                    if((per.key === key && per.value ) || allow)
                        res = true
                })      
        })
        
    return res
}