import React, { useEffect, useState } from 'react'
import PanelNevo from '../../../ui/panel'
import Box from '../../../ui/box'
import { RecordFaker } from './const'
import MiniCard from '../../../ui/MiniCard'
import { InputText } from 'primereact/inputtext'
import HistoryTable from '../../../ui/histroy-table'
import AssetCard from '../../../ui/AssetCard'
import { useLocation } from 'react-router'
import { requests } from '../../../../services/requests'
import { labels } from '../../../../constants/consts'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../../redux/actions/commonReducer'

function RecordItem(props) {
  const { block } = props
  let [item, setItem] = useState()

  const location = useLocation()

  const dispatch = useDispatch()

  const [data, setData] = useState()

  const [inputs, setInputs] = useState([])

  const handleChange = (idx) => (e) => {
    let ins = [...inputs]
    ins[idx] = {
      ...ins[idx],
      value: e.target.value,
    }
    setInputs(ins)
  }

  const handleInputChange = (name, value) => {
    let item = { ...data }

    setData({ ...item, [name]: value })
  }

  useEffect(() => {
    let paths = location.pathname.split('/')
    let id = paths[paths.length - 1]

    dispatch(setLoading(true))

    requests.partner
      .getSingleAsset(id)
      .then((res) => {
        const { data } = res
        dispatch(setLoading(false))
        setItem(data)
        setData({
          record: data.title,
          author: data.authors,
        })
        setInputs([
          {
            name: 'released_at',
            value: item.release_date,
            type: 'date',
            label: 'Дата релиза',
          },
          {
            name: 'yt_asset',
            value: item.youtube_id,
            type: 'string',
            label: 'Asset ID (YT)',
          },
          {
            name: 'isrc',
            value: item.isrc,
            type: 'string',
            label: 'ISRC',
          },
          {
            name: 'label',
            value: labels[item.label],
            type: 'string',
            label: 'Label',
          },
        ])
      })
      .catch((err) => {
        console.log(err)
        dispatch(setLoading(false))
      })

    requests.partner
      .getAssetDetails(id)
      .then((res) => {
        const { data } = res
        dispatch(setLoading(false))
        console.log(data)
      })
      .catch((err) => {
        console.log(err)
        dispatch(setLoading(false))
      })
  }, [item])

  const confirmDelete = () => {
    console.log('delete record')
  }

  return (
    <Box block={block} permission_key="read">
      <div className="p-mt-3">
        <AssetCard
          record={item && item.title}
          author={item && item.authors}
          added_at={item && item.release_date}
          // nv_asset={item.nv_asset}
          yt_asset={item && item.youtube_id}
          released_at={item && item.release_date}
          // records={item.records}
          block={props.block}
          setData={handleInputChange}
          handleDelete={confirmDelete}
        />
      </div>
      {/* <div className="p-mt-3">
                <PanelNevo 
                    title="Исполнители"
                    block={block}
                    permission_key="read_members"
                >
                        <div className="p-grid p-px-2">
                            {
                                item.performers.map((it,i) => 
                                    <div className="p-col-12 p-sm-6 p-md-4 p-lg-3" key={i}>
                                        <MiniCard data={it}/>
                                    </div>
                                )
                            }
                        </div>
                </PanelNevo>
            </div>
            <div className="p-mt-3">
                <PanelNevo 
                    title="Авторы"
                    block={block}
                    permission_key="read_members"
                >
                        <div className="p-grid p-px-2">
                            {
                                item.authors.map((it,i) => 
                                    <div className="p-col-12 p-sm-6 p-md-4 p-lg-3" key={i}>
                                        <MiniCard data={it}/>
                                    </div>
                                )
                            }
                        </div>
                </PanelNevo>
            </div> */}
      <div className="p-mt-3">
        <PanelNevo
          title="Метаданные"
          block={block}
          permission_key="read_meta_data"
        >
          <div className="p-grid p-px-2">
            {inputs?.map((it, i) => (
              <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3" key={i}>
                <span className="p-float-label">
                  <InputText
                    type={it.type}
                    value={it.value}
                    name={it.name}
                    onChange={handleChange(i)}
                    id={it.name}
                    disabled
                  />
                  <label htmlFor={it.name}>
                    <b>{it.label}</b>
                  </label>
                </span>
              </div>
            ))}
          </div>
        </PanelNevo>
      </div>
      <div className="p-mt-3 p-mb-3">
        <PanelNevo title="История" block={block} permission_key="read_history">
          {/* <HistoryTable history={item.history}/> */}
        </PanelNevo>
      </div>
    </Box>
  )
}

export default RecordItem
