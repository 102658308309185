import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { setOpenModal } from '../../../redux/actions/commonReducer';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { getCSV, getExcel } from '../../../utils/import';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useMutation } from 'react-query';
import { requests } from '../../../services/requests';
import { Calendar } from 'primereact/calendar';

const types = [
    {
        name:"Звукозапись",
        value: "sound recording"
    },
    {
        name:"Фильм",
        value: "film"
    },
]

const template = {
    record:{
        value: "",
        name: "record",
        title: "Название",
    },
    singer: {
        value: "",
        name: "singer",
        title: "Исполнитель",
    },
    composer:{
        value: "",
        name: "composer",
        title: "Композитор",
    },
    author: {
        value: "",
        name: "author",
        title: "Автор текста",
    },
    release_date: {
        value: "",
        name: "release_date",
        title: "Дата релиза",
    },
    isrc: {
        value: "",
        name: "isrc",
        title: "ISRC",
    },
    youtube_id: {
        value: "",
        name: "youtube_id",
        title: "Youtube ID",
    },
    type: {
        value: "",
        name: "type",
        title: "Тип объекта",
    },
    file: {
        value: "",
        name: "file",
        title: "Файл",
    },
}

function DialogObject(){
    const { mutate: create, isLoading } = useMutation(requests.partner.createAsset,{
        onSuccess:(res)=>{  
            console.log(res);
        },
        onError: (err) => {
            console.log(err);
        }
    })

    const [data,setData] = useState([
        template
    ])
    const [importData,setImportData] = useState([])
    const openModal = useSelector(store => store.common.openModal)
    const dispatch = useDispatch()
    const op = useRef(null);

    const handleCreate = () =>{
        data.map(item => {
            create(
                {
                    title: item.record.value,
                    creation_type: item.type.value,
                    isrc: item.isrc.value,
                    youtube_id: item.youtube_id.value,
                    release_date: item.release_date.value,
                    authors:'1',
                    label: 1919217
                }
            )
        })
    }

    const handleClose = () => {
        dispatch(setOpenModal(false))
    }

    const handleIncrement = () => {
        let items = [...data]
        items.push(template)

        setData([...items])
    }

    const handleSetData = () => {
        let datas = [...data]
        importData.map(item => {
            let extend = template
            if(item[extend.record.title]){
                extend.record.value = item[template.record.title]
                extend.singer.value = item[template.singer.title]
                extend.composer.value = item[template.composer.title]
                extend.author.value = item[template.author.title]
                extend.type.value = item[template.type.title]
                extend.file.value = item[template.file.title]
                datas.push(extend)
            
                setData([...datas])
            }
            return item
        })
    }

    const importCSV = (e) => {
        const file = e.files[0];
        const resp = getCSV(file,setImportData)

        handleSetData(resp)
    }

    const importExcel = (e) => {
        const file = e.files[0];
        const resp = getExcel(file,setImportData)
        
        handleSetData(resp)
    }

    const importFiles = (e) => {
        const { files } = e
        const items = [...handleFileSet(files)]

        setData([...items])
    }    

    const clear = () => {
        setData([
            template
        ])
    }

    const handleOpen = (e) => {
        op.current.toggle(e)
    }

    const handleFileSet = (files) => {
        let items = [...data]
        if(files.length>0)
            files.map(item => {
                let tmpl = template
                tmpl = {
                    ...tmpl,
                    file:{
                        ...tmpl.file,
                        value: item.name
                    }
                }
                
                items.push(tmpl)
                tmpl = template
                return item 
            });

        return items 
    }

    const renderFooter = () => {
        return (
            <div className="modal-header p-d-flex p-ai-center">
                <div 
                    className="modal-action"
                    onClick={handleIncrement}
                >
                    <span className="pi pi-plus"></span>
                    <span className="p-ml-2">Добавить объект</span>
                </div>
                <div>
                    <Button 
                        label="Отмена" 
                        icon="pi pi-times" 
                        onClick={handleClose} 
                        className="p-button-text" 
                    />
                    <Button 
                        label="Создать" 
                        icon="pi pi-check" 
                        onClick={handleCreate} 
                        autoFocus 
                    />
                </div>
            </div>
        );
    }
    
    const rendererHeader = () => {
        return (
            <div className="modal-header p-ai-center">
                <span>Создание объекта {data.length <= 1?'':`(${data.length})`}</span>
                <div>
                    <div className="p-d-flex p-ai-center p-py-2">
                        <Button 
                            label="Загрузить" 
                            icon="pi pi-upload" 
                            iconPos="left" 
                            className="p-p-2 p-button-text"
                            onClick={handleOpen}
                        />
                        <Button 
                            type="button" 
                            label="Очистить" 
                            icon="pi pi-times" 
                            onClick={clear} 
                            className="p-button-danger p-ml-auto p-button-text" 
                        />
                    </div>
                </div>
            </div>
        )
    }

    const Overlay = () => {
        return (
            <OverlayPanel 
                ref={op}  
                id="overlay_panel" 
                style={{width: '200px'}} 
                className="overlaypanel-demo"
            >
                <div className="overlay-list">
                <FileUpload 
                    chooseOptions={{ label: 'CSV', icon: 'pi pi-file-o', className: 'p-button-text p-py-0 p-px-1', style:{width:'100%'} }} 
                    mode="basic" 
                    name="csv_upload" 
                    auto 
                    url="https://primefaces.org/primereact/showcase/upload.php" 
                    accept=".csv" 
                    className="p-mr-2" 
                    onUpload={importCSV} 
                />
                <FileUpload 
                    chooseOptions={{ label: 'Excel', icon: 'pi pi-file-excel', className: 'p-button-success p-button-text p-py-0 p-px-1', style:{width:'100%'} }} 
                    mode="basic" 
                    name="excel_upload" 
                    auto 
                    url="https://primefaces.org/primereact/showcase/upload.php"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
                    className="p-mr-2" 
                    onUpload={importExcel} 
                />
                <FileUpload 
                    chooseOptions={{ label: 'Файлы', icon: 'pi pi-file-o', className: 'p-button-info p-button-text p-py-0 p-px-1', style:{width:'100%'} }} 
                    mode="basic" 
                    name="files_upload" 
                    auto 
                    url="https://primefaces.org/primereact/showcase/upload.php"
                    accept=".mp3" 
                    multiple
                    className="p-mr-2" 
                    onUpload={importFiles} 
                />
                </div>
            </OverlayPanel>
        )
    }


    return (
        <>
            <Dialog 
                header={rendererHeader} 
                footer={renderFooter}
                visible={openModal} 
                onHide={handleClose} 
                breakpoints={{'960px': '75vw'}} 
                style={{width: '99vw'}} 
                closable={false}
            >
                <ModalTable 
                    data={data}
                    setData={setData}
                />
                {Overlay()}
            </Dialog>
        </>
    )
}


function ModalTable({data,setData}){

    const handleInputChange = (id) => (e) => {
        const { name , value } = e.target
        let items = [...data]

        items = items.map((it,i) => {
            if (i===id)
                it = {
                    ...it,
                    [name] : {
                        ...it[name],
                        value
                    }
                }
            return it
        })

        setData([...items])
    }

    const handleDropdownChange = (id) => (e) => {
        const { value } = e.target
        let items = [...data]
    
        items = items.map((it,i) => {
            if (i===id)
                it = {
                    ...it,
                    type : {
                        ...it['type'],
                        value
                    }
                }
            return it
        })
    
        setData([...items])
    }

    const handleDelete = (id) => (e) => {
        let items = []
        data.map((it,i) => {
            if(i!==id)
                items.push(it)
            
            return it
        })
        
        setData([...items])
    }

    return(
        <div className="custom-table datatable-responsive-demo">
            <div className="card">
                <div 
                    className="p-datatable p-component p-datatable-responsive-demo"
                    data-scrollselectors=".p-datatable-scrollable-body, .p-datatable-unfrozen-view .p-datatable-scrollable-body"
                >
                    <div className="p-datatable-wrapper">
                        <table 
                            role="grid" 
                        >
                            <thead className="p-datatable-thead">
                                <tr>
                                    <th>Название</th>
                                    <th>Исполнитель</th>
                                    <th>Композитор</th>
                                    <th>Автор текста</th>
                                    <th>ISRC</th>
                                    <th>Ссылка для youtube</th>
                                    <th>Дата релиза</th>
                                    <th>Тип обьекта</th>
                                    <th>Файл</th>
                                    <th style={{
                                                width:50
                                            }}></th>
                                </tr>
                            </thead>
                            <tbody className="p-datatable-tbody">
                                {
                                    data.map((item,i) =>
                                        <tr key={i}>
                                            <td role="cell" className="p-row-odd" >
                                                <span class="p-column-title">{item['record'].title}</span>
                                                <div className="p-field">
                                                    <span className="p-float-label">
                                                        <InputText 
                                                            value={item['record'].value} 
                                                            name={item['record'].name} 
                                                            onChange={handleInputChange(i)} 
                                                            id={item['record'].name}
                                                        />
                                                        <label htmlFor={item['record'].name}>{item['record'].title}</label>
                                                    </span>
                                                </div>
                                            </td>
                                            <td role="cell" className="p-row-odd" >
                                                <span class="p-column-title">{item['singer'].title}</span>
                                                <div className="p-field">
                                                    <span className="p-float-label">
                                                        <InputText 
                                                            value={item['singer'].value} 
                                                            name={item['singer'].name} 
                                                            onChange={handleInputChange(i)} 
                                                            id={item['singer'].name}
                                                        />
                                                        <label htmlFor={item['singer'].name}>{item['singer'].title}</label>
                                                    </span>
                                                </div>
                                            </td>
                                            <td role="cell" className="p-row-odd" >
                                                <span class="p-column-title">{item['composer'].title}</span>
                                                <div className="p-field">
                                                    <span className="p-float-label">
                                                        <InputText 
                                                            value={item['composer'].value} 
                                                            name={item['composer'].name} 
                                                            onChange={handleInputChange(i)} 
                                                            id={item['composer'].name}
                                                        />
                                                        <label htmlFor={item['composer'].name}>{item['composer'].title}</label>
                                                    </span>
                                                </div>
                                            </td>
                                            <td role="cell" className="p-row-odd" >
                                                <span class="p-column-title">{item['author'].title}</span>
                                                <div className="p-field">
                                                    <span className="p-float-label">
                                                        <InputText 
                                                            value={item['author'].value} 
                                                            name={item['author'].name} 
                                                            onChange={handleInputChange(i)} 
                                                            id={item['author'].name}
                                                        />
                                                        <label htmlFor={item['author'].name}>{item['author'].title}</label>
                                                    </span>
                                                </div>
                                            </td>

                                            <td role="cell" className="p-row-odd" >
                                                <span class="p-column-title">{item['isrc'].title}</span>
                                                <div className="p-field">
                                                    <span className="p-float-label">
                                                        <InputText 
                                                            value={item['isrc'].value} 
                                                            name={item['isrc'].name} 
                                                            onChange={handleInputChange(i)} 
                                                            id={item['isrc'].name}
                                                        />
                                                        <label htmlFor={item['isrc'].name}>{item['isrc'].title}</label>
                                                    </span>
                                                </div>
                                            </td>
                                            <td role="cell" className="p-row-odd" >
                                                <span class="p-column-title">{item['youtube_id'].title}</span>
                                                <div className="p-field">
                                                    <span className="p-float-label">
                                                        <InputText 
                                                            value={item['youtube_id'].value} 
                                                            name={item['youtube_id'].name} 
                                                            onChange={handleInputChange(i)} 
                                                            id={item['youtube_id'].name}
                                                        />
                                                        <label htmlFor={item['youtube_id'].name}>{item['youtube_id'].title}</label>
                                                    </span>
                                                </div>
                                            </td>
                                            <td role="cell" className="p-row-odd" >
                                                <span class="p-column-title">{item['release_date'].title}</span>
                                                <div className="p-field">
                                                    <span className="p-float-label">
                                                        <Calendar 
                                                            value={item['release_date'].value} 
                                                            name={item['release_date'].name} 
                                                            onChange={handleInputChange(i)} 
                                                            id={item['release_date'].name}
                                                        />
                                                        <label htmlFor={item['release_date'].name}>{item['release_date'].title}</label>
                                                    </span>
                                                </div>
                                            </td>
                                            <td role="cell" className="p-row-odd" >
                                                <span class="p-column-title">{item['type'].title}</span>
                                                <Dropdown 
                                                    value={item["type"].value} 
                                                    options={types} 
                                                    optionLabel="name"
                                                    optionValue="value"
                                                    onChange={handleDropdownChange(i)}
                                                    placeholder={item["type"].title}
                                                    name="type"
                                                />
                                            </td>
                                            <td role="cell" className="p-row-odd" >
                                                <span class="p-column-title">{item['file'].title}</span>
                                                <input
                                                        type={"file"}
                                                        // value={item['file'].value} 
                                                        name={item['file'].name} 
                                                        onChange={handleInputChange(i)} 
                                                        id={item['file'].name+i}
                                                        className="modal-file"
                                                    />
                                                <label 
                                                    className="modal-upload"
                                                    htmlFor={item['file'].name+i}
                                                >
                                                    <span className="pi pi-upload p-mr-1"></span>
                                                    <span>{
                                                        item['file'].value ?
                                                            item['file'].value
                                                        : 'Загрузить'
                                                    }</span>
                                                </label>
                                            </td>
                                            <td style={{
                                                width:100
                                            }}>
                                                {
                                                    data.length <= 1 ?'':
                                                    <span 
                                                        className="pi pi-trash"
                                                        onClick={handleDelete(i)}
                                                    ></span>
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DialogObject