import { NavLink } from 'react-router-dom'

export const labels = {
  5974690: 'UzAvtor',
  1919217: 'NEVO',
}

const redirect = (item, opt) => (
  <NavLink className={opt.className} to={item.url}>
    <span className="p-menuitem-text">{item.label}</span>
  </NavLink>
)

export const labels_arr = [
  {
    name: 'UzAvtor',
    value: '5974690',
  },
  {
    name: 'Nevo',
    value: '1919217',
  },
]

export const asset_types = [
  {
    name: 'Звукозапись',
    vale: 'sound recording',
    link: 'records',
  },
  {
    name: 'Соундтрек',
    vale: 'music video',
    link: 'soundtrack',
  },
  {
    name: 'Фильм',
    vale: 'film',
    link: 'film',
  },
]

export const artist_types = [
  {
    label: 'Артист',
    value: 'artist',
    url: '/authors/artist',
    template: (item, options) => redirect(item, options),
  },
  {
    label: 'Композер',
    value: 'composer',
    url: '/authors/composer',
    template: (item, options) => redirect(item, options),
  },
  {
    label: 'Автор текста',
    value: 'lyricist',
    url: '/authors/lyricist',
    template: (item, options) => redirect(item, options),
  },
  {
    label: 'Композер',
    value: 'arranger',
    url: '/authors/arranger',
    template: (item, options) => redirect(item, options),
  },
  {
    label: 'Ремиксер',
    value: 'remixer',
    url: '/authors/remixer',
    template: (item, options) => redirect(item, options),
  },
  {
    label: 'Директор',
    value: 'director',
    url: '/authors/director',
    template: (item, options) => redirect(item, options),
  },
  {
    label: 'Продюсер',
    value: 'producer',
    url: '/authors/producer',
    template: (item, options) => redirect(item, options),
  },
  {
    label: 'Писатель',
    value: 'writer',
    url: '/authors/writer',
    template: (item, options) => redirect(item, options),
  },
]
