import { authRequest } from "../api"

const partner_api = `api/v1/nevopartners/`

export const partner = {
    // Assets API
    getAssetsList: () => authRequest.get(`${partner_api}asset/`),
    getAssetDetails: (id) => authRequest.get(`${partner_api}asset/details/${id}/`) ,
    getSingleAsset: (id) => authRequest.get(`${partner_api}asset/${id}/`) ,
    deleteAsset: (id) => authRequest.delete(`${partner_api}asset/${id}/`) ,
    createAsset: (data) => authRequest.post(`${partner_api}asset/`, data) ,
    putAsset: (id, data) => authRequest.put(`${partner_api}asset/${id}/`, data),
    patchAsset: (id, data) => authRequest.patch(`${partner_api}asset/${id}/`, data),

    // Author Asset API
    getAuthorAssetList: () => authRequest.get(`${partner_api}author_asset/`) ,
    getSingleAuthorAsset: (id) => authRequest.get(`${partner_api}author_asset/${id}/`) ,
    deleteAuthorAsset: (id) => authRequest.delete(`${partner_api}author_asset/${id}/`),
    createAuthorAsset: (
        {
            id,
            title,
            creation_type,
            isrc,
            youtube_id,
            release_date,
        },
        author_type,
        percent_reward,
    ) => 
        authRequest.post(`${partner_api}author_asset/`, {
            asset: {
                id,
                title,
                creation_type,
                isrc,
                youtube_id,
                release_date,
            },
            author_type,
            percent_reward,
    }),
    putAuthorAsset: (
        {
            id,
            title,
            creation_type,
            isrc,
            youtube_id,
            release_date,
        },
        author_type,
        percent_reward,
    ) => 
        authRequest.put(`${partner_api}author_assett/${id}/`, {
            asset: {
                id,
                title,
                creation_type,
                isrc,
                youtube_id,
                release_date,
            },
            author_type,
            percent_reward,
    }),
    patchAuthorAsset: (
        {
            id,
            title,
            creation_type,
            isrc,
            youtube_id,
            release_date,
        },
        author_type,
        percent_reward,
    ) => 
        authRequest.patch(`${partner_api}author_assett/${id}/`, {
            asset: {
                id,
                title,
                creation_type,
                isrc,
                youtube_id,
                release_date,
            },
            author_type,
            percent_reward,
    }),

    // Contract API
    getContractList: () => authRequest.get(`${partner_api}person/contract/`),
    getSingleContract: (id) => authRequest.get(`${partner_api}person/contract/${id}/`) ,
    deleteContract: (id) => authRequest.delete(`${partner_api}person/contract/${id}/`),
    createContract: (
        id,
        title,
        status,
        issued_at,
        person
    ) => 
        authRequest.post(`${partner_api}perrson/contract/`, {
            id,
            title,
            status,
            issued_at,
            person
    }),
    putContract: (
        id,
        title,
        status,
        issued_at,
        person
    ) => 
        authRequest.put(`${partner_api}perrson/contract/${id}/`, {
            id,
            title,
            status,
            issued_at,
            person
    }),
    patchContract: (
        id,
        title,
        status,
        issued_at,
        person
    ) => 
        authRequest.patch(`${partner_api}perrson/contract/${id}/`, {
            id,
            title,
            status,
            issued_at,
            person
    }),
}