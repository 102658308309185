import 'primereact/resources/themes/md-light-indigo/theme.css'
import React, { useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import PrimeReact from 'primereact/api'
import Providers from './Providers'
import routes from './routes'
import moment from 'moment'
import i18n from './i18n'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.min.css'
import 'react-h5-audio-player/lib/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-image-crop/dist/ReactCrop.css'
import './styles/language.css'
import './styles/auth.css'
import './styles/overrides.css'
import './styles/permission.css'
import 'nprogress/nprogress.css'
import DialogObject from './components/ui/DialogObject'
import DialogSinger from './components/ui/DialogObject/singer-create'
import DialogStaff from './components/ui/DialogObject/staff-create'
import Loader from './components/ui/Loader'

PrimeReact.autoZIndex = true

function App() {
  const routing = useRoutes(routes)

  useEffect(() => {
    moment.locale(i18n.language)
  }, [i18n.language])

  return (
    <Providers>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        closeButton={false}
      />
      <DialogObject />
      <DialogSinger />
      <DialogStaff />
      <Loader />
      {routing}
    </Providers>
  )
}

export default App
