import React, { useEffect, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { Button } from 'primereact/button'
import { requests } from '../../../services/requests/index'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading, setUser } from '../../../redux/actions/commonReducer'
import nProgress from 'nprogress'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((store) => store.common.user)
  const token = localStorage.getItem('access_token')
  const [data, setData] = useState({
    email: '',
    password: '',
  })

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(setLoading(true))
    requests.auth
      .createJwt(data.email, data.password)
      .then((res) => {
        localStorage.setItem('access_token', res.data.access)
        localStorage.setItem('refresh_token', res.data.refresh)
        toast.info('Добро пожаловать в систему')
        requests.auth
          .getCurrentUserToken(res.data.access)
          .then((res) => {
            dispatch(setUser({ ...res.data }))
            window.location.reload()
          })
          .catch((err) => {
            console.log(err)
          })
        dispatch(setLoading(false))
      })
      .catch((err) => {
        console.log(err)
        dispatch(setLoading(false))
        toast.error('Email или пароль не совпадает')
      })
  }

  useEffect(() => {
    if (user && token) navigate(`/`)
    nProgress.done()
  }, [])

  return !user || !token ? (
    <div className="login">
      <div className="login-img"></div>
      <div className="login-form">
        <div className="p-grid p-d-flex p-jc-center">
          <div className="p-lg-8">
            <form>
              <h1 className="p-my-3">Логин</h1>
              <div className="p-grid">
                <div className="p-lg-12 p-md-12 p-sm-12 p-col-12">
                  <span className="p-float-label">
                    <InputText
                      value={data.email}
                      name={'email'}
                      onChange={handleChange}
                      id={'email'}
                      required
                    />
                    <label htmlFor={data.nickname}>Email</label>
                  </span>
                </div>
                <div className="p-lg-12 p-md-12 p-sm-12 p-col-12">
                  <span className="p-float-label">
                    <Password
                      value={data.password}
                      name="password"
                      onChange={handleChange}
                      id="password"
                      required
                      type="password"
                      toggleMask
                      feedback={false}
                      className="p-w-100"
                    />
                    <label htmlFor={data.nickname}>Пароль</label>
                  </span>
                </div>
                <div className="p-lg-12 p-md-12 p-sm-12 p-col-12 p-d-flex p-jc-center">
                  <span>У вас нет аккаунта ? </span>
                  &nbsp;
                  <a className="p-button-text" href="/auth/register">
                    <b>Регистрация</b>
                  </a>
                </div>
                <div className="p-lg-12 p-md-12 p-sm-12 p-col-12">
                  <Button
                    label="Войти"
                    style={{ width: '100%' }}
                    onClick={handleLogin}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  )
}

export default Login
