import { combineReducers } from 'redux'
import { persistReducer, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import commonReducer from './commonReducer'

const migrations = {
  0: (state) => {
    return {
      ...state,
    }
  },
  1: (state) => {
    return {
      ...state,
    }
  },
}

const commonPersistConfig = {
  key: 'common',
  storage,
  whitelist: ['common'],
}


const persistConfig = {
  key: 'root',
  storage,
  whiteList: [],
  version: 1,
  migrate: createMigrate(migrations, { debug: false }),
}

const rootReducer = combineReducers({
    common: persistReducer(commonPersistConfig, commonReducer)
})

export default persistReducer(persistConfig, rootReducer)
