import Staffs from '../components/pages/staff'
import StaffSingle from '../components/pages/staff/item'
import AuthorizedLayout from '../components/layouts/Authorized/index'

const staffRoutes = {
  path: 'staff',
  element: <AuthorizedLayout />,
  children: [
    {
      path: '*',
      element: <StaffSingle block="staff" />,
    },
    {
      path: '',
      element: <Staffs block="staff" />,
    },
  ],
}

export default staffRoutes
