import React, { useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { setLoading } from '../../../redux/actions/commonReducer'
import { requests } from '../../../services/requests'

const Activate = () => {
  const [pieces, setPieces] = useState(true)
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      setPieces(false)
    }, 1000)
  }, [])

  useEffect(() => {
    dispatch(setLoading(true))
    let paths = location.pathname.split('/')
    let uid = paths[paths.length - 2]
    let token = paths[paths.length - 1]

    if (uid && token) {
      requests.auth
        .activateUser(uid, token)
        .then((res) => {
          dispatch(setLoading(false))
          console.log(res)
        })
        .catch((err) => {
          dispatch(setLoading(false))
          console.log(err)
        })
    }
  }, [])

  return (
    <>
      <Confetti numberOfPieces={pieces ? 500 : 0} />
      <div className="container">
        <div className="activate-page">
          <span className="pi pi-check-circle"></span>
          <h1>Поздравляем, ваш профиль активирован</h1>
          <a href="/auth/login">Войти</a>
        </div>
      </div>
    </>
  )
}

export default Activate
