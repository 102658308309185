import React from 'react'
import { useSelector } from 'react-redux'
import cls from './Loader.module.scss'

const Loader = () => {
  const loading = useSelector((store) => store.common.loading)

  return loading ? (
    <div className={cls.container}>
      <img src="/media/loader.gif" width="300" alt="loader" />
    </div>
  ) : (
    ''
  )
}

export default Loader
