export const lottie_permission = {
    "v": "5.6.10",
    "fr": 60,
    "ip": 0,
    "op": 600,
    "w": 1330,
    "h": 920,
    "nm": "Designer boy ready",
    "ddd": 0,
    "assets": [],
    "layers": [
      {
        "ddd": 0,
        "ind": 1,
        "ty": 4,
        "nm": "mensaje 8",
        "sr": 1,
        "ks": {
          "o": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 480.332,
                "s": [
                  0
                ]
              },
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 490.332,
                "s": [
                  100
                ]
              },
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 550,
                "s": [
                  100
                ]
              },
              {
                "t": 600,
                "s": [
                  0
                ]
              }
            ],
            "ix": 11
          },
          "r": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.256
                  ],
                  "y": [
                    1.671
                  ]
                },
                "o": {
                  "x": [
                    0.25
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 477,
                "s": [
                  -40
                ]
              },
              {
                "t": 493.66796875,
                "s": [
                  0
                ]
              }
            ],
            "ix": 10
          },
          "p": {
            "a": 0,
            "k": [
              553.019,
              430.375,
              0
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              502.625,
              162.125,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.067,
                    0.067,
                    0.56
                  ],
                  "y": [
                    1.506,
                    1.506,
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.25,
                    0.25,
                    0.25
                  ],
                  "y": [
                    0,
                    0,
                    0
                  ]
                },
                "t": 477,
                "s": [
                  0,
                  0,
                  100
                ]
              },
              {
                "t": 495.33203125,
                "s": [
                  100,
                  100,
                  100
                ]
              }
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 1,
                  "k": [
                    {
                      "i": {
                        "x": 0.44,
                        "y": 1
                      },
                      "o": {
                        "x": 0.12,
                        "y": 0
                      },
                      "t": 485.332,
                      "s": [
                        {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              4.036
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ]
                          ],
                          "o": [
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              2.854,
                              2.855
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.823
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -150.585,
                              -163.461
                            ],
                            [
                              -188.289,
                              -125.757
                            ],
                            [
                              -188.289,
                              -125.756
                            ],
                            [
                              -150.585,
                              -88.051
                            ],
                            [
                              -130.488,
                              -88.1
                            ],
                            [
                              -106.735,
                              -64.349
                            ],
                            [
                              -99,
                              -67.552
                            ],
                            [
                              -99,
                              -88.1
                            ],
                            [
                              -95.888,
                              -88.1
                            ],
                            [
                              -58.183,
                              -125.805
                            ],
                            [
                              -58.183,
                              -125.806
                            ],
                            [
                              -95.888,
                              -163.51
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "t": 542,
                      "s": [
                        {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              4.036
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ]
                          ],
                          "o": [
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              2.854,
                              2.855
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.823
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -464.835,
                              -163.51
                            ],
                            [
                              -502.539,
                              -125.806
                            ],
                            [
                              -502.539,
                              -125.805
                            ],
                            [
                              -464.835,
                              -88.1
                            ],
                            [
                              -130.488,
                              -88.1
                            ],
                            [
                              -106.735,
                              -64.349
                            ],
                            [
                              -99,
                              -67.552
                            ],
                            [
                              -99,
                              -88.1
                            ],
                            [
                              -95.888,
                              -88.1
                            ],
                            [
                              -58.183,
                              -125.805
                            ],
                            [
                              -58.183,
                              -125.806
                            ],
                            [
                              -95.888,
                              -163.51
                            ]
                          ],
                          "c": true
                        }
                      ]
                    }
                  ],
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.002,
                      0.161,
                      0.435,
                      1,
                      0.407,
                      0.161,
                      0.531,
                      1,
                      1,
                      0.161,
                      0.627,
                      1
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    -503.219,
                    -126.02
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    -58.402,
                    -124.02
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    502.789,
                    163.76
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    -103,
                    -66
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 600,
        "op": 600,
        "st": 466,
        "bm": 0,
        "hidden": 477
      },
      {
        "ddd": 0,
        "ind": 2,
        "ty": 4,
        "nm": "mensaje 7",
        "sr": 1,
        "ks": {
          "o": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 407.332,
                "s": [
                  0
                ]
              },
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 417.332,
                "s": [
                  100
                ]
              },
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 530,
                "s": [
                  100
                ]
              },
              {
                "t": 580,
                "s": [
                  0
                ]
              }
            ],
            "ix": 11
          },
          "r": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.112
                  ],
                  "y": [
                    1.707
                  ]
                },
                "o": {
                  "x": [
                    0.25
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 404,
                "s": [
                  40
                ]
              },
              {
                "t": 420.66796875,
                "s": [
                  0
                ]
              }
            ],
            "ix": 10
          },
          "p": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": 0.39,
                  "y": 1
                },
                "o": {
                  "x": 0.52,
                  "y": 0
                },
                "t": 461,
                "s": [
                  148.211,
                  431.48099999999994,
                  0
                ],
                "to": [
                  0,
                  -16.333,
                  0
                ],
                "ti": [
                  0,
                  16.333,
                  0
                ]
              },
              {
                "t": 495,
                "s": [
                  148.211,
                  323.481,
                  0
                ]
              }
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              223,
              49.241,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.067,
                    0.067,
                    0.56
                  ],
                  "y": [
                    1.506,
                    1.506,
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.25,
                    0.25,
                    0.25
                  ],
                  "y": [
                    0,
                    0,
                    0
                  ]
                },
                "t": 404,
                "s": [
                  0,
                  0,
                  100
                ]
              },
              {
                "t": 422.33203125,
                "s": [
                  100,
                  100,
                  100
                ]
              }
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 1,
                  "k": [
                    {
                      "i": {
                        "x": 0.61,
                        "y": 1
                      },
                      "o": {
                        "x": 0.12,
                        "y": 0
                      },
                      "t": 412.332,
                      "s": [
                        {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.825
                            ],
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -2.855,
                              2.855
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.824
                            ],
                            [
                              20.824,
                              0
                            ]
                          ],
                          "o": [
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              20.824
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              4.037
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ],
                            [
                              0,
                              -20.824
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -406.902,
                              -102.266
                            ],
                            [
                              -444.606,
                              -64.561
                            ],
                            [
                              -406.902,
                              -26.857
                            ],
                            [
                              -403.789,
                              -26.857
                            ],
                            [
                              -403.789,
                              -6.309
                            ],
                            [
                              -396.054,
                              -3.105
                            ],
                            [
                              -372.301,
                              -26.857
                            ],
                            [
                              -355.205,
                              -26.57
                            ],
                            [
                              -317.5,
                              -64.274
                            ],
                            [
                              -355.205,
                              -101.979
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "t": 464,
                      "s": [
                        {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.825
                            ],
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -2.855,
                              2.855
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.824
                            ],
                            [
                              20.824,
                              0
                            ]
                          ],
                          "o": [
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              20.824
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              4.037
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ],
                            [
                              0,
                              -20.824
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -406.902,
                              -102.266
                            ],
                            [
                              -444.606,
                              -64.561
                            ],
                            [
                              -406.902,
                              -26.857
                            ],
                            [
                              -403.789,
                              -26.857
                            ],
                            [
                              -403.789,
                              -6.309
                            ],
                            [
                              -396.054,
                              -3.105
                            ],
                            [
                              -372.301,
                              -26.857
                            ],
                            [
                              -37.955,
                              -26.857
                            ],
                            [
                              -0.25,
                              -64.561
                            ],
                            [
                              -37.955,
                              -102.266
                            ]
                          ],
                          "c": true
                        }
                      ]
                    }
                  ],
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.002,
                      0.961,
                      0.961,
                      0.961,
                      0.407,
                      0.929,
                      0.955,
                      0.98,
                      1,
                      0.898,
                      0.949,
                      1
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    -457.895,
                    -64.875
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    3.988,
                    -63.609
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    222.428,
                    51.258
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    -399,
                    -7
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 600,
        "op": 600,
        "st": 404,
        "bm": 0,
        "hidden": 404
      },
      {
        "ddd": 0,
        "ind": 3,
        "ty": 4,
        "nm": "mensaje 6",
        "sr": 1,
        "ks": {
          "o": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 355.332,
                "s": [
                  0
                ]
              },
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 365.332,
                "s": [
                  100
                ]
              },
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 510,
                "s": [
                  100
                ]
              },
              {
                "t": 560,
                "s": [
                  0
                ]
              }
            ],
            "ix": 11
          },
          "r": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.256
                  ],
                  "y": [
                    1.671
                  ]
                },
                "o": {
                  "x": [
                    0.25
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 352,
                "s": [
                  -40
                ]
              },
              {
                "t": 368.66796875,
                "s": [
                  0
                ]
              }
            ],
            "ix": 10
          },
          "p": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": 0.39,
                  "y": 1
                },
                "o": {
                  "x": 0.53,
                  "y": 0
                },
                "t": 399,
                "s": [
                  552.884,
                  433.73900000000003,
                  0
                ],
                "to": [
                  0,
                  -16.5,
                  0
                ],
                "ti": [
                  0,
                  16.5,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 433,
                "s": [
                  552.884,
                  328.739,
                  0
                ],
                "to": [
                  0,
                  -16.5,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 434,
                "s": [
                  552.884,
                  328.739,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 460,
                "s": [
                  552.884,
                  328.739,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.39,
                  "y": 1
                },
                "o": {
                  "x": 0.52,
                  "y": 0
                },
                "t": 461,
                "s": [
                  552.884,
                  328.739,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "t": 495,
                "s": [
                  552.884,
                  220.73900000000003,
                  0
                ]
              }
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              144.5,
              51.5,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.067,
                    0.067,
                    0.56
                  ],
                  "y": [
                    1.506,
                    1.506,
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.25,
                    0.25,
                    0.25
                  ],
                  "y": [
                    0,
                    0,
                    0
                  ]
                },
                "t": 352,
                "s": [
                  0,
                  0,
                  100
                ]
              },
              {
                "t": 370.33203125,
                "s": [
                  100,
                  100,
                  100
                ]
              }
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 1,
                  "k": [
                    {
                      "i": {
                        "x": 0.61,
                        "y": 1
                      },
                      "o": {
                        "x": 0.12,
                        "y": 0
                      },
                      "t": 360.332,
                      "s": [
                        {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              4.038
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ]
                          ],
                          "o": [
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              2.854,
                              2.855
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.823
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -91.902,
                              -101.884
                            ],
                            [
                              -129.606,
                              -64.179
                            ],
                            [
                              -129.606,
                              -64.178
                            ],
                            [
                              -91.902,
                              -26.473
                            ],
                            [
                              -72.555,
                              -26.856
                            ],
                            [
                              -48.802,
                              -3.105
                            ],
                            [
                              -41.067,
                              -6.309
                            ],
                            [
                              -41.067,
                              -26.856
                            ],
                            [
                              -37.955,
                              -26.856
                            ],
                            [
                              -0.25,
                              -64.561
                            ],
                            [
                              -0.25,
                              -64.562
                            ],
                            [
                              -37.955,
                              -102.267
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "t": 402,
                      "s": [
                        {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              4.038
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ]
                          ],
                          "o": [
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              2.854,
                              2.855
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.823
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -250.902,
                              -102.267
                            ],
                            [
                              -288.606,
                              -64.562
                            ],
                            [
                              -288.606,
                              -64.561
                            ],
                            [
                              -250.902,
                              -26.856
                            ],
                            [
                              -72.555,
                              -26.856
                            ],
                            [
                              -48.802,
                              -3.105
                            ],
                            [
                              -41.067,
                              -6.309
                            ],
                            [
                              -41.067,
                              -26.856
                            ],
                            [
                              -37.955,
                              -26.856
                            ],
                            [
                              -0.25,
                              -64.561
                            ],
                            [
                              -0.25,
                              -64.562
                            ],
                            [
                              -37.955,
                              -102.267
                            ]
                          ],
                          "c": true
                        }
                      ]
                    }
                  ],
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.002,
                      0.161,
                      0.435,
                      1,
                      0.407,
                      0.161,
                      0.531,
                      1,
                      1,
                      0.161,
                      0.627,
                      1
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    -284.195,
                    -64.09
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    1.207,
                    -64.031
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    144.428,
                    51.258
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    -46,
                    -7
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 600,
        "op": 600,
        "st": 352,
        "bm": 0,
        "hidden": 352
      },
      {
        "ddd": 0,
        "ind": 4,
        "ty": 4,
        "nm": "mensaje 5",
        "sr": 1,
        "ks": {
          "o": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 303.336,
                "s": [
                  0
                ]
              },
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 313.336,
                "s": [
                  100
                ]
              },
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 490,
                "s": [
                  100
                ]
              },
              {
                "t": 540,
                "s": [
                  0
                ]
              }
            ],
            "ix": 11
          },
          "r": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.112
                  ],
                  "y": [
                    1.707
                  ]
                },
                "o": {
                  "x": [
                    0.25
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 300,
                "s": [
                  40
                ]
              },
              {
                "t": 316.666015625,
                "s": [
                  0
                ]
              }
            ],
            "ix": 10
          },
          "p": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": 0.39,
                  "y": 1
                },
                "o": {
                  "x": 0.53,
                  "y": 0
                },
                "t": 347,
                "s": [
                  148.711,
                  426.74,
                  0
                ],
                "to": [
                  0,
                  -18.167,
                  0
                ],
                "ti": [
                  0,
                  18.167,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 381,
                "s": [
                  148.711,
                  330.74,
                  0
                ],
                "to": [
                  0,
                  -18.167,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 382,
                "s": [
                  148.711,
                  330.74,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 398,
                "s": [
                  148.711,
                  330.74,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.39,
                  "y": 1
                },
                "o": {
                  "x": 0.53,
                  "y": 0
                },
                "t": 399,
                "s": [
                  148.711,
                  330.74,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 433,
                "s": [
                  148.711,
                  225.74,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 434,
                "s": [
                  148.711,
                  225.74,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 460,
                "s": [
                  148.711,
                  225.74,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.39,
                  "y": 1
                },
                "o": {
                  "x": 0.52,
                  "y": 0
                },
                "t": 461,
                "s": [
                  148.711,
                  225.74,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "t": 495,
                "s": [
                  148.711,
                  117.74,
                  0
                ]
              }
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              119.5,
              51.5,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.067,
                    0.067,
                    0.56
                  ],
                  "y": [
                    1.506,
                    1.506,
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.25,
                    0.25,
                    0.25
                  ],
                  "y": [
                    0,
                    0,
                    0
                  ]
                },
                "t": 300,
                "s": [
                  0,
                  0,
                  100
                ]
              },
              {
                "t": 318.3359375,
                "s": [
                  100,
                  100,
                  100
                ]
              }
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 1,
                  "k": [
                    {
                      "i": {
                        "x": 0.61,
                        "y": 1
                      },
                      "o": {
                        "x": 0.12,
                        "y": 0
                      },
                      "t": 308.336,
                      "s": [
                        {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.825
                            ],
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -2.855,
                              2.855
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.824
                            ],
                            [
                              20.824,
                              0
                            ]
                          ],
                          "o": [
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              20.824
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              4.037
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ],
                            [
                              0,
                              -20.824
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -200.902,
                              -102.266
                            ],
                            [
                              -238.606,
                              -64.561
                            ],
                            [
                              -200.902,
                              -26.857
                            ],
                            [
                              -197.789,
                              -26.857
                            ],
                            [
                              -197.789,
                              -6.309
                            ],
                            [
                              -190.054,
                              -3.105
                            ],
                            [
                              -166.301,
                              -26.857
                            ],
                            [
                              -148.955,
                              -26.678
                            ],
                            [
                              -111.25,
                              -64.382
                            ],
                            [
                              -148.955,
                              -102.087
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "t": 350,
                      "s": [
                        {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.825
                            ],
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -2.855,
                              2.855
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.824
                            ],
                            [
                              20.824,
                              0
                            ]
                          ],
                          "o": [
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              20.824
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              4.037
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ],
                            [
                              0,
                              -20.824
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -200.902,
                              -102.266
                            ],
                            [
                              -238.606,
                              -64.561
                            ],
                            [
                              -200.902,
                              -26.857
                            ],
                            [
                              -197.789,
                              -26.857
                            ],
                            [
                              -197.789,
                              -6.309
                            ],
                            [
                              -190.054,
                              -3.105
                            ],
                            [
                              -166.301,
                              -26.857
                            ],
                            [
                              -37.955,
                              -26.857
                            ],
                            [
                              -0.25,
                              -64.561
                            ],
                            [
                              -37.955,
                              -102.266
                            ]
                          ],
                          "c": true
                        }
                      ]
                    }
                  ],
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.002,
                      0.961,
                      0.961,
                      0.961,
                      0.407,
                      0.929,
                      0.955,
                      0.98,
                      1,
                      0.898,
                      0.949,
                      1
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    -238.508,
                    -66.277
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    28.801,
                    -64.402
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    119.428,
                    51.258
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    -192,
                    -11
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 600,
        "op": 600,
        "st": 293,
        "bm": 0,
        "hidden": 300
      },
      {
        "ddd": 0,
        "ind": 5,
        "ty": 4,
        "nm": "mensaje 4",
        "sr": 1,
        "ks": {
          "o": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 180.332,
                "s": [
                  0
                ]
              },
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 190.332,
                "s": [
                  100
                ]
              },
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 250,
                "s": [
                  100
                ]
              },
              {
                "t": 300,
                "s": [
                  0
                ]
              }
            ],
            "ix": 11
          },
          "r": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.256
                  ],
                  "y": [
                    1.671
                  ]
                },
                "o": {
                  "x": [
                    0.25
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 177,
                "s": [
                  -40
                ]
              },
              {
                "t": 193.66796875,
                "s": [
                  0
                ]
              }
            ],
            "ix": 10
          },
          "p": {
            "a": 0,
            "k": [
              553.019,
              430.375,
              0
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              502.625,
              162.125,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.067,
                    0.067,
                    0.56
                  ],
                  "y": [
                    1.506,
                    1.506,
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.25,
                    0.25,
                    0.25
                  ],
                  "y": [
                    0,
                    0,
                    0
                  ]
                },
                "t": 177,
                "s": [
                  0,
                  0,
                  100
                ]
              },
              {
                "t": 195.33203125,
                "s": [
                  100,
                  100,
                  100
                ]
              }
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 1,
                  "k": [
                    {
                      "i": {
                        "x": 0.44,
                        "y": 1
                      },
                      "o": {
                        "x": 0.12,
                        "y": 0
                      },
                      "t": 185.332,
                      "s": [
                        {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              4.036
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ]
                          ],
                          "o": [
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              2.854,
                              2.855
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.823
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -150.585,
                              -163.461
                            ],
                            [
                              -188.289,
                              -125.757
                            ],
                            [
                              -188.289,
                              -125.756
                            ],
                            [
                              -150.585,
                              -88.051
                            ],
                            [
                              -130.488,
                              -88.1
                            ],
                            [
                              -106.735,
                              -64.349
                            ],
                            [
                              -99,
                              -67.552
                            ],
                            [
                              -99,
                              -88.1
                            ],
                            [
                              -95.888,
                              -88.1
                            ],
                            [
                              -58.183,
                              -125.805
                            ],
                            [
                              -58.183,
                              -125.806
                            ],
                            [
                              -95.888,
                              -163.51
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "t": 242,
                      "s": [
                        {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              4.036
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ]
                          ],
                          "o": [
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              2.854,
                              2.855
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.823
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -464.835,
                              -163.51
                            ],
                            [
                              -502.539,
                              -125.806
                            ],
                            [
                              -502.539,
                              -125.805
                            ],
                            [
                              -464.835,
                              -88.1
                            ],
                            [
                              -130.488,
                              -88.1
                            ],
                            [
                              -106.735,
                              -64.349
                            ],
                            [
                              -99,
                              -67.552
                            ],
                            [
                              -99,
                              -88.1
                            ],
                            [
                              -95.888,
                              -88.1
                            ],
                            [
                              -58.183,
                              -125.805
                            ],
                            [
                              -58.183,
                              -125.806
                            ],
                            [
                              -95.888,
                              -163.51
                            ]
                          ],
                          "c": true
                        }
                      ]
                    }
                  ],
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.002,
                      0.161,
                      0.435,
                      1,
                      0.407,
                      0.161,
                      0.531,
                      1,
                      1,
                      0.161,
                      0.627,
                      1
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    -503.219,
                    -126.02
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    -58.402,
                    -124.02
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    502.789,
                    163.76
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    -103,
                    -66
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 300,
        "op": 300,
        "st": 166,
        "bm": 0,
        "hidden": 300
      },
      {
        "ddd": 0,
        "ind": 6,
        "ty": 4,
        "nm": "mensaje 3",
        "sr": 1,
        "ks": {
          "o": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 107.332,
                "s": [
                  0
                ]
              },
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 117.332,
                "s": [
                  100
                ]
              },
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 230,
                "s": [
                  100
                ]
              },
              {
                "t": 280,
                "s": [
                  0
                ]
              }
            ],
            "ix": 11
          },
          "r": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.112
                  ],
                  "y": [
                    1.707
                  ]
                },
                "o": {
                  "x": [
                    0.25
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 104,
                "s": [
                  40
                ]
              },
              {
                "t": 120.66796875,
                "s": [
                  0
                ]
              }
            ],
            "ix": 10
          },
          "p": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": 0.39,
                  "y": 1
                },
                "o": {
                  "x": 0.52,
                  "y": 0
                },
                "t": 161,
                "s": [
                  148.211,
                  431.48099999999994,
                  0
                ],
                "to": [
                  0,
                  -16.333,
                  0
                ],
                "ti": [
                  0,
                  16.333,
                  0
                ]
              },
              {
                "t": 195,
                "s": [
                  148.211,
                  323.481,
                  0
                ]
              }
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              223,
              49.241,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.067,
                    0.067,
                    0.56
                  ],
                  "y": [
                    1.506,
                    1.506,
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.25,
                    0.25,
                    0.25
                  ],
                  "y": [
                    0,
                    0,
                    0
                  ]
                },
                "t": 104,
                "s": [
                  0,
                  0,
                  100
                ]
              },
              {
                "t": 122.33203125,
                "s": [
                  100,
                  100,
                  100
                ]
              }
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 1,
                  "k": [
                    {
                      "i": {
                        "x": 0.61,
                        "y": 1
                      },
                      "o": {
                        "x": 0.12,
                        "y": 0
                      },
                      "t": 112.332,
                      "s": [
                        {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.825
                            ],
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -2.855,
                              2.855
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.824
                            ],
                            [
                              20.824,
                              0
                            ]
                          ],
                          "o": [
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              20.824
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              4.037
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ],
                            [
                              0,
                              -20.824
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -406.902,
                              -102.266
                            ],
                            [
                              -444.606,
                              -64.561
                            ],
                            [
                              -406.902,
                              -26.857
                            ],
                            [
                              -403.789,
                              -26.857
                            ],
                            [
                              -403.789,
                              -6.309
                            ],
                            [
                              -396.054,
                              -3.105
                            ],
                            [
                              -372.301,
                              -26.857
                            ],
                            [
                              -355.205,
                              -26.57
                            ],
                            [
                              -317.5,
                              -64.274
                            ],
                            [
                              -355.205,
                              -101.979
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "t": 164,
                      "s": [
                        {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.825
                            ],
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -2.855,
                              2.855
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.824
                            ],
                            [
                              20.824,
                              0
                            ]
                          ],
                          "o": [
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              20.824
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              4.037
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ],
                            [
                              0,
                              -20.824
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -406.902,
                              -102.266
                            ],
                            [
                              -444.606,
                              -64.561
                            ],
                            [
                              -406.902,
                              -26.857
                            ],
                            [
                              -403.789,
                              -26.857
                            ],
                            [
                              -403.789,
                              -6.309
                            ],
                            [
                              -396.054,
                              -3.105
                            ],
                            [
                              -372.301,
                              -26.857
                            ],
                            [
                              -37.955,
                              -26.857
                            ],
                            [
                              -0.25,
                              -64.561
                            ],
                            [
                              -37.955,
                              -102.266
                            ]
                          ],
                          "c": true
                        }
                      ]
                    }
                  ],
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.002,
                      0.961,
                      0.961,
                      0.961,
                      0.407,
                      0.929,
                      0.955,
                      0.98,
                      1,
                      0.898,
                      0.949,
                      1
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    -457.895,
                    -64.875
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    3.988,
                    -63.609
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    222.428,
                    51.258
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    -399,
                    -7
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 300,
        "op": 300,
        "st": 104,
        "bm": 0,
        "hidden": 104
      },
      {
        "ddd": 0,
        "ind": 7,
        "ty": 4,
        "nm": "mensaje 2",
        "sr": 1,
        "ks": {
          "o": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 55.332,
                "s": [
                  0
                ]
              },
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 65.332,
                "s": [
                  100
                ]
              },
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 210,
                "s": [
                  100
                ]
              },
              {
                "t": 260,
                "s": [
                  0
                ]
              }
            ],
            "ix": 11
          },
          "r": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.256
                  ],
                  "y": [
                    1.671
                  ]
                },
                "o": {
                  "x": [
                    0.25
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 52,
                "s": [
                  -40
                ]
              },
              {
                "t": 68.66796875,
                "s": [
                  0
                ]
              }
            ],
            "ix": 10
          },
          "p": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": 0.39,
                  "y": 1
                },
                "o": {
                  "x": 0.53,
                  "y": 0
                },
                "t": 99,
                "s": [
                  552.884,
                  433.73900000000003,
                  0
                ],
                "to": [
                  0,
                  -16.5,
                  0
                ],
                "ti": [
                  0,
                  16.5,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 133,
                "s": [
                  552.884,
                  328.739,
                  0
                ],
                "to": [
                  0,
                  -16.5,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 134,
                "s": [
                  552.884,
                  328.739,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 160,
                "s": [
                  552.884,
                  328.739,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.39,
                  "y": 1
                },
                "o": {
                  "x": 0.52,
                  "y": 0
                },
                "t": 161,
                "s": [
                  552.884,
                  328.739,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "t": 195,
                "s": [
                  552.884,
                  220.73900000000003,
                  0
                ]
              }
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              144.5,
              51.5,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.067,
                    0.067,
                    0.56
                  ],
                  "y": [
                    1.506,
                    1.506,
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.25,
                    0.25,
                    0.25
                  ],
                  "y": [
                    0,
                    0,
                    0
                  ]
                },
                "t": 52,
                "s": [
                  0,
                  0,
                  100
                ]
              },
              {
                "t": 70.33203125,
                "s": [
                  100,
                  100,
                  100
                ]
              }
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 1,
                  "k": [
                    {
                      "i": {
                        "x": 0.61,
                        "y": 1
                      },
                      "o": {
                        "x": 0.12,
                        "y": 0
                      },
                      "t": 60.332,
                      "s": [
                        {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              4.038
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ]
                          ],
                          "o": [
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              2.854,
                              2.855
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.823
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -91.902,
                              -101.884
                            ],
                            [
                              -129.606,
                              -64.179
                            ],
                            [
                              -129.606,
                              -64.178
                            ],
                            [
                              -91.902,
                              -26.473
                            ],
                            [
                              -72.555,
                              -26.856
                            ],
                            [
                              -48.802,
                              -3.105
                            ],
                            [
                              -41.067,
                              -6.309
                            ],
                            [
                              -41.067,
                              -26.856
                            ],
                            [
                              -37.955,
                              -26.856
                            ],
                            [
                              -0.25,
                              -64.561
                            ],
                            [
                              -0.25,
                              -64.562
                            ],
                            [
                              -37.955,
                              -102.267
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "t": 102,
                      "s": [
                        {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              4.038
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ]
                          ],
                          "o": [
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.823
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              2.854,
                              2.855
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.823
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -250.902,
                              -102.267
                            ],
                            [
                              -288.606,
                              -64.562
                            ],
                            [
                              -288.606,
                              -64.561
                            ],
                            [
                              -250.902,
                              -26.856
                            ],
                            [
                              -72.555,
                              -26.856
                            ],
                            [
                              -48.802,
                              -3.105
                            ],
                            [
                              -41.067,
                              -6.309
                            ],
                            [
                              -41.067,
                              -26.856
                            ],
                            [
                              -37.955,
                              -26.856
                            ],
                            [
                              -0.25,
                              -64.561
                            ],
                            [
                              -0.25,
                              -64.562
                            ],
                            [
                              -37.955,
                              -102.267
                            ]
                          ],
                          "c": true
                        }
                      ]
                    }
                  ],
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.002,
                      0,
                      0,
                      0,
                      0.407,
                      0,
                      0,
                      0,
                      1,
                      0,
                      0,
                      0
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    -284.195,
                    -64.09
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    1.207,
                    -64.031
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    144.428,
                    51.258
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    -46,
                    -7
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 300,
        "op": 300,
        "st": 52,
        "bm": 0,
        "hidden": 52
      },
      {
        "ddd": 0,
        "ind": 8,
        "ty": 4,
        "nm": "mensaje 1",
        "sr": 1,
        "ks": {
          "o": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 3.336,
                "s": [
                  0
                ]
              },
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 13.336,
                "s": [
                  100
                ]
              },
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 190,
                "s": [
                  100
                ]
              },
              {
                "t": 240,
                "s": [
                  0
                ]
              }
            ],
            "ix": 11
          },
          "r": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.112
                  ],
                  "y": [
                    1.707
                  ]
                },
                "o": {
                  "x": [
                    0.25
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 0,
                "s": [
                  40
                ]
              },
              {
                "t": 16.666015625,
                "s": [
                  0
                ]
              }
            ],
            "ix": 10
          },
          "p": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": 0.39,
                  "y": 1
                },
                "o": {
                  "x": 0.53,
                  "y": 0
                },
                "t": 47,
                "s": [
                  148.711,
                  426.74,
                  0
                ],
                "to": [
                  0,
                  -18.167,
                  0
                ],
                "ti": [
                  0,
                  18.167,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 81,
                "s": [
                  148.711,
                  330.74,
                  0
                ],
                "to": [
                  0,
                  -18.167,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 82,
                "s": [
                  148.711,
                  330.74,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 98,
                "s": [
                  148.711,
                  330.74,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.39,
                  "y": 1
                },
                "o": {
                  "x": 0.53,
                  "y": 0
                },
                "t": 99,
                "s": [
                  148.711,
                  330.74,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 133,
                "s": [
                  148.711,
                  225.74,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 134,
                "s": [
                  148.711,
                  225.74,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.833,
                  "y": 0.833
                },
                "o": {
                  "x": 0.167,
                  "y": 0.167
                },
                "t": 160,
                "s": [
                  148.711,
                  225.74,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "i": {
                  "x": 0.39,
                  "y": 1
                },
                "o": {
                  "x": 0.52,
                  "y": 0
                },
                "t": 161,
                "s": [
                  148.711,
                  225.74,
                  0
                ],
                "to": [
                  0,
                  0,
                  0
                ],
                "ti": [
                  0,
                  0,
                  0
                ]
              },
              {
                "t": 195,
                "s": [
                  148.711,
                  117.74,
                  0
                ]
              }
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              119.5,
              51.5,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.067,
                    0.067,
                    0.56
                  ],
                  "y": [
                    1.506,
                    1.506,
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.25,
                    0.25,
                    0.25
                  ],
                  "y": [
                    0,
                    0,
                    0
                  ]
                },
                "t": 0,
                "s": [
                  0,
                  0,
                  100
                ]
              },
              {
                "t": 18.3359375,
                "s": [
                  100,
                  100,
                  100
                ]
              }
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 1,
                  "k": [
                    {
                      "i": {
                        "x": 0.61,
                        "y": 1
                      },
                      "o": {
                        "x": 0.12,
                        "y": 0
                      },
                      "t": 8.336,
                      "s": [
                        {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.825
                            ],
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -2.855,
                              2.855
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.824
                            ],
                            [
                              20.824,
                              0
                            ]
                          ],
                          "o": [
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              20.824
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              4.037
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ],
                            [
                              0,
                              -20.824
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -200.902,
                              -102.266
                            ],
                            [
                              -238.606,
                              -64.561
                            ],
                            [
                              -200.902,
                              -26.857
                            ],
                            [
                              -197.789,
                              -26.857
                            ],
                            [
                              -197.789,
                              -6.309
                            ],
                            [
                              -190.054,
                              -3.105
                            ],
                            [
                              -166.301,
                              -26.857
                            ],
                            [
                              -148.955,
                              -26.678
                            ],
                            [
                              -111.25,
                              -64.382
                            ],
                            [
                              -148.955,
                              -102.087
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "t": 50,
                      "s": [
                        {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -20.825
                            ],
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -2.855,
                              2.855
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              20.824
                            ],
                            [
                              20.824,
                              0
                            ]
                          ],
                          "o": [
                            [
                              -20.823,
                              0
                            ],
                            [
                              0,
                              20.824
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              4.037
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              20.824,
                              0
                            ],
                            [
                              0,
                              -20.824
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -200.902,
                              -102.266
                            ],
                            [
                              -238.606,
                              -64.561
                            ],
                            [
                              -200.902,
                              -26.857
                            ],
                            [
                              -197.789,
                              -26.857
                            ],
                            [
                              -197.789,
                              -6.309
                            ],
                            [
                              -190.054,
                              -3.105
                            ],
                            [
                              -166.301,
                              -26.857
                            ],
                            [
                              -37.955,
                              -26.857
                            ],
                            [
                              -0.25,
                              -64.561
                            ],
                            [
                              -37.955,
                              -102.266
                            ]
                          ],
                          "c": true
                        }
                      ]
                    }
                  ],
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.002,
                      0.961,
                      0.961,
                      0.961,
                      0.407,
                      0.929,
                      0.955,
                      0.98,
                      1,
                      0.898,
                      0.949,
                      1
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    -238.508,
                    -66.277
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    28.801,
                    -64.402
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    119.428,
                    51.258
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    -192,
                    -11
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 300,
        "op": 300,
        "st": -7,
        "bm": 0,
        "hidden": 0
      },
      {
        "ddd": 0,
        "ind": 10,
        "ty": 4,
        "nm": "base",
        "sr": 1,
        "ks": {
          "o": {
            "a": 0,
            "k": 100,
            "ix": 11
          },
          "r": {
            "a": 0,
            "k": 0,
            "ix": 10
          },
          "p": {
            "a": 0,
            "k": [
              672.425,
              882.44,
              0
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              562.026,
              14.34,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 0,
            "k": [
              100,
              100,
              100
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        718.909,
                        0.25
                      ],
                      [
                        791.967,
                        0.25
                      ],
                      [
                        791.967,
                        12.939
                      ],
                      [
                        718.909,
                        12.939
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.438999998803,
                    0.698000021542,
                    0.866999966491,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 2",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ind": 2,
                "ty": "sh",
                "ix": 3,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        791.966,
                        0.25
                      ],
                      [
                        555.105,
                        0.25
                      ],
                      [
                        555.105,
                        12.939
                      ],
                      [
                        791.966,
                        12.939
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.670999983245,
                    0.855000035903,
                    0.969000004787,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    673.536,
                    6.594
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    673.536,
                    6.594
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "mousepad",
            "np": 4,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        1123.803,
                        28.429
                      ],
                      [
                        874.637,
                        28.429
                      ],
                      [
                        874.637,
                        12.939
                      ],
                      [
                        1123.803,
                        12.939
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.161000001197,
                    0.626999978458,
                    1,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ind": 2,
                "ty": "sh",
                "ix": 3,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        1123.803,
                        28.429
                      ],
                      [
                        0.25,
                        28.429
                      ],
                      [
                        0.25,
                        12.939
                      ],
                      [
                        1123.803,
                        12.939
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.161000001197,
                    0.764999988032,
                    1,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 2",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    562.026,
                    20.684
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    562.026,
                    20.684
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "base",
            "np": 4,
            "cix": 2,
            "bm": 0,
            "ix": 2,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 0,
        "op": 600,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 11,
        "ty": 4,
        "nm": "laptop",
        "sr": 1,
        "ks": {
          "o": {
            "a": 0,
            "k": 100,
            "ix": 11
          },
          "r": {
            "a": 0,
            "k": 0,
            "ix": 10
          },
          "p": {
            "a": 0,
            "k": [
              335.973,
              726.258,
              0
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              141.35,
              57.895,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 0,
            "k": [
              100,
              100,
              100
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        1.587,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -1.588
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.588,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        1.587
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        -1.588,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        1.587
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.587,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -1.588
                      ]
                    ],
                    "v": [
                      [
                        121.601,
                        -7.197
                      ],
                      [
                        -121.601,
                        -7.197
                      ],
                      [
                        -124.476,
                        -4.322
                      ],
                      [
                        -124.476,
                        4.323
                      ],
                      [
                        -121.601,
                        7.198
                      ],
                      [
                        121.601,
                        7.198
                      ],
                      [
                        124.476,
                        4.323
                      ],
                      [
                        124.476,
                        -4.322
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.21568627450980393,
                    0.33725490196078434,
                    0.7607843137254902,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    199.078,
                    205.646
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "base_compu2",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -5.373,
                        -13.085
                      ],
                      [
                        9.043,
                        -3.714
                      ],
                      [
                        5.373,
                        13.085
                      ],
                      [
                        -9.042,
                        3.714
                      ]
                    ],
                    "o": [
                      [
                        5.374,
                        13.085
                      ],
                      [
                        -9.043,
                        3.713
                      ],
                      [
                        -5.374,
                        -13.084
                      ],
                      [
                        9.044,
                        -3.714
                      ]
                    ],
                    "v": [
                      [
                        16.374,
                        -6.725
                      ],
                      [
                        9.729,
                        23.692
                      ],
                      [
                        -16.374,
                        6.723
                      ],
                      [
                        -9.73,
                        -23.693
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.894000004787,
                    0.922000002394,
                    0.969000004787,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    153.883,
                    109.844
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 66,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "circulito",
            "np": 2,
            "cix": 2,
            "bm": 3,
            "ix": 2,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        -3.632,
                        -9.321
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -5.859,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.27,
                        5.825
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        5.86,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -10.003,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.127,
                        5.46
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        6.251,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.127,
                        -5.46
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        -303.852,
                        -198.292
                      ],
                      [
                        -317.098,
                        -178.913
                      ],
                      [
                        -252.536,
                        -13.239
                      ],
                      [
                        -239.29,
                        -4.184
                      ],
                      [
                        -10.458,
                        -4.184
                      ],
                      [
                        -2.18,
                        -16.295
                      ],
                      [
                        -69.574,
                        -189.237
                      ],
                      [
                        -82.821,
                        -198.292
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.002,
                      0.224,
                      0.592,
                      0.871,
                      0.407,
                      0.208,
                      0.453,
                      0.804,
                      1,
                      0.192,
                      0.314,
                      0.737
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    -187.471,
                    -206.533
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    -178.652,
                    3.809
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    319.79,
                    202.831
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "gradient_laptop",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 3,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        5.936,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -3.68,
                        -9.518
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -5.935,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.299,
                        5.948
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        -10.133,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.155,
                        5.575
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        6.332,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.155,
                        -5.575
                      ]
                    ],
                    "v": [
                      [
                        78.505,
                        -99.109
                      ],
                      [
                        -145.392,
                        -99.109
                      ],
                      [
                        -158.81,
                        -79.32
                      ],
                      [
                        -93.412,
                        89.863
                      ],
                      [
                        -79.994,
                        99.109
                      ],
                      [
                        151.806,
                        99.109
                      ],
                      [
                        160.191,
                        86.743
                      ],
                      [
                        91.923,
                        -89.863
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.258999992819,
                    0.666999966491,
                    1,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    162.74,
                    99.547
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "laptop1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 4,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        1.596,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -1.596
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.596,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        1.595
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        -1.596,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        1.595
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.596,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -1.596
                      ]
                    ],
                    "v": [
                      [
                        132.065,
                        -7.197
                      ],
                      [
                        -132.064,
                        -7.197
                      ],
                      [
                        -134.953,
                        -4.307
                      ],
                      [
                        -134.953,
                        4.309
                      ],
                      [
                        -132.064,
                        7.198
                      ],
                      [
                        132.065,
                        7.198
                      ],
                      [
                        134.953,
                        4.309
                      ],
                      [
                        134.953,
                        -4.307
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.097999999102,
                    0.161000001197,
                    0.380000005984,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    305.509,
                    205.646
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "base_compu",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 5,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 0,
        "op": 600,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 12,
        "ty": 4,
        "nm": "Cabeza/Designer boy ready contornos",
        "sr": 1,
        "ks": {
          "o": {
            "a": 0,
            "k": 100,
            "ix": 11
          },
          "r": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.57
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.17
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 0,
                "s": [
                  -2.839
                ]
              },
              {
                "t": 60,
                "s": [
                  5.357
                ]
              }
            ],
            "ix": 10,
            "x": "var $bm_rt;\n$bm_rt = loopOut('pingpong', 0);"
          },
          "p": {
            "a": 0,
            "k": [
              771.429,
              497.667,
              0
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              121.5,
              200.5,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 0,
            "k": [
              100,
              100,
              100
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.901,
                        -30.037
                      ],
                      [
                        -10.317,
                        10.702
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.125,
                        9.496
                      ],
                      [
                        8.388,
                        -5.826
                      ],
                      [
                        -8.708,
                        7.159
                      ],
                      [
                        0.652,
                        -5.394
                      ],
                      [
                        -28.417,
                        -19.83
                      ],
                      [
                        -12.613,
                        15.24
                      ],
                      [
                        -6.9,
                        13.025
                      ],
                      [
                        -2.163,
                        19.439
                      ]
                    ],
                    "o": [
                      [
                        -13.053,
                        -11.998
                      ],
                      [
                        -14.446,
                        14.985
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -11.447,
                        0.903
                      ],
                      [
                        -1.747,
                        14.447
                      ],
                      [
                        28.417,
                        19.831
                      ],
                      [
                        12.612,
                        -15.241
                      ],
                      [
                        10.241,
                        -3.997
                      ],
                      [
                        2.163,
                        -19.438
                      ]
                    ],
                    "v": [
                      [
                        46.798,
                        -23.565
                      ],
                      [
                        39.693,
                        -69.326
                      ],
                      [
                        -14.831,
                        -59.234
                      ],
                      [
                        -3.181,
                        -72.318
                      ],
                      [
                        -56.539,
                        -57.836
                      ],
                      [
                        -48.433,
                        -73.534
                      ],
                      [
                        -69.821,
                        -64.828
                      ],
                      [
                        -34.448,
                        53.703
                      ],
                      [
                        38.923,
                        27.432
                      ],
                      [
                        52.927,
                        -6.365
                      ],
                      [
                        69.405,
                        -28.315
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.9176470588235294,
                    0.7568627450980392,
                    0.6901960784313725,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    111.662,
                    166.741
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0.409,
                        -1.963
                      ],
                      [
                        -5.98,
                        -7.934
                      ],
                      [
                        9.552,
                        7.864
                      ],
                      [
                        -2.446,
                        -8.55
                      ],
                      [
                        -13.264,
                        -3.957
                      ],
                      [
                        -11.887,
                        -7.475
                      ],
                      [
                        -26.467,
                        1.739
                      ],
                      [
                        -9.147,
                        -0.193
                      ],
                      [
                        -1.565,
                        1.597
                      ],
                      [
                        -0.714,
                        -0.614
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.251,
                        8.682
                      ],
                      [
                        0.058,
                        1.988
                      ],
                      [
                        -2.925,
                        4.043
                      ],
                      [
                        -2.94,
                        13.895
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.162,
                        3.916
                      ],
                      [
                        7.005,
                        1.4
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.974,
                        -5.451
                      ],
                      [
                        3.437,
                        4.023
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -4.284,
                        -3.922
                      ],
                      [
                        16.63,
                        -5.589
                      ],
                      [
                        11.234,
                        6.566
                      ],
                      [
                        0.686,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -1.599,
                        7.681
                      ],
                      [
                        -11.959,
                        -6.313
                      ],
                      [
                        -10.368,
                        -8.535
                      ],
                      [
                        2.447,
                        8.549
                      ],
                      [
                        5.538,
                        19.476
                      ],
                      [
                        16.259,
                        10.228
                      ],
                      [
                        11.327,
                        -0.745
                      ],
                      [
                        1.747,
                        -2.031
                      ],
                      [
                        0.215,
                        3.028
                      ],
                      [
                        1.671,
                        1.435
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.122,
                        -4.226
                      ],
                      [
                        2.554,
                        -2.649
                      ],
                      [
                        7.244,
                        -10.01
                      ],
                      [
                        1.227,
                        4.974
                      ],
                      [
                        1.402,
                        -18.818
                      ],
                      [
                        -1.306,
                        -5.601
                      ],
                      [
                        9.372,
                        -13.834
                      ],
                      [
                        1.139,
                        5.78
                      ],
                      [
                        -0.867,
                        -4.48
                      ],
                      [
                        -9.286,
                        -15.945
                      ],
                      [
                        2.177,
                        0.766
                      ],
                      [
                        -15.124,
                        -0.663
                      ],
                      [
                        -16.03,
                        5.389
                      ],
                      [
                        -0.651,
                        -0.38
                      ],
                      [
                        -1.825,
                        0
                      ]
                    ],
                    "v": [
                      [
                        -170.374,
                        -170.848
                      ],
                      [
                        -167.74,
                        -141.305
                      ],
                      [
                        -187.429,
                        -155.81
                      ],
                      [
                        -194.176,
                        -106.221
                      ],
                      [
                        -174.558,
                        -68.184
                      ],
                      [
                        -144.534,
                        -27.011
                      ],
                      [
                        -72.702,
                        -20.263
                      ],
                      [
                        -41.386,
                        -21.56
                      ],
                      [
                        -36.445,
                        -26.925
                      ],
                      [
                        -35.124,
                        -20.461
                      ],
                      [
                        -33.006,
                        -0.25
                      ],
                      [
                        -27.54,
                        -26.273
                      ],
                      [
                        -27.806,
                        -35.498
                      ],
                      [
                        -19.707,
                        -45.18
                      ],
                      [
                        -3.513,
                        -86.768
                      ],
                      [
                        -1.652,
                        -78.117
                      ],
                      [
                        -2.175,
                        -109.501
                      ],
                      [
                        -13.837,
                        -120.845
                      ],
                      [
                        -18.585,
                        -156.946
                      ],
                      [
                        -19.068,
                        -138.952
                      ],
                      [
                        -24.698,
                        -151.729
                      ],
                      [
                        -69.508,
                        -172.937
                      ],
                      [
                        -59.06,
                        -165
                      ],
                      [
                        -108.207,
                        -156.946
                      ],
                      [
                        -164.38,
                        -173.5
                      ],
                      [
                        -166.418,
                        -174.057
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.003,
                      0.28627450980392155,
                      0.27058823529411763,
                      0.26666666666666666,
                      0.408,
                      0.2235294117647059,
                      0.2196078431372549,
                      0.2196078431372549,
                      0.999,
                      0.17254901960784313,
                      0.17254901960784313,
                      0.17254901960784313
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    -7.039,
                    -91.215
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    -179.242,
                    -132.891
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    96.038,
                    90.438
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    -102,
                    -84
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 2",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 2,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 0,
        "op": 600,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 13,
        "ty": 4,
        "nm": "Cuello",
        "sr": 1,
        "ks": {
          "o": {
            "a": 0,
            "k": 100,
            "ix": 11
          },
          "r": {
            "a": 0,
            "k": 0,
            "ix": 10
          },
          "p": {
            "a": 0,
            "k": [
              770.935,
              531.661,
              0
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              46,
              77.5,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 0,
            "k": [
              100,
              100,
              100
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        48.918,
                        15.339
                      ],
                      [
                        -0.195,
                        0.291
                      ],
                      [
                        1.605,
                        19.087
                      ],
                      [
                        -27.441,
                        12.449
                      ],
                      [
                        2.042,
                        -14.002
                      ]
                    ],
                    "o": [
                      [
                        -0.17,
                        1.101
                      ],
                      [
                        -3.171,
                        -18.955
                      ],
                      [
                        27.489,
                        -11.79
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -4.36,
                        19.966
                      ]
                    ],
                    "v": [
                      [
                        -36.329,
                        39.435
                      ],
                      [
                        -35.648,
                        37.715
                      ],
                      [
                        -41.628,
                        -19.42
                      ],
                      [
                        40.719,
                        -54.775
                      ],
                      [
                        39.586,
                        -40.123
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.807843137254902,
                    0.6235294117647059,
                    0.5529411764705883,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    48.235,
                    55.025
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -2.006,
                        -17.997
                      ],
                      [
                        35.047,
                        0.857
                      ],
                      [
                        -6.51,
                        20.041
                      ],
                      [
                        -0.733,
                        1.974
                      ],
                      [
                        1.623,
                        19.028
                      ],
                      [
                        -27.306,
                        12.206
                      ],
                      [
                        -0.022,
                        -4.757
                      ],
                      [
                        -0.054,
                        -11.909
                      ]
                    ],
                    "o": [
                      [
                        -9.689,
                        29.48
                      ],
                      [
                        -30.6,
                        -0.747
                      ],
                      [
                        0.967,
                        -2.978
                      ],
                      [
                        -3.247,
                        -18.724
                      ],
                      [
                        27.452,
                        -11.726
                      ],
                      [
                        0.104,
                        4.308
                      ],
                      [
                        0.021,
                        4.758
                      ],
                      [
                        0.054,
                        11.909
                      ]
                    ],
                    "v": [
                      [
                        45.615,
                        6.846
                      ],
                      [
                        -15.015,
                        76.05
                      ],
                      [
                        -34.308,
                        18.638
                      ],
                      [
                        -33.244,
                        15.368
                      ],
                      [
                        -39.257,
                        -41.662
                      ],
                      [
                        43.029,
                        -76.907
                      ],
                      [
                        43.175,
                        -67.626
                      ],
                      [
                        43.167,
                        -64.576
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.9176470588235294,
                    0.7568627450980392,
                    0.6901960784313725,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    45.865,
                    77.268
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 2",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 2,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 0,
        "op": 600,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 14,
        "ty": 4,
        "nm": "brazo",
        "sr": 1,
        "ks": {
          "o": {
            "a": 0,
            "k": 100,
            "ix": 11
          },
          "r": {
            "a": 0,
            "k": 0,
            "ix": 10
          },
          "p": {
            "a": 0,
            "k": [
              576.195,
              817.515,
              0
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              75.5,
              53.5,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 0,
            "k": [
              100,
              100,
              100
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ty": "gr",
                "it": [
                  {
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                      "a": 0,
                      "k": {
                        "i": [
                          [
                            -1.84,
                            0.42
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.773,
                            0.911
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -1.665,
                            -0.036
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -1.076,
                            1.332
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -1.9,
                            -0.239
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.906,
                            0.391
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -1.028,
                            -20.84
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            2.215,
                            -0.42
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.468,
                            1.657
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            2.335,
                            -0.557
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.552,
                            2.485
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            2.282,
                            -0.289
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.536,
                            1.864
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            1.807,
                            -0.537
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.654,
                            2.032
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.714,
                            1.991
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            1.165,
                            -0.266
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            1.077,
                            -1.269
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            1.712,
                            0.037
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            1.203,
                            -1.489
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.978,
                            0.122
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.225,
                            2.244
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -1.692,
                            0.322
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.397,
                            2.368
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -2.478,
                            0.59
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.289,
                            2.281
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -1.924,
                            0.244
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.382,
                            1.845
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -2.048,
                            0.609
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.648,
                            -2.014
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.637,
                            -1.776
                          ]
                        ],
                        "v": [
                          [
                            -28.417,
                            -10.437
                          ],
                          [
                            -26.073,
                            -10.971
                          ],
                          [
                            -23.086,
                            -12.784
                          ],
                          [
                            -20.522,
                            -15.802
                          ],
                          [
                            -16.189,
                            -17.751
                          ],
                          [
                            -13.058,
                            -17.685
                          ],
                          [
                            -8.638,
                            -19.739
                          ],
                          [
                            -7.117,
                            -21.622
                          ],
                          [
                            -2.126,
                            -23.633
                          ],
                          [
                            3.981,
                            -22.867
                          ],
                          [
                            6.859,
                            -23.276
                          ],
                          [
                            14.313,
                            -26.489
                          ],
                          [
                            34.253,
                            0.736
                          ],
                          [
                            33.288,
                            10.385
                          ],
                          [
                            29.174,
                            14.878
                          ],
                          [
                            21.723,
                            16.294
                          ],
                          [
                            17.906,
                            13.934
                          ],
                          [
                            14.184,
                            0.771
                          ],
                          [
                            16.421,
                            14.115
                          ],
                          [
                            12.978,
                            19.309
                          ],
                          [
                            7.148,
                            20.697
                          ],
                          [
                            1.637,
                            17.25
                          ],
                          [
                            -2.051,
                            0.678
                          ],
                          [
                            0.131,
                            17.891
                          ],
                          [
                            -3.476,
                            22.545
                          ],
                          [
                            -8.964,
                            23.242
                          ],
                          [
                            -13.239,
                            20.427
                          ],
                          [
                            -18.69,
                            1.484
                          ],
                          [
                            -14.792,
                            20.273
                          ],
                          [
                            -17.311,
                            24.486
                          ],
                          [
                            -22.005,
                            25.88
                          ],
                          [
                            -26.864,
                            23.318
                          ],
                          [
                            -33.605,
                            2.354
                          ],
                          [
                            -33.503,
                            -3.821
                          ],
                          [
                            -32.394,
                            -6.912
                          ]
                        ],
                        "c": true
                      },
                      "ix": 2
                    },
                    "nm": "Trazado 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                  },
                  {
                    "ty": "fl",
                    "c": {
                      "a": 0,
                      "k": [
                        0.26666666666666666,
                        0.2196078431372549,
                        0.20784313725490197,
                        1
                      ],
                      "ix": 4
                    },
                    "o": {
                      "a": 0,
                      "k": 100,
                      "ix": 5
                    },
                    "r": 1,
                    "bm": 0,
                    "nm": "Relleno 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                  },
                  {
                    "ty": "tr",
                    "p": {
                      "a": 0,
                      "k": [
                        34.503,
                        77.694
                      ],
                      "ix": 2
                    },
                    "a": {
                      "a": 0,
                      "k": [
                        0,
                        0
                      ],
                      "ix": 1
                    },
                    "s": {
                      "a": 0,
                      "k": [
                        100,
                        100
                      ],
                      "ix": 3
                    },
                    "r": {
                      "a": 0,
                      "k": 0,
                      "ix": 6
                    },
                    "o": {
                      "a": 0,
                      "k": 100,
                      "ix": 7
                    },
                    "sk": {
                      "a": 0,
                      "k": 0,
                      "ix": 4
                    },
                    "sa": {
                      "a": 0,
                      "k": 0,
                      "ix": 5
                    },
                    "nm": "Transformar"
                  }
                ],
                "nm": "Grupo 1",
                "np": 2,
                "cix": 2,
                "bm": 0,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
              },
              {
                "ty": "gr",
                "it": [
                  {
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                      "a": 0,
                      "k": {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.825,
                            -3.567
                          ],
                          [
                            -2.282,
                            0.468
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.486,
                            1.712
                          ],
                          [
                            -0.025,
                            0.643
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -3.395,
                            1.37
                          ],
                          [
                            0.526,
                            2.269
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            1.759,
                            -0.272
                          ],
                          [
                            0.652,
                            -2.295
                          ],
                          [
                            0.042,
                            -1.08
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            3.382,
                            0.709
                          ],
                          [
                            -6.92,
                            4.864
                          ],
                          [
                            -11.383,
                            13.438
                          ],
                          [
                            -6.338,
                            16.677
                          ],
                          [
                            3.362,
                            14.691
                          ],
                          [
                            6.997,
                            14.131
                          ],
                          [
                            10.68,
                            10.876
                          ],
                          [
                            12.167,
                            4.972
                          ],
                          [
                            4.104,
                            -17.145
                          ]
                        ],
                        "c": true
                      },
                      "ix": 2
                    },
                    "nm": "Trazado 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                  },
                  {
                    "ty": "fl",
                    "c": {
                      "a": 0,
                      "k": [
                        0.26666666666666666,
                        0.2196078431372549,
                        0.20784313725490197,
                        1
                      ],
                      "ix": 4
                    },
                    "o": {
                      "a": 0,
                      "k": 100,
                      "ix": 5
                    },
                    "r": 1,
                    "bm": 0,
                    "nm": "Relleno 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                  },
                  {
                    "ty": "tr",
                    "p": {
                      "a": 0,
                      "k": [
                        63.763,
                        89.037
                      ],
                      "ix": 2
                    },
                    "a": {
                      "a": 0,
                      "k": [
                        0,
                        0
                      ],
                      "ix": 1
                    },
                    "s": {
                      "a": 0,
                      "k": [
                        100,
                        100
                      ],
                      "ix": 3
                    },
                    "r": {
                      "a": 0,
                      "k": 0,
                      "ix": 6
                    },
                    "o": {
                      "a": 0,
                      "k": 100,
                      "ix": 7
                    },
                    "sk": {
                      "a": 0,
                      "k": 0,
                      "ix": 4
                    },
                    "sa": {
                      "a": 0,
                      "k": 0,
                      "ix": 5
                    },
                    "nm": "Transformar"
                  }
                ],
                "nm": "Grupo 2",
                "np": 2,
                "cix": 2,
                "bm": 0,
                "ix": 2,
                "mn": "ADBE Vector Group",
                "hd": false
              },
              {
                "ty": "gr",
                "it": [
                  {
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                      "a": 0,
                      "k": {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.734,
                            -0.138
                          ],
                          [
                            -2.007,
                            -0.284
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            -0.529,
                            0.066
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.735,
                            0.138
                          ],
                          [
                            2.007,
                            0.283
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            25.019,
                            15.591
                          ],
                          [
                            -24.743,
                            17.51
                          ],
                          [
                            -28.205,
                            -9.796
                          ],
                          [
                            12.435,
                            -17.226
                          ],
                          [
                            28.939,
                            12.584
                          ]
                        ],
                        "c": true
                      },
                      "ix": 2
                    },
                    "nm": "Trazado 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                  },
                  {
                    "ty": "fl",
                    "c": {
                      "a": 0,
                      "k": [
                        0.19215686274509805,
                        0.16470588235294117,
                        0.1568627450980392,
                        1
                      ],
                      "ix": 4
                    },
                    "o": {
                      "a": 0,
                      "k": 100,
                      "ix": 5
                    },
                    "r": 1,
                    "bm": 0,
                    "nm": "Relleno 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                  },
                  {
                    "ty": "tr",
                    "p": {
                      "a": 0,
                      "k": [
                        34.544,
                        86.459
                      ],
                      "ix": 2
                    },
                    "a": {
                      "a": 0,
                      "k": [
                        0,
                        0
                      ],
                      "ix": 1
                    },
                    "s": {
                      "a": 0,
                      "k": [
                        100,
                        100
                      ],
                      "ix": 3
                    },
                    "r": {
                      "a": 0,
                      "k": 0,
                      "ix": 6
                    },
                    "o": {
                      "a": 0,
                      "k": 100,
                      "ix": 7
                    },
                    "sk": {
                      "a": 0,
                      "k": 0,
                      "ix": 4
                    },
                    "sa": {
                      "a": 0,
                      "k": 0,
                      "ix": 5
                    },
                    "nm": "Transformar"
                  }
                ],
                "nm": "Grupo 3",
                "np": 2,
                "cix": 2,
                "bm": 0,
                "ix": 3,
                "mn": "ADBE Vector Group",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    38.183,
                    78.504
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    38.183,
                    78.504
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Mano",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        72.093,
                        -4.88
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        2.08,
                        -46.503
                      ],
                      [
                        -74.173,
                        51.382
                      ],
                      [
                        0.268,
                        51.164
                      ],
                      [
                        60.864,
                        17.497
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.9176470588235294,
                    0.7568627450980392,
                    0.6901960784313725,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    75.659,
                    51.633
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "brazo",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 2,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 0,
        "op": 600,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 15,
        "ty": 4,
        "nm": "mano_uno",
        "sr": 1,
        "ks": {
          "o": {
            "a": 0,
            "k": 100,
            "ix": 11
          },
          "r": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.65
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.15
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 0,
                "s": [
                  -2.31
                ]
              },
              {
                "t": 60,
                "s": [
                  7.166
                ]
              }
            ],
            "ix": 10,
            "x": "var $bm_rt;\n$bm_rt = loopOut('pingpong', 0);"
          },
          "p": {
            "a": 0,
            "k": [
              824.606,
              873.835,
              0
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              104.25,
              55.25,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 0,
            "k": [
              100,
              100,
              100
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -0.054,
                        0.05
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.509,
                        -0.555
                      ],
                      [
                        0.556,
                        -0.51
                      ],
                      [
                        0.035,
                        -0.025
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.048,
                        0.064
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0.555,
                        -0.508
                      ],
                      [
                        0.51,
                        0.556
                      ],
                      [
                        -0.032,
                        0.029
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.065,
                        0.048
                      ],
                      [
                        -0.044,
                        -0.06
                      ]
                    ],
                    "v": [
                      [
                        -10.484,
                        8.646
                      ],
                      [
                        8.108,
                        -8.408
                      ],
                      [
                        10.037,
                        -8.324
                      ],
                      [
                        9.952,
                        -6.395
                      ],
                      [
                        9.852,
                        -6.311
                      ],
                      [
                        -10.299,
                        8.868
                      ],
                      [
                        -10.502,
                        8.839
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.9176470588235294,
                    0.7568627450980392,
                    0.6901960784313725,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    38.812,
                    47.097
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -0.078,
                        0.066
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.484,
                        -0.57
                      ],
                      [
                        0.569,
                        -0.484
                      ],
                      [
                        0.032,
                        -0.023
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.064,
                        0.09
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0.569,
                        -0.485
                      ],
                      [
                        0.486,
                        0.569
                      ],
                      [
                        -0.03,
                        0.026
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.091,
                        0.065
                      ],
                      [
                        -0.061,
                        -0.086
                      ]
                    ],
                    "v": [
                      [
                        -11.109,
                        8.527
                      ],
                      [
                        8.808,
                        -8.424
                      ],
                      [
                        10.717,
                        -8.27
                      ],
                      [
                        10.564,
                        -6.361
                      ],
                      [
                        10.469,
                        -6.286
                      ],
                      [
                        -10.862,
                        8.844
                      ],
                      [
                        -11.142,
                        8.797
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.807843137254902,
                    0.6235294117647059,
                    0.5529411764705883,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    28.559,
                    35.957
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 2",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 2,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        14.482,
                        11.057
                      ],
                      [
                        -10.509,
                        -11.085
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.069,
                        16.266
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        10.51,
                        11.084
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        -22.009,
                        -29.797
                      ],
                      [
                        -16.987,
                        7.209
                      ],
                      [
                        27.496,
                        29.797
                      ],
                      [
                        23.457,
                        -1.958
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.807843137254902,
                    0.6235294117647059,
                    0.5529411764705883,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    83.303,
                    31.951
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 3",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 3,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -9.688,
                        -3.002
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        10.543,
                        -1.688
                      ],
                      [
                        1.208,
                        -4.56
                      ],
                      [
                        -10.543,
                        4.56
                      ],
                      [
                        9.079,
                        0.118
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.807843137254902,
                    0.6235294117647059,
                    0.5529411764705883,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    54.407,
                    56.51
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 4",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 4,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        1.546,
                        4.23
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.756,
                        -5.3
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        -12.64,
                        -2.415
                      ],
                      [
                        12.64,
                        -6.789
                      ],
                      [
                        -0.936,
                        6.789
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.807843137254902,
                    0.6235294117647059,
                    0.5529411764705883,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    15.137,
                    25.01
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 5",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 5,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        20.274,
                        14.835
                      ],
                      [
                        2.565,
                        -0.346
                      ],
                      [
                        16.75,
                        -4.653
                      ],
                      [
                        -0.175,
                        -2.249
                      ],
                      [
                        -2.194,
                        -2.988
                      ],
                      [
                        -6.079,
                        -2.89
                      ],
                      [
                        0.055,
                        -1.624
                      ],
                      [
                        -2.887,
                        -1.154
                      ],
                      [
                        -0.643,
                        -2.622
                      ],
                      [
                        -2.102,
                        -0.315
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.25,
                        -2.203
                      ],
                      [
                        -2.422,
                        -0.489
                      ],
                      [
                        -3.129,
                        1.195
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -20.247,
                        2.643
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        -2.088,
                        -1.528
                      ],
                      [
                        -9.541,
                        1.292
                      ],
                      [
                        -2.174,
                        0.604
                      ],
                      [
                        0.212,
                        2.719
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.055,
                        1.624
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.641,
                        2.623
                      ],
                      [
                        2.103,
                        0.316
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.25,
                        2.203
                      ],
                      [
                        2.422,
                        0.488
                      ],
                      [
                        3.129,
                        -1.194
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        51.236,
                        -4.041
                      ],
                      [
                        6.174,
                        -31.598
                      ],
                      [
                        -1.071,
                        -33.436
                      ],
                      [
                        -51.635,
                        -23.951
                      ],
                      [
                        -55.099,
                        -19.034
                      ],
                      [
                        -51.961,
                        -9.668
                      ],
                      [
                        -41.323,
                        -2.234
                      ],
                      [
                        -43.445,
                        2.814
                      ],
                      [
                        -38.271,
                        10.793
                      ],
                      [
                        -38.495,
                        15.012
                      ],
                      [
                        -33.034,
                        21.701
                      ],
                      [
                        -27.105,
                        21.82
                      ],
                      [
                        -28.43,
                        27.303
                      ],
                      [
                        -21.969,
                        33.294
                      ],
                      [
                        0.089,
                        29.98
                      ],
                      [
                        9.424,
                        20.789
                      ],
                      [
                        55.274,
                        27.714
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.9176470588235294,
                    0.7568627450980392,
                    0.6901960784313725,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    55.524,
                    34.033
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 6",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 6,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 0,
        "op": 600,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 16,
        "ty": 4,
        "nm": "Cuerpo",
        "sr": 1,
        "ks": {
          "o": {
            "a": 0,
            "k": 100,
            "ix": 11
          },
          "r": {
            "a": 0,
            "k": 0,
            "ix": 10
          },
          "p": {
            "a": 0,
            "k": [
              779.677,
              706,
              0
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              205,
              175,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 0,
            "k": [
              100,
              100,
              100
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        -56.207,
                        -13.041
                      ],
                      [
                        13.751,
                        -7.937
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        50.097,
                        11.624
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        -77.13,
                        2.155
                      ],
                      [
                        31.355,
                        -21.375
                      ],
                      [
                        59.048,
                        34.416
                      ],
                      [
                        -81.452,
                        34.416
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.9176470588235294,
                    0.7568627450980392,
                    0.6901960784313725,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    328.216,
                    314.759
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "brazo",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        18.148,
                        32.271
                      ],
                      [
                        36.173,
                        -26.784
                      ],
                      [
                        23.756,
                        55.719
                      ],
                      [
                        46.142,
                        -31.722
                      ],
                      [
                        19.609,
                        -55.372
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.281,
                        6.524
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        1.264,
                        -27.558
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -46.141,
                        31.722
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.916,
                        -9.707
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        143.589,
                        33.906
                      ],
                      [
                        56.361,
                        32.38
                      ],
                      [
                        33.402,
                        -112.759
                      ],
                      [
                        -7.549,
                        32.01
                      ],
                      [
                        -151.741,
                        112.759
                      ],
                      [
                        141.812,
                        112.759
                      ],
                      [
                        143.912,
                        88.197
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.9176470588235294,
                    0.7568627450980392,
                    0.6901960784313725,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 30,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    245.452,
                    236.416
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "sombra",
            "np": 2,
            "cix": 2,
            "bm": 1,
            "ix": 2,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -1.846,
                        -17.995
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.153,
                        8.536
                      ],
                      [
                        -6.044,
                        20.071
                      ],
                      [
                        0.554,
                        3.136
                      ]
                    ],
                    "o": [
                      [
                        -3.829,
                        29.484
                      ],
                      [
                        -1.108,
                        15.042
                      ],
                      [
                        -1.569,
                        -13.842
                      ],
                      [
                        9.183,
                        -8.859
                      ],
                      [
                        12.228,
                        -40.42
                      ],
                      [
                        9.967,
                        37.79
                      ]
                    ],
                    "v": [
                      [
                        23.394,
                        20.464
                      ],
                      [
                        3.969,
                        65.545
                      ],
                      [
                        -14.488,
                        81.694
                      ],
                      [
                        -23.394,
                        48.426
                      ],
                      [
                        2.261,
                        5.791
                      ],
                      [
                        6.137,
                        -81.693
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.9176470588235294,
                    0.7568627450980392,
                    0.6901960784313725,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 30,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    77.03,
                    219.586
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "sombra 2",
            "np": 2,
            "cix": 2,
            "bm": 1,
            "ix": 3,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        35.206,
                        -27.685
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -3.508,
                        -18.457
                      ],
                      [
                        -17.027,
                        -35.206
                      ],
                      [
                        -1.569,
                        -13.843
                      ],
                      [
                        -1.107,
                        15.042
                      ],
                      [
                        -3.83,
                        29.484
                      ],
                      [
                        -0.047,
                        0.554
                      ],
                      [
                        8.674,
                        -30.177
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.68,
                        9.788
                      ],
                      [
                        18.149,
                        29.709
                      ],
                      [
                        23.071,
                        39.773
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        -35.206,
                        27.686
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.153,
                        8.536
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.092,
                        -0.553
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -23.071,
                        -39.774
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        -235.542,
                        -345.327
                      ],
                      [
                        -328.148,
                        -303.8
                      ],
                      [
                        -387.533,
                        -135.382
                      ],
                      [
                        -403.681,
                        -112.311
                      ],
                      [
                        -353.803,
                        -81.581
                      ],
                      [
                        -344.898,
                        -48.312
                      ],
                      [
                        -326.441,
                        -64.462
                      ],
                      [
                        -307.015,
                        -109.543
                      ],
                      [
                        -306.784,
                        -111.158
                      ],
                      [
                        -313.728,
                        -0.417
                      ],
                      [
                        -70.852,
                        -0.25
                      ],
                      [
                        -20.175,
                        -0.417
                      ],
                      [
                        -18.075,
                        -24.979
                      ],
                      [
                        -18.399,
                        -79.27
                      ],
                      [
                        -37.455,
                        -286.496
                      ],
                      [
                        -176.434,
                        -349.342
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.002,
                      1,
                      0.7686274509803922,
                      0.20392156862745098,
                      0.407,
                      1,
                      0.7098039215686275,
                      0,
                      0.999,
                      0.7333333333333333,
                      0.5411764705882353,
                      0.07058823529411765
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    -155.492,
                    -228.27
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    -457.156,
                    -229.848
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    203.719,
                    174.796
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    -204,
                    -175
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "franela",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 4,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 0,
        "op": 600,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 17,
        "ty": 4,
        "nm": "humito_uno",
        "sr": 1,
        "ks": {
          "o": {
            "a": 0,
            "k": 100,
            "ix": 11
          },
          "r": {
            "a": 0,
            "k": 0,
            "ix": 10
          },
          "p": {
            "a": 0,
            "k": [
              1047.353,
              706.51,
              0
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              6.5,
              51,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 0,
            "k": [
              100,
              100,
              100
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 1,
                  "k": [
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 0,
                      "s": [
                        {
                          "i": [
                            [
                              -4.358,
                              27.457
                            ],
                            [
                              3.084,
                              -14.172
                            ],
                            [
                              0.142,
                              -13.988
                            ],
                            [
                              -5.824,
                              20.299
                            ]
                          ],
                          "o": [
                            [
                              -0.608,
                              33.702
                            ],
                            [
                              -3.084,
                              14.172
                            ],
                            [
                              -5.483,
                              -11.668
                            ],
                            [
                              5.824,
                              -20.299
                            ]
                          ],
                          "v": [
                            [
                              9.5,
                              -50.432
                            ],
                            [
                              -2.024,
                              -0.568
                            ],
                            [
                              -7.25,
                              50.254
                            ],
                            [
                              -8.057,
                              -7.316
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 25,
                      "s": [
                        {
                          "i": [
                            [
                              -8.858,
                              16.284
                            ],
                            [
                              -0.358,
                              -14.5
                            ],
                            [
                              -6.108,
                              -29.532
                            ],
                            [
                              -2.426,
                              24.846
                            ]
                          ],
                          "o": [
                            [
                              -1.733,
                              11.287
                            ],
                            [
                              0.416,
                              16.849
                            ],
                            [
                              -7.983,
                              -13.472
                            ],
                            [
                              2.052,
                              -21.018
                            ]
                          ],
                          "v": [
                            [
                              1,
                              -50.501
                            ],
                            [
                              -5.149,
                              -3.958
                            ],
                            [
                              2,
                              50.274
                            ],
                            [
                              -12.557,
                              -11.777
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 50,
                      "s": [
                        {
                          "i": [
                            [
                              -5.983,
                              15.021
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              1.017,
                              -22.085
                            ],
                            [
                              6.449,
                              27.904
                            ]
                          ],
                          "o": [
                            [
                              -0.483,
                              13.594
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -7.483,
                              -27.617
                            ],
                            [
                              -4.755,
                              -20.575
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              -0.274,
                              -1.706
                            ],
                            [
                              10.5,
                              50.321
                            ],
                            [
                              -9.807,
                              -5.256
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 75,
                      "s": [
                        {
                          "i": [
                            [
                              -2.108,
                              25.549
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              9.449,
                              26.278
                            ]
                          ],
                          "o": [
                            [
                              4.642,
                              27.333
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -0.983,
                              -18.097
                            ],
                            [
                              -7.602,
                              -21.144
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              9.726,
                              -7.332
                            ],
                            [
                              7.75,
                              50.259
                            ],
                            [
                              0.693,
                              -2.325
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 100,
                      "s": [
                        {
                          "i": [
                            [
                              4.142,
                              24.089
                            ],
                            [
                              0.058,
                              -14.504
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              0.074,
                              24.851
                            ]
                          ],
                          "o": [
                            [
                              9.392,
                              14.275
                            ],
                            [
                              -0.084,
                              21.217
                            ],
                            [
                              3.017,
                              -12.592
                            ],
                            [
                              -0.067,
                              -22.469
                            ]
                          ],
                          "v": [
                            [
                              0.125,
                              -50.455
                            ],
                            [
                              15.226,
                              -4.579
                            ],
                            [
                              -2.375,
                              50.285
                            ],
                            [
                              5.443,
                              -2.825
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 125,
                      "s": [
                        {
                          "i": [
                            [
                              -1.733,
                              21.87
                            ],
                            [
                              6.038,
                              -13.188
                            ],
                            [
                              3.642,
                              -24.075
                            ],
                            [
                              -10.676,
                              23.659
                            ]
                          ],
                          "o": [
                            [
                              6.892,
                              11.521
                            ],
                            [
                              -9.959,
                              21.753
                            ],
                            [
                              -1.233,
                              -12.298
                            ],
                            [
                              9.242,
                              -20.48
                            ]
                          ],
                          "v": [
                            [
                              8,
                              -50.377
                            ],
                            [
                              8.976,
                              2.023
                            ],
                            [
                              -9.375,
                              50.349
                            ],
                            [
                              -0.557,
                              -0.776
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 150,
                      "s": [
                        {
                          "i": [
                            [
                              -4.358,
                              27.457
                            ],
                            [
                              3.084,
                              -14.172
                            ],
                            [
                              0.142,
                              -13.988
                            ],
                            [
                              -5.824,
                              20.299
                            ]
                          ],
                          "o": [
                            [
                              -0.608,
                              33.702
                            ],
                            [
                              -3.084,
                              14.172
                            ],
                            [
                              -5.483,
                              -11.668
                            ],
                            [
                              5.824,
                              -20.299
                            ]
                          ],
                          "v": [
                            [
                              9.5,
                              -50.432
                            ],
                            [
                              -2.024,
                              -0.568
                            ],
                            [
                              -7.25,
                              50.254
                            ],
                            [
                              -8.057,
                              -7.316
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 175,
                      "s": [
                        {
                          "i": [
                            [
                              -8.858,
                              16.284
                            ],
                            [
                              -0.358,
                              -14.5
                            ],
                            [
                              -6.108,
                              -29.532
                            ],
                            [
                              -2.426,
                              24.846
                            ]
                          ],
                          "o": [
                            [
                              -1.733,
                              11.287
                            ],
                            [
                              0.416,
                              16.849
                            ],
                            [
                              -7.983,
                              -13.472
                            ],
                            [
                              2.052,
                              -21.018
                            ]
                          ],
                          "v": [
                            [
                              1,
                              -50.501
                            ],
                            [
                              -5.149,
                              -3.958
                            ],
                            [
                              2,
                              50.274
                            ],
                            [
                              -12.557,
                              -11.777
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 200,
                      "s": [
                        {
                          "i": [
                            [
                              -5.983,
                              15.021
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              1.017,
                              -22.085
                            ],
                            [
                              6.449,
                              27.904
                            ]
                          ],
                          "o": [
                            [
                              -0.483,
                              13.594
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -7.483,
                              -27.617
                            ],
                            [
                              -4.755,
                              -20.575
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              -0.274,
                              -1.706
                            ],
                            [
                              10.5,
                              50.321
                            ],
                            [
                              -9.807,
                              -5.256
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 225,
                      "s": [
                        {
                          "i": [
                            [
                              -2.108,
                              25.549
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              9.449,
                              26.278
                            ]
                          ],
                          "o": [
                            [
                              4.642,
                              27.333
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -0.983,
                              -18.097
                            ],
                            [
                              -7.602,
                              -21.144
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              9.726,
                              -7.332
                            ],
                            [
                              7.75,
                              50.259
                            ],
                            [
                              0.693,
                              -2.325
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 250,
                      "s": [
                        {
                          "i": [
                            [
                              4.142,
                              24.089
                            ],
                            [
                              0.058,
                              -14.504
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              0.074,
                              24.851
                            ]
                          ],
                          "o": [
                            [
                              9.392,
                              14.275
                            ],
                            [
                              -0.084,
                              21.217
                            ],
                            [
                              3.017,
                              -12.592
                            ],
                            [
                              -0.067,
                              -22.469
                            ]
                          ],
                          "v": [
                            [
                              0.125,
                              -50.455
                            ],
                            [
                              15.226,
                              -4.579
                            ],
                            [
                              -2.375,
                              50.285
                            ],
                            [
                              5.443,
                              -2.825
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 275,
                      "s": [
                        {
                          "i": [
                            [
                              -1.733,
                              21.87
                            ],
                            [
                              6.038,
                              -13.188
                            ],
                            [
                              3.642,
                              -24.075
                            ],
                            [
                              -10.676,
                              23.659
                            ]
                          ],
                          "o": [
                            [
                              6.892,
                              11.521
                            ],
                            [
                              -9.959,
                              21.753
                            ],
                            [
                              -1.233,
                              -12.298
                            ],
                            [
                              9.242,
                              -20.48
                            ]
                          ],
                          "v": [
                            [
                              8,
                              -50.377
                            ],
                            [
                              8.976,
                              2.023
                            ],
                            [
                              -9.375,
                              50.349
                            ],
                            [
                              -0.557,
                              -0.776
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 300,
                      "s": [
                        {
                          "i": [
                            [
                              -4.358,
                              27.457
                            ],
                            [
                              3.084,
                              -14.172
                            ],
                            [
                              0.142,
                              -13.988
                            ],
                            [
                              -5.824,
                              20.299
                            ]
                          ],
                          "o": [
                            [
                              -0.608,
                              33.702
                            ],
                            [
                              -3.084,
                              14.172
                            ],
                            [
                              -5.483,
                              -11.668
                            ],
                            [
                              5.824,
                              -20.299
                            ]
                          ],
                          "v": [
                            [
                              9.5,
                              -50.432
                            ],
                            [
                              -2.024,
                              -0.568
                            ],
                            [
                              -7.25,
                              50.254
                            ],
                            [
                              -8.057,
                              -7.316
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 325,
                      "s": [
                        {
                          "i": [
                            [
                              -8.858,
                              16.284
                            ],
                            [
                              -0.358,
                              -14.5
                            ],
                            [
                              -6.108,
                              -29.532
                            ],
                            [
                              -2.426,
                              24.846
                            ]
                          ],
                          "o": [
                            [
                              -1.733,
                              11.287
                            ],
                            [
                              0.416,
                              16.849
                            ],
                            [
                              -7.983,
                              -13.472
                            ],
                            [
                              2.052,
                              -21.018
                            ]
                          ],
                          "v": [
                            [
                              1,
                              -50.501
                            ],
                            [
                              -5.149,
                              -3.958
                            ],
                            [
                              2,
                              50.274
                            ],
                            [
                              -12.557,
                              -11.777
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 350,
                      "s": [
                        {
                          "i": [
                            [
                              -5.983,
                              15.021
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              1.017,
                              -22.085
                            ],
                            [
                              6.449,
                              27.904
                            ]
                          ],
                          "o": [
                            [
                              -0.483,
                              13.594
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -7.483,
                              -27.617
                            ],
                            [
                              -4.755,
                              -20.575
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              -0.274,
                              -1.706
                            ],
                            [
                              10.5,
                              50.321
                            ],
                            [
                              -9.807,
                              -5.256
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 375,
                      "s": [
                        {
                          "i": [
                            [
                              -2.108,
                              25.549
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              9.449,
                              26.278
                            ]
                          ],
                          "o": [
                            [
                              4.642,
                              27.333
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -0.983,
                              -18.097
                            ],
                            [
                              -7.602,
                              -21.144
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              9.726,
                              -7.332
                            ],
                            [
                              7.75,
                              50.259
                            ],
                            [
                              0.693,
                              -2.325
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 400,
                      "s": [
                        {
                          "i": [
                            [
                              4.142,
                              24.089
                            ],
                            [
                              0.058,
                              -14.504
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              0.074,
                              24.851
                            ]
                          ],
                          "o": [
                            [
                              9.392,
                              14.275
                            ],
                            [
                              -0.084,
                              21.217
                            ],
                            [
                              3.017,
                              -12.592
                            ],
                            [
                              -0.067,
                              -22.469
                            ]
                          ],
                          "v": [
                            [
                              0.125,
                              -50.455
                            ],
                            [
                              15.226,
                              -4.579
                            ],
                            [
                              -2.375,
                              50.285
                            ],
                            [
                              5.443,
                              -2.825
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 425,
                      "s": [
                        {
                          "i": [
                            [
                              -1.733,
                              21.87
                            ],
                            [
                              6.038,
                              -13.188
                            ],
                            [
                              3.642,
                              -24.075
                            ],
                            [
                              -10.676,
                              23.659
                            ]
                          ],
                          "o": [
                            [
                              6.892,
                              11.521
                            ],
                            [
                              -9.959,
                              21.753
                            ],
                            [
                              -1.233,
                              -12.298
                            ],
                            [
                              9.242,
                              -20.48
                            ]
                          ],
                          "v": [
                            [
                              8,
                              -50.377
                            ],
                            [
                              8.976,
                              2.023
                            ],
                            [
                              -9.375,
                              50.349
                            ],
                            [
                              -0.557,
                              -0.776
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 450,
                      "s": [
                        {
                          "i": [
                            [
                              -4.358,
                              27.457
                            ],
                            [
                              3.084,
                              -14.172
                            ],
                            [
                              0.142,
                              -13.988
                            ],
                            [
                              -5.824,
                              20.299
                            ]
                          ],
                          "o": [
                            [
                              -0.608,
                              33.702
                            ],
                            [
                              -3.084,
                              14.172
                            ],
                            [
                              -5.483,
                              -11.668
                            ],
                            [
                              5.824,
                              -20.299
                            ]
                          ],
                          "v": [
                            [
                              9.5,
                              -50.432
                            ],
                            [
                              -2.024,
                              -0.568
                            ],
                            [
                              -7.25,
                              50.254
                            ],
                            [
                              -8.057,
                              -7.316
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 475,
                      "s": [
                        {
                          "i": [
                            [
                              -8.858,
                              16.284
                            ],
                            [
                              -0.358,
                              -14.5
                            ],
                            [
                              -6.108,
                              -29.532
                            ],
                            [
                              -2.426,
                              24.846
                            ]
                          ],
                          "o": [
                            [
                              -1.733,
                              11.287
                            ],
                            [
                              0.416,
                              16.849
                            ],
                            [
                              -7.983,
                              -13.472
                            ],
                            [
                              2.052,
                              -21.018
                            ]
                          ],
                          "v": [
                            [
                              1,
                              -50.501
                            ],
                            [
                              -5.149,
                              -3.958
                            ],
                            [
                              2,
                              50.274
                            ],
                            [
                              -12.557,
                              -11.777
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 500,
                      "s": [
                        {
                          "i": [
                            [
                              -5.983,
                              15.021
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              1.017,
                              -22.085
                            ],
                            [
                              6.449,
                              27.904
                            ]
                          ],
                          "o": [
                            [
                              -0.483,
                              13.594
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -7.483,
                              -27.617
                            ],
                            [
                              -4.755,
                              -20.575
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              -0.274,
                              -1.706
                            ],
                            [
                              10.5,
                              50.321
                            ],
                            [
                              -9.807,
                              -5.256
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 525,
                      "s": [
                        {
                          "i": [
                            [
                              -2.108,
                              25.549
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              9.449,
                              26.278
                            ]
                          ],
                          "o": [
                            [
                              4.642,
                              27.333
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -0.983,
                              -18.097
                            ],
                            [
                              -7.602,
                              -21.144
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              9.726,
                              -7.332
                            ],
                            [
                              7.75,
                              50.259
                            ],
                            [
                              0.693,
                              -2.325
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 550,
                      "s": [
                        {
                          "i": [
                            [
                              4.142,
                              24.089
                            ],
                            [
                              0.058,
                              -14.504
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              0.074,
                              24.851
                            ]
                          ],
                          "o": [
                            [
                              9.392,
                              14.275
                            ],
                            [
                              -0.084,
                              21.217
                            ],
                            [
                              3.017,
                              -12.592
                            ],
                            [
                              -0.067,
                              -22.469
                            ]
                          ],
                          "v": [
                            [
                              0.125,
                              -50.455
                            ],
                            [
                              15.226,
                              -4.579
                            ],
                            [
                              -2.375,
                              50.285
                            ],
                            [
                              5.443,
                              -2.825
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 575,
                      "s": [
                        {
                          "i": [
                            [
                              -1.733,
                              21.87
                            ],
                            [
                              6.038,
                              -13.188
                            ],
                            [
                              3.642,
                              -24.075
                            ],
                            [
                              -10.676,
                              23.659
                            ]
                          ],
                          "o": [
                            [
                              6.892,
                              11.521
                            ],
                            [
                              -9.959,
                              21.753
                            ],
                            [
                              -1.233,
                              -12.298
                            ],
                            [
                              9.242,
                              -20.48
                            ]
                          ],
                          "v": [
                            [
                              8,
                              -50.377
                            ],
                            [
                              8.976,
                              2.023
                            ],
                            [
                              -9.375,
                              50.349
                            ],
                            [
                              -0.557,
                              -0.776
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "t": 600,
                      "s": [
                        {
                          "i": [
                            [
                              -4.358,
                              27.457
                            ],
                            [
                              3.084,
                              -14.172
                            ],
                            [
                              0.142,
                              -13.988
                            ],
                            [
                              -5.824,
                              20.299
                            ]
                          ],
                          "o": [
                            [
                              -0.608,
                              33.702
                            ],
                            [
                              -3.084,
                              14.172
                            ],
                            [
                              -5.483,
                              -11.668
                            ],
                            [
                              5.824,
                              -20.299
                            ]
                          ],
                          "v": [
                            [
                              9.5,
                              -50.432
                            ],
                            [
                              -2.024,
                              -0.568
                            ],
                            [
                              -7.25,
                              50.254
                            ],
                            [
                              -8.057,
                              -7.316
                            ]
                          ],
                          "c": true
                        }
                      ]
                    }
                  ],
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    1,
                    1,
                    1,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 70,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    13.347,
                    40.738
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    122.67,
                    85.934
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 2",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 1,
                  "k": [
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 0,
                      "s": [
                        {
                          "i": [
                            [
                              -4.358,
                              27.457
                            ],
                            [
                              3.084,
                              -14.172
                            ],
                            [
                              0.142,
                              -13.988
                            ],
                            [
                              -5.824,
                              20.299
                            ]
                          ],
                          "o": [
                            [
                              -0.608,
                              33.702
                            ],
                            [
                              -3.084,
                              14.172
                            ],
                            [
                              -5.483,
                              -11.668
                            ],
                            [
                              5.824,
                              -20.299
                            ]
                          ],
                          "v": [
                            [
                              9.5,
                              -50.432
                            ],
                            [
                              -2.024,
                              -0.568
                            ],
                            [
                              -7.25,
                              50.254
                            ],
                            [
                              -8.057,
                              -7.316
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 25,
                      "s": [
                        {
                          "i": [
                            [
                              -8.858,
                              16.284
                            ],
                            [
                              -0.358,
                              -14.5
                            ],
                            [
                              -6.108,
                              -29.532
                            ],
                            [
                              -2.426,
                              24.846
                            ]
                          ],
                          "o": [
                            [
                              -1.733,
                              11.287
                            ],
                            [
                              0.416,
                              16.849
                            ],
                            [
                              -7.983,
                              -13.472
                            ],
                            [
                              2.052,
                              -21.018
                            ]
                          ],
                          "v": [
                            [
                              1,
                              -50.501
                            ],
                            [
                              -5.149,
                              -3.958
                            ],
                            [
                              2,
                              50.274
                            ],
                            [
                              -12.557,
                              -11.777
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 50,
                      "s": [
                        {
                          "i": [
                            [
                              -5.983,
                              15.021
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              1.017,
                              -22.085
                            ],
                            [
                              6.449,
                              27.904
                            ]
                          ],
                          "o": [
                            [
                              -0.483,
                              13.594
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -7.483,
                              -27.617
                            ],
                            [
                              -4.755,
                              -20.575
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              -0.274,
                              -1.706
                            ],
                            [
                              10.5,
                              50.321
                            ],
                            [
                              -9.807,
                              -5.256
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 75,
                      "s": [
                        {
                          "i": [
                            [
                              -2.108,
                              25.549
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              9.449,
                              26.278
                            ]
                          ],
                          "o": [
                            [
                              4.642,
                              27.333
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -0.983,
                              -18.097
                            ],
                            [
                              -7.602,
                              -21.144
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              9.726,
                              -7.332
                            ],
                            [
                              7.75,
                              50.259
                            ],
                            [
                              0.693,
                              -2.325
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 100,
                      "s": [
                        {
                          "i": [
                            [
                              4.142,
                              24.089
                            ],
                            [
                              0.058,
                              -14.504
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              0.074,
                              24.851
                            ]
                          ],
                          "o": [
                            [
                              9.392,
                              14.275
                            ],
                            [
                              -0.084,
                              21.217
                            ],
                            [
                              3.017,
                              -12.592
                            ],
                            [
                              -0.067,
                              -22.469
                            ]
                          ],
                          "v": [
                            [
                              0.125,
                              -50.455
                            ],
                            [
                              15.226,
                              -4.579
                            ],
                            [
                              -2.375,
                              50.285
                            ],
                            [
                              5.443,
                              -2.825
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 125,
                      "s": [
                        {
                          "i": [
                            [
                              -1.733,
                              21.87
                            ],
                            [
                              6.038,
                              -13.188
                            ],
                            [
                              3.642,
                              -24.075
                            ],
                            [
                              -10.676,
                              23.659
                            ]
                          ],
                          "o": [
                            [
                              6.892,
                              11.521
                            ],
                            [
                              -9.959,
                              21.753
                            ],
                            [
                              -1.233,
                              -12.298
                            ],
                            [
                              9.242,
                              -20.48
                            ]
                          ],
                          "v": [
                            [
                              8,
                              -50.377
                            ],
                            [
                              8.976,
                              2.023
                            ],
                            [
                              -9.375,
                              50.349
                            ],
                            [
                              -0.557,
                              -0.776
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 150,
                      "s": [
                        {
                          "i": [
                            [
                              -4.358,
                              27.457
                            ],
                            [
                              3.084,
                              -14.172
                            ],
                            [
                              0.142,
                              -13.988
                            ],
                            [
                              -5.824,
                              20.299
                            ]
                          ],
                          "o": [
                            [
                              -0.608,
                              33.702
                            ],
                            [
                              -3.084,
                              14.172
                            ],
                            [
                              -5.483,
                              -11.668
                            ],
                            [
                              5.824,
                              -20.299
                            ]
                          ],
                          "v": [
                            [
                              9.5,
                              -50.432
                            ],
                            [
                              -2.024,
                              -0.568
                            ],
                            [
                              -7.25,
                              50.254
                            ],
                            [
                              -8.057,
                              -7.316
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 175,
                      "s": [
                        {
                          "i": [
                            [
                              -8.858,
                              16.284
                            ],
                            [
                              -0.358,
                              -14.5
                            ],
                            [
                              -6.108,
                              -29.532
                            ],
                            [
                              -2.426,
                              24.846
                            ]
                          ],
                          "o": [
                            [
                              -1.733,
                              11.287
                            ],
                            [
                              0.416,
                              16.849
                            ],
                            [
                              -7.983,
                              -13.472
                            ],
                            [
                              2.052,
                              -21.018
                            ]
                          ],
                          "v": [
                            [
                              1,
                              -50.501
                            ],
                            [
                              -5.149,
                              -3.958
                            ],
                            [
                              2,
                              50.274
                            ],
                            [
                              -12.557,
                              -11.777
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 200,
                      "s": [
                        {
                          "i": [
                            [
                              -5.983,
                              15.021
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              1.017,
                              -22.085
                            ],
                            [
                              6.449,
                              27.904
                            ]
                          ],
                          "o": [
                            [
                              -0.483,
                              13.594
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -7.483,
                              -27.617
                            ],
                            [
                              -4.755,
                              -20.575
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              -0.274,
                              -1.706
                            ],
                            [
                              10.5,
                              50.321
                            ],
                            [
                              -9.807,
                              -5.256
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 225,
                      "s": [
                        {
                          "i": [
                            [
                              -2.108,
                              25.549
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              9.449,
                              26.278
                            ]
                          ],
                          "o": [
                            [
                              4.642,
                              27.333
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -0.983,
                              -18.097
                            ],
                            [
                              -7.602,
                              -21.144
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              9.726,
                              -7.332
                            ],
                            [
                              7.75,
                              50.259
                            ],
                            [
                              0.693,
                              -2.325
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 250,
                      "s": [
                        {
                          "i": [
                            [
                              4.142,
                              24.089
                            ],
                            [
                              0.058,
                              -14.504
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              0.074,
                              24.851
                            ]
                          ],
                          "o": [
                            [
                              9.392,
                              14.275
                            ],
                            [
                              -0.084,
                              21.217
                            ],
                            [
                              3.017,
                              -12.592
                            ],
                            [
                              -0.067,
                              -22.469
                            ]
                          ],
                          "v": [
                            [
                              0.125,
                              -50.455
                            ],
                            [
                              15.226,
                              -4.579
                            ],
                            [
                              -2.375,
                              50.285
                            ],
                            [
                              5.443,
                              -2.825
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 275,
                      "s": [
                        {
                          "i": [
                            [
                              -1.733,
                              21.87
                            ],
                            [
                              6.038,
                              -13.188
                            ],
                            [
                              3.642,
                              -24.075
                            ],
                            [
                              -10.676,
                              23.659
                            ]
                          ],
                          "o": [
                            [
                              6.892,
                              11.521
                            ],
                            [
                              -9.959,
                              21.753
                            ],
                            [
                              -1.233,
                              -12.298
                            ],
                            [
                              9.242,
                              -20.48
                            ]
                          ],
                          "v": [
                            [
                              8,
                              -50.377
                            ],
                            [
                              8.976,
                              2.023
                            ],
                            [
                              -9.375,
                              50.349
                            ],
                            [
                              -0.557,
                              -0.776
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 300,
                      "s": [
                        {
                          "i": [
                            [
                              -4.358,
                              27.457
                            ],
                            [
                              3.084,
                              -14.172
                            ],
                            [
                              0.142,
                              -13.988
                            ],
                            [
                              -5.824,
                              20.299
                            ]
                          ],
                          "o": [
                            [
                              -0.608,
                              33.702
                            ],
                            [
                              -3.084,
                              14.172
                            ],
                            [
                              -5.483,
                              -11.668
                            ],
                            [
                              5.824,
                              -20.299
                            ]
                          ],
                          "v": [
                            [
                              9.5,
                              -50.432
                            ],
                            [
                              -2.024,
                              -0.568
                            ],
                            [
                              -7.25,
                              50.254
                            ],
                            [
                              -8.057,
                              -7.316
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 325,
                      "s": [
                        {
                          "i": [
                            [
                              -8.858,
                              16.284
                            ],
                            [
                              -0.358,
                              -14.5
                            ],
                            [
                              -6.108,
                              -29.532
                            ],
                            [
                              -2.426,
                              24.846
                            ]
                          ],
                          "o": [
                            [
                              -1.733,
                              11.287
                            ],
                            [
                              0.416,
                              16.849
                            ],
                            [
                              -7.983,
                              -13.472
                            ],
                            [
                              2.052,
                              -21.018
                            ]
                          ],
                          "v": [
                            [
                              1,
                              -50.501
                            ],
                            [
                              -5.149,
                              -3.958
                            ],
                            [
                              2,
                              50.274
                            ],
                            [
                              -12.557,
                              -11.777
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 350,
                      "s": [
                        {
                          "i": [
                            [
                              -5.983,
                              15.021
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              1.017,
                              -22.085
                            ],
                            [
                              6.449,
                              27.904
                            ]
                          ],
                          "o": [
                            [
                              -0.483,
                              13.594
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -7.483,
                              -27.617
                            ],
                            [
                              -4.755,
                              -20.575
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              -0.274,
                              -1.706
                            ],
                            [
                              10.5,
                              50.321
                            ],
                            [
                              -9.807,
                              -5.256
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 375,
                      "s": [
                        {
                          "i": [
                            [
                              -2.108,
                              25.549
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              9.449,
                              26.278
                            ]
                          ],
                          "o": [
                            [
                              4.642,
                              27.333
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -0.983,
                              -18.097
                            ],
                            [
                              -7.602,
                              -21.144
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              9.726,
                              -7.332
                            ],
                            [
                              7.75,
                              50.259
                            ],
                            [
                              0.693,
                              -2.325
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 400,
                      "s": [
                        {
                          "i": [
                            [
                              4.142,
                              24.089
                            ],
                            [
                              0.058,
                              -14.504
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              0.074,
                              24.851
                            ]
                          ],
                          "o": [
                            [
                              9.392,
                              14.275
                            ],
                            [
                              -0.084,
                              21.217
                            ],
                            [
                              3.017,
                              -12.592
                            ],
                            [
                              -0.067,
                              -22.469
                            ]
                          ],
                          "v": [
                            [
                              0.125,
                              -50.455
                            ],
                            [
                              15.226,
                              -4.579
                            ],
                            [
                              -2.375,
                              50.285
                            ],
                            [
                              5.443,
                              -2.825
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 425,
                      "s": [
                        {
                          "i": [
                            [
                              -1.733,
                              21.87
                            ],
                            [
                              6.038,
                              -13.188
                            ],
                            [
                              3.642,
                              -24.075
                            ],
                            [
                              -10.676,
                              23.659
                            ]
                          ],
                          "o": [
                            [
                              6.892,
                              11.521
                            ],
                            [
                              -9.959,
                              21.753
                            ],
                            [
                              -1.233,
                              -12.298
                            ],
                            [
                              9.242,
                              -20.48
                            ]
                          ],
                          "v": [
                            [
                              8,
                              -50.377
                            ],
                            [
                              8.976,
                              2.023
                            ],
                            [
                              -9.375,
                              50.349
                            ],
                            [
                              -0.557,
                              -0.776
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 450,
                      "s": [
                        {
                          "i": [
                            [
                              -4.358,
                              27.457
                            ],
                            [
                              3.084,
                              -14.172
                            ],
                            [
                              0.142,
                              -13.988
                            ],
                            [
                              -5.824,
                              20.299
                            ]
                          ],
                          "o": [
                            [
                              -0.608,
                              33.702
                            ],
                            [
                              -3.084,
                              14.172
                            ],
                            [
                              -5.483,
                              -11.668
                            ],
                            [
                              5.824,
                              -20.299
                            ]
                          ],
                          "v": [
                            [
                              9.5,
                              -50.432
                            ],
                            [
                              -2.024,
                              -0.568
                            ],
                            [
                              -7.25,
                              50.254
                            ],
                            [
                              -8.057,
                              -7.316
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 475,
                      "s": [
                        {
                          "i": [
                            [
                              -8.858,
                              16.284
                            ],
                            [
                              -0.358,
                              -14.5
                            ],
                            [
                              -6.108,
                              -29.532
                            ],
                            [
                              -2.426,
                              24.846
                            ]
                          ],
                          "o": [
                            [
                              -1.733,
                              11.287
                            ],
                            [
                              0.416,
                              16.849
                            ],
                            [
                              -7.983,
                              -13.472
                            ],
                            [
                              2.052,
                              -21.018
                            ]
                          ],
                          "v": [
                            [
                              1,
                              -50.501
                            ],
                            [
                              -5.149,
                              -3.958
                            ],
                            [
                              2,
                              50.274
                            ],
                            [
                              -12.557,
                              -11.777
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 500,
                      "s": [
                        {
                          "i": [
                            [
                              -5.983,
                              15.021
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              1.017,
                              -22.085
                            ],
                            [
                              6.449,
                              27.904
                            ]
                          ],
                          "o": [
                            [
                              -0.483,
                              13.594
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -7.483,
                              -27.617
                            ],
                            [
                              -4.755,
                              -20.575
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              -0.274,
                              -1.706
                            ],
                            [
                              10.5,
                              50.321
                            ],
                            [
                              -9.807,
                              -5.256
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 525,
                      "s": [
                        {
                          "i": [
                            [
                              -2.108,
                              25.549
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              9.449,
                              26.278
                            ]
                          ],
                          "o": [
                            [
                              4.642,
                              27.333
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -0.983,
                              -18.097
                            ],
                            [
                              -7.602,
                              -21.144
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              9.726,
                              -7.332
                            ],
                            [
                              7.75,
                              50.259
                            ],
                            [
                              0.693,
                              -2.325
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 550,
                      "s": [
                        {
                          "i": [
                            [
                              4.142,
                              24.089
                            ],
                            [
                              0.058,
                              -14.504
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              0.074,
                              24.851
                            ]
                          ],
                          "o": [
                            [
                              9.392,
                              14.275
                            ],
                            [
                              -0.084,
                              21.217
                            ],
                            [
                              3.017,
                              -12.592
                            ],
                            [
                              -0.067,
                              -22.469
                            ]
                          ],
                          "v": [
                            [
                              0.125,
                              -50.455
                            ],
                            [
                              15.226,
                              -4.579
                            ],
                            [
                              -2.375,
                              50.285
                            ],
                            [
                              5.443,
                              -2.825
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 575,
                      "s": [
                        {
                          "i": [
                            [
                              -1.733,
                              21.87
                            ],
                            [
                              6.038,
                              -13.188
                            ],
                            [
                              3.642,
                              -24.075
                            ],
                            [
                              -10.676,
                              23.659
                            ]
                          ],
                          "o": [
                            [
                              6.892,
                              11.521
                            ],
                            [
                              -9.959,
                              21.753
                            ],
                            [
                              -1.233,
                              -12.298
                            ],
                            [
                              9.242,
                              -20.48
                            ]
                          ],
                          "v": [
                            [
                              8,
                              -50.377
                            ],
                            [
                              8.976,
                              2.023
                            ],
                            [
                              -9.375,
                              50.349
                            ],
                            [
                              -0.557,
                              -0.776
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "t": 600,
                      "s": [
                        {
                          "i": [
                            [
                              -4.358,
                              27.457
                            ],
                            [
                              3.084,
                              -14.172
                            ],
                            [
                              0.142,
                              -13.988
                            ],
                            [
                              -5.824,
                              20.299
                            ]
                          ],
                          "o": [
                            [
                              -0.608,
                              33.702
                            ],
                            [
                              -3.084,
                              14.172
                            ],
                            [
                              -5.483,
                              -11.668
                            ],
                            [
                              5.824,
                              -20.299
                            ]
                          ],
                          "v": [
                            [
                              9.5,
                              -50.432
                            ],
                            [
                              -2.024,
                              -0.568
                            ],
                            [
                              -7.25,
                              50.254
                            ],
                            [
                              -8.057,
                              -7.316
                            ]
                          ],
                          "c": true
                        }
                      ]
                    }
                  ],
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    1,
                    1,
                    1,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 70,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    -6.086,
                    35.2
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    70.053
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 3",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 2,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 1,
                  "k": [
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 0,
                      "s": [
                        {
                          "i": [
                            [
                              -4.358,
                              27.457
                            ],
                            [
                              3.084,
                              -14.172
                            ],
                            [
                              0.142,
                              -13.988
                            ],
                            [
                              -5.824,
                              20.299
                            ]
                          ],
                          "o": [
                            [
                              -0.608,
                              33.702
                            ],
                            [
                              -3.084,
                              14.172
                            ],
                            [
                              -5.483,
                              -11.668
                            ],
                            [
                              5.824,
                              -20.299
                            ]
                          ],
                          "v": [
                            [
                              9.5,
                              -50.432
                            ],
                            [
                              -2.024,
                              -0.568
                            ],
                            [
                              -7.25,
                              50.254
                            ],
                            [
                              -8.057,
                              -7.316
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 25,
                      "s": [
                        {
                          "i": [
                            [
                              -8.858,
                              16.284
                            ],
                            [
                              -0.358,
                              -14.5
                            ],
                            [
                              -6.108,
                              -29.532
                            ],
                            [
                              -2.426,
                              24.846
                            ]
                          ],
                          "o": [
                            [
                              -1.733,
                              11.287
                            ],
                            [
                              0.416,
                              16.849
                            ],
                            [
                              -7.983,
                              -13.472
                            ],
                            [
                              2.052,
                              -21.018
                            ]
                          ],
                          "v": [
                            [
                              1,
                              -50.501
                            ],
                            [
                              -5.149,
                              -3.958
                            ],
                            [
                              2,
                              50.274
                            ],
                            [
                              -12.557,
                              -11.777
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 50,
                      "s": [
                        {
                          "i": [
                            [
                              -5.983,
                              15.021
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              1.017,
                              -22.085
                            ],
                            [
                              6.449,
                              27.904
                            ]
                          ],
                          "o": [
                            [
                              -0.483,
                              13.594
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -7.483,
                              -27.617
                            ],
                            [
                              -4.755,
                              -20.575
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              -0.274,
                              -1.706
                            ],
                            [
                              10.5,
                              50.321
                            ],
                            [
                              -9.807,
                              -5.256
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 75,
                      "s": [
                        {
                          "i": [
                            [
                              -2.108,
                              25.549
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              9.449,
                              26.278
                            ]
                          ],
                          "o": [
                            [
                              4.642,
                              27.333
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -0.983,
                              -18.097
                            ],
                            [
                              -7.602,
                              -21.144
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              9.726,
                              -7.332
                            ],
                            [
                              7.75,
                              50.259
                            ],
                            [
                              0.693,
                              -2.325
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 100,
                      "s": [
                        {
                          "i": [
                            [
                              4.142,
                              24.089
                            ],
                            [
                              0.058,
                              -14.504
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              0.074,
                              24.851
                            ]
                          ],
                          "o": [
                            [
                              9.392,
                              14.275
                            ],
                            [
                              -0.084,
                              21.217
                            ],
                            [
                              3.017,
                              -12.592
                            ],
                            [
                              -0.067,
                              -22.469
                            ]
                          ],
                          "v": [
                            [
                              0.125,
                              -50.455
                            ],
                            [
                              15.226,
                              -4.579
                            ],
                            [
                              -2.375,
                              50.285
                            ],
                            [
                              5.443,
                              -2.825
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 125,
                      "s": [
                        {
                          "i": [
                            [
                              -1.733,
                              21.87
                            ],
                            [
                              6.038,
                              -13.188
                            ],
                            [
                              3.642,
                              -24.075
                            ],
                            [
                              -10.676,
                              23.659
                            ]
                          ],
                          "o": [
                            [
                              6.892,
                              11.521
                            ],
                            [
                              -9.959,
                              21.753
                            ],
                            [
                              -1.233,
                              -12.298
                            ],
                            [
                              9.242,
                              -20.48
                            ]
                          ],
                          "v": [
                            [
                              8,
                              -50.377
                            ],
                            [
                              8.976,
                              2.023
                            ],
                            [
                              -9.375,
                              50.349
                            ],
                            [
                              -0.557,
                              -0.776
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 150,
                      "s": [
                        {
                          "i": [
                            [
                              -4.358,
                              27.457
                            ],
                            [
                              3.084,
                              -14.172
                            ],
                            [
                              0.142,
                              -13.988
                            ],
                            [
                              -5.824,
                              20.299
                            ]
                          ],
                          "o": [
                            [
                              -0.608,
                              33.702
                            ],
                            [
                              -3.084,
                              14.172
                            ],
                            [
                              -5.483,
                              -11.668
                            ],
                            [
                              5.824,
                              -20.299
                            ]
                          ],
                          "v": [
                            [
                              9.5,
                              -50.432
                            ],
                            [
                              -2.024,
                              -0.568
                            ],
                            [
                              -7.25,
                              50.254
                            ],
                            [
                              -8.057,
                              -7.316
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 175,
                      "s": [
                        {
                          "i": [
                            [
                              -8.858,
                              16.284
                            ],
                            [
                              -0.358,
                              -14.5
                            ],
                            [
                              -6.108,
                              -29.532
                            ],
                            [
                              -2.426,
                              24.846
                            ]
                          ],
                          "o": [
                            [
                              -1.733,
                              11.287
                            ],
                            [
                              0.416,
                              16.849
                            ],
                            [
                              -7.983,
                              -13.472
                            ],
                            [
                              2.052,
                              -21.018
                            ]
                          ],
                          "v": [
                            [
                              1,
                              -50.501
                            ],
                            [
                              -5.149,
                              -3.958
                            ],
                            [
                              2,
                              50.274
                            ],
                            [
                              -12.557,
                              -11.777
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 200,
                      "s": [
                        {
                          "i": [
                            [
                              -5.983,
                              15.021
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              1.017,
                              -22.085
                            ],
                            [
                              6.449,
                              27.904
                            ]
                          ],
                          "o": [
                            [
                              -0.483,
                              13.594
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -7.483,
                              -27.617
                            ],
                            [
                              -4.755,
                              -20.575
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              -0.274,
                              -1.706
                            ],
                            [
                              10.5,
                              50.321
                            ],
                            [
                              -9.807,
                              -5.256
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 225,
                      "s": [
                        {
                          "i": [
                            [
                              -2.108,
                              25.549
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              9.449,
                              26.278
                            ]
                          ],
                          "o": [
                            [
                              4.642,
                              27.333
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -0.983,
                              -18.097
                            ],
                            [
                              -7.602,
                              -21.144
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              9.726,
                              -7.332
                            ],
                            [
                              7.75,
                              50.259
                            ],
                            [
                              0.693,
                              -2.325
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 250,
                      "s": [
                        {
                          "i": [
                            [
                              4.142,
                              24.089
                            ],
                            [
                              0.058,
                              -14.504
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              0.074,
                              24.851
                            ]
                          ],
                          "o": [
                            [
                              9.392,
                              14.275
                            ],
                            [
                              -0.084,
                              21.217
                            ],
                            [
                              3.017,
                              -12.592
                            ],
                            [
                              -0.067,
                              -22.469
                            ]
                          ],
                          "v": [
                            [
                              0.125,
                              -50.455
                            ],
                            [
                              15.226,
                              -4.579
                            ],
                            [
                              -2.375,
                              50.285
                            ],
                            [
                              5.443,
                              -2.825
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 275,
                      "s": [
                        {
                          "i": [
                            [
                              -1.733,
                              21.87
                            ],
                            [
                              6.038,
                              -13.188
                            ],
                            [
                              3.642,
                              -24.075
                            ],
                            [
                              -10.676,
                              23.659
                            ]
                          ],
                          "o": [
                            [
                              6.892,
                              11.521
                            ],
                            [
                              -9.959,
                              21.753
                            ],
                            [
                              -1.233,
                              -12.298
                            ],
                            [
                              9.242,
                              -20.48
                            ]
                          ],
                          "v": [
                            [
                              8,
                              -50.377
                            ],
                            [
                              8.976,
                              2.023
                            ],
                            [
                              -9.375,
                              50.349
                            ],
                            [
                              -0.557,
                              -0.776
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 300,
                      "s": [
                        {
                          "i": [
                            [
                              -4.358,
                              27.457
                            ],
                            [
                              3.084,
                              -14.172
                            ],
                            [
                              0.142,
                              -13.988
                            ],
                            [
                              -5.824,
                              20.299
                            ]
                          ],
                          "o": [
                            [
                              -0.608,
                              33.702
                            ],
                            [
                              -3.084,
                              14.172
                            ],
                            [
                              -5.483,
                              -11.668
                            ],
                            [
                              5.824,
                              -20.299
                            ]
                          ],
                          "v": [
                            [
                              9.5,
                              -50.432
                            ],
                            [
                              -2.024,
                              -0.568
                            ],
                            [
                              -7.25,
                              50.254
                            ],
                            [
                              -8.057,
                              -7.316
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 325,
                      "s": [
                        {
                          "i": [
                            [
                              -8.858,
                              16.284
                            ],
                            [
                              -0.358,
                              -14.5
                            ],
                            [
                              -6.108,
                              -29.532
                            ],
                            [
                              -2.426,
                              24.846
                            ]
                          ],
                          "o": [
                            [
                              -1.733,
                              11.287
                            ],
                            [
                              0.416,
                              16.849
                            ],
                            [
                              -7.983,
                              -13.472
                            ],
                            [
                              2.052,
                              -21.018
                            ]
                          ],
                          "v": [
                            [
                              1,
                              -50.501
                            ],
                            [
                              -5.149,
                              -3.958
                            ],
                            [
                              2,
                              50.274
                            ],
                            [
                              -12.557,
                              -11.777
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 350,
                      "s": [
                        {
                          "i": [
                            [
                              -5.983,
                              15.021
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              1.017,
                              -22.085
                            ],
                            [
                              6.449,
                              27.904
                            ]
                          ],
                          "o": [
                            [
                              -0.483,
                              13.594
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -7.483,
                              -27.617
                            ],
                            [
                              -4.755,
                              -20.575
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              -0.274,
                              -1.706
                            ],
                            [
                              10.5,
                              50.321
                            ],
                            [
                              -9.807,
                              -5.256
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 375,
                      "s": [
                        {
                          "i": [
                            [
                              -2.108,
                              25.549
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              9.449,
                              26.278
                            ]
                          ],
                          "o": [
                            [
                              4.642,
                              27.333
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -0.983,
                              -18.097
                            ],
                            [
                              -7.602,
                              -21.144
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              9.726,
                              -7.332
                            ],
                            [
                              7.75,
                              50.259
                            ],
                            [
                              0.693,
                              -2.325
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 400,
                      "s": [
                        {
                          "i": [
                            [
                              4.142,
                              24.089
                            ],
                            [
                              0.058,
                              -14.504
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              0.074,
                              24.851
                            ]
                          ],
                          "o": [
                            [
                              9.392,
                              14.275
                            ],
                            [
                              -0.084,
                              21.217
                            ],
                            [
                              3.017,
                              -12.592
                            ],
                            [
                              -0.067,
                              -22.469
                            ]
                          ],
                          "v": [
                            [
                              0.125,
                              -50.455
                            ],
                            [
                              15.226,
                              -4.579
                            ],
                            [
                              -2.375,
                              50.285
                            ],
                            [
                              5.443,
                              -2.825
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 425,
                      "s": [
                        {
                          "i": [
                            [
                              -1.733,
                              21.87
                            ],
                            [
                              6.038,
                              -13.188
                            ],
                            [
                              3.642,
                              -24.075
                            ],
                            [
                              -10.676,
                              23.659
                            ]
                          ],
                          "o": [
                            [
                              6.892,
                              11.521
                            ],
                            [
                              -9.959,
                              21.753
                            ],
                            [
                              -1.233,
                              -12.298
                            ],
                            [
                              9.242,
                              -20.48
                            ]
                          ],
                          "v": [
                            [
                              8,
                              -50.377
                            ],
                            [
                              8.976,
                              2.023
                            ],
                            [
                              -9.375,
                              50.349
                            ],
                            [
                              -0.557,
                              -0.776
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 450,
                      "s": [
                        {
                          "i": [
                            [
                              -4.358,
                              27.457
                            ],
                            [
                              3.084,
                              -14.172
                            ],
                            [
                              0.142,
                              -13.988
                            ],
                            [
                              -5.824,
                              20.299
                            ]
                          ],
                          "o": [
                            [
                              -0.608,
                              33.702
                            ],
                            [
                              -3.084,
                              14.172
                            ],
                            [
                              -5.483,
                              -11.668
                            ],
                            [
                              5.824,
                              -20.299
                            ]
                          ],
                          "v": [
                            [
                              9.5,
                              -50.432
                            ],
                            [
                              -2.024,
                              -0.568
                            ],
                            [
                              -7.25,
                              50.254
                            ],
                            [
                              -8.057,
                              -7.316
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 475,
                      "s": [
                        {
                          "i": [
                            [
                              -8.858,
                              16.284
                            ],
                            [
                              -0.358,
                              -14.5
                            ],
                            [
                              -6.108,
                              -29.532
                            ],
                            [
                              -2.426,
                              24.846
                            ]
                          ],
                          "o": [
                            [
                              -1.733,
                              11.287
                            ],
                            [
                              0.416,
                              16.849
                            ],
                            [
                              -7.983,
                              -13.472
                            ],
                            [
                              2.052,
                              -21.018
                            ]
                          ],
                          "v": [
                            [
                              1,
                              -50.501
                            ],
                            [
                              -5.149,
                              -3.958
                            ],
                            [
                              2,
                              50.274
                            ],
                            [
                              -12.557,
                              -11.777
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 500,
                      "s": [
                        {
                          "i": [
                            [
                              -5.983,
                              15.021
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              1.017,
                              -22.085
                            ],
                            [
                              6.449,
                              27.904
                            ]
                          ],
                          "o": [
                            [
                              -0.483,
                              13.594
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -7.483,
                              -27.617
                            ],
                            [
                              -4.755,
                              -20.575
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              -0.274,
                              -1.706
                            ],
                            [
                              10.5,
                              50.321
                            ],
                            [
                              -9.807,
                              -5.256
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 525,
                      "s": [
                        {
                          "i": [
                            [
                              -2.108,
                              25.549
                            ],
                            [
                              -4.61,
                              -13.752
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              9.449,
                              26.278
                            ]
                          ],
                          "o": [
                            [
                              4.642,
                              27.333
                            ],
                            [
                              6.041,
                              18.02
                            ],
                            [
                              -0.983,
                              -18.097
                            ],
                            [
                              -7.602,
                              -21.144
                            ]
                          ],
                          "v": [
                            [
                              -8.25,
                              -50.487
                            ],
                            [
                              9.726,
                              -7.332
                            ],
                            [
                              7.75,
                              50.259
                            ],
                            [
                              0.693,
                              -2.325
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 550,
                      "s": [
                        {
                          "i": [
                            [
                              4.142,
                              24.089
                            ],
                            [
                              0.058,
                              -14.504
                            ],
                            [
                              5.642,
                              -9.71
                            ],
                            [
                              0.074,
                              24.851
                            ]
                          ],
                          "o": [
                            [
                              9.392,
                              14.275
                            ],
                            [
                              -0.084,
                              21.217
                            ],
                            [
                              3.017,
                              -12.592
                            ],
                            [
                              -0.067,
                              -22.469
                            ]
                          ],
                          "v": [
                            [
                              0.125,
                              -50.455
                            ],
                            [
                              15.226,
                              -4.579
                            ],
                            [
                              -2.375,
                              50.285
                            ],
                            [
                              5.443,
                              -2.825
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 575,
                      "s": [
                        {
                          "i": [
                            [
                              -1.733,
                              21.87
                            ],
                            [
                              6.038,
                              -13.188
                            ],
                            [
                              3.642,
                              -24.075
                            ],
                            [
                              -10.676,
                              23.659
                            ]
                          ],
                          "o": [
                            [
                              6.892,
                              11.521
                            ],
                            [
                              -9.959,
                              21.753
                            ],
                            [
                              -1.233,
                              -12.298
                            ],
                            [
                              9.242,
                              -20.48
                            ]
                          ],
                          "v": [
                            [
                              8,
                              -50.377
                            ],
                            [
                              8.976,
                              2.023
                            ],
                            [
                              -9.375,
                              50.349
                            ],
                            [
                              -0.557,
                              -0.776
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "t": 600,
                      "s": [
                        {
                          "i": [
                            [
                              -4.358,
                              27.457
                            ],
                            [
                              3.084,
                              -14.172
                            ],
                            [
                              0.142,
                              -13.988
                            ],
                            [
                              -5.824,
                              20.299
                            ]
                          ],
                          "o": [
                            [
                              -0.608,
                              33.702
                            ],
                            [
                              -3.084,
                              14.172
                            ],
                            [
                              -5.483,
                              -11.668
                            ],
                            [
                              5.824,
                              -20.299
                            ]
                          ],
                          "v": [
                            [
                              9.5,
                              -50.432
                            ],
                            [
                              -2.024,
                              -0.568
                            ],
                            [
                              -7.25,
                              50.254
                            ],
                            [
                              -8.057,
                              -7.316
                            ]
                          ],
                          "c": true
                        }
                      ]
                    }
                  ],
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    1,
                    1,
                    1,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 70,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    33.23,
                    44.71
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    70.053
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "Grupo 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 3,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 0,
        "op": 600,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 18,
        "ty": 4,
        "nm": "cafe",
        "sr": 1,
        "ks": {
          "o": {
            "a": 0,
            "k": 100,
            "ix": 11
          },
          "r": {
            "a": 0,
            "k": 0,
            "ix": 10
          },
          "p": {
            "a": 0,
            "k": [
              1056.173,
              820.2090000000001,
              0
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              44.5,
              67,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 0,
            "k": [
              100,
              100,
              100
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        1.661,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        1.661
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        -1.662,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        1.661
                      ]
                    ],
                    "v": [
                      [
                        8.531,
                        3.037
                      ],
                      [
                        -8.531,
                        3.037
                      ],
                      [
                        -11.539,
                        0.029
                      ],
                      [
                        -11.539,
                        -3.037
                      ],
                      [
                        11.539,
                        -3.037
                      ],
                      [
                        11.539,
                        0.029
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.905999995213,
                    0.8,
                    0.677999997606,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    45.109,
                    3.288
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "tapita_tres",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.592,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.489,
                        -1.514
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.489,
                        -1.514
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.592,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        38.042,
                        6.883
                      ],
                      [
                        -38.042,
                        6.883
                      ],
                      [
                        -34.416,
                        -4.342
                      ],
                      [
                        -30.925,
                        -6.883
                      ],
                      [
                        30.924,
                        -6.883
                      ],
                      [
                        34.415,
                        -4.342
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.972999961703,
                    0.933000033509,
                    0.882000014361,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    44.335,
                    7.133
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "tapita_dos",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 2,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        1.164,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        1.164
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.164,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -1.164
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        -1.164,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -1.164
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.164,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        1.164
                      ]
                    ],
                    "v": [
                      [
                        41.977,
                        7.948
                      ],
                      [
                        -41.975,
                        7.948
                      ],
                      [
                        -44.084,
                        5.84
                      ],
                      [
                        -44.084,
                        -5.84
                      ],
                      [
                        -41.975,
                        -7.948
                      ],
                      [
                        41.977,
                        -7.948
                      ],
                      [
                        44.084,
                        -5.84
                      ],
                      [
                        44.084,
                        5.84
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    1,
                    1,
                    1,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    44.334,
                    21.964
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "tapita_uno",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 3,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ty": "gr",
                "it": [
                  {
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                      "a": 0,
                      "k": {
                        "i": [
                          [
                            0.973,
                            -0.34
                          ],
                          [
                            1.428,
                            -1.429
                          ],
                          [
                            0.403,
                            -1.155
                          ],
                          [
                            1.885,
                            -1.885
                          ],
                          [
                            1.294,
                            -0.452
                          ],
                          [
                            0.909,
                            -0.682
                          ],
                          [
                            -2.351,
                            0
                          ],
                          [
                            -0.511,
                            0.068
                          ],
                          [
                            -2.342,
                            2.342
                          ],
                          [
                            -0.391,
                            2.959
                          ],
                          [
                            0.883,
                            1.675
                          ]
                        ],
                        "o": [
                          [
                            -1.154,
                            0.402
                          ],
                          [
                            -1.429,
                            1.428
                          ],
                          [
                            -0.452,
                            1.295
                          ],
                          [
                            -1.886,
                            1.886
                          ],
                          [
                            -0.846,
                            0.295
                          ],
                          [
                            1.595,
                            1.457
                          ],
                          [
                            0.492,
                            0
                          ],
                          [
                            2.958,
                            -0.392
                          ],
                          [
                            2.341,
                            -2.342
                          ],
                          [
                            0.286,
                            -2.161
                          ],
                          [
                            -1.271,
                            0.963
                          ]
                        ],
                        "v": [
                          [
                            5.717,
                            -7.524
                          ],
                          [
                            2.137,
                            -5.345
                          ],
                          [
                            -0.042,
                            -1.765
                          ],
                          [
                            -2.891,
                            2.882
                          ],
                          [
                            -7.538,
                            5.732
                          ],
                          [
                            -10.055,
                            6.975
                          ],
                          [
                            -4.01,
                            9.2
                          ],
                          [
                            -2.505,
                            9.098
                          ],
                          [
                            5.714,
                            4.86
                          ],
                          [
                            9.953,
                            -3.359
                          ],
                          [
                            9.03,
                            -9.2
                          ]
                        ],
                        "c": true
                      },
                      "ix": 2
                    },
                    "nm": "Trazado 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                  },
                  {
                    "ty": "fl",
                    "c": {
                      "a": 0,
                      "k": [
                        0.894000004787,
                        0.922000002394,
                        0.969000004787,
                        1
                      ],
                      "ix": 4
                    },
                    "o": {
                      "a": 0,
                      "k": 100,
                      "ix": 5
                    },
                    "r": 1,
                    "bm": 0,
                    "nm": "Relleno 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                  },
                  {
                    "ty": "tr",
                    "p": {
                      "a": 0,
                      "k": [
                        45.427,
                        82.618
                      ],
                      "ix": 2
                    },
                    "a": {
                      "a": 0,
                      "k": [
                        0,
                        0
                      ],
                      "ix": 1
                    },
                    "s": {
                      "a": 0,
                      "k": [
                        100,
                        100
                      ],
                      "ix": 3
                    },
                    "r": {
                      "a": 0,
                      "k": 0,
                      "ix": 6
                    },
                    "o": {
                      "a": 0,
                      "k": 100,
                      "ix": 7
                    },
                    "sk": {
                      "a": 0,
                      "k": 0,
                      "ix": 4
                    },
                    "sa": {
                      "a": 0,
                      "k": 0,
                      "ix": 5
                    },
                    "nm": "Transformar"
                  }
                ],
                "nm": "Grupo 2",
                "np": 2,
                "cix": 2,
                "bm": 0,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
              },
              {
                "ty": "gr",
                "it": [
                  {
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                      "a": 0,
                      "k": {
                        "i": [
                          [
                            -1.428,
                            1.428
                          ],
                          [
                            -0.404,
                            1.154
                          ],
                          [
                            -1.884,
                            1.886
                          ],
                          [
                            -1.294,
                            0.452
                          ],
                          [
                            -0.908,
                            0.681
                          ],
                          [
                            2.957,
                            -0.392
                          ],
                          [
                            2.342,
                            -2.342
                          ],
                          [
                            0.391,
                            -2.959
                          ],
                          [
                            -0.882,
                            -1.675
                          ],
                          [
                            -0.973,
                            0.339
                          ]
                        ],
                        "o": [
                          [
                            1.429,
                            -1.429
                          ],
                          [
                            0.451,
                            -1.295
                          ],
                          [
                            1.886,
                            -1.885
                          ],
                          [
                            0.845,
                            -0.295
                          ],
                          [
                            -1.927,
                            -1.762
                          ],
                          [
                            -2.958,
                            0.391
                          ],
                          [
                            -2.342,
                            2.342
                          ],
                          [
                            -0.287,
                            2.161
                          ],
                          [
                            1.272,
                            -0.962
                          ],
                          [
                            1.154,
                            -0.404
                          ]
                        ],
                        "v": [
                          [
                            -2.138,
                            5.345
                          ],
                          [
                            0.042,
                            1.765
                          ],
                          [
                            2.89,
                            -2.883
                          ],
                          [
                            7.538,
                            -5.732
                          ],
                          [
                            10.053,
                            -6.975
                          ],
                          [
                            2.504,
                            -9.098
                          ],
                          [
                            -5.715,
                            -4.86
                          ],
                          [
                            -9.953,
                            3.359
                          ],
                          [
                            -9.031,
                            9.199
                          ],
                          [
                            -5.717,
                            7.524
                          ]
                        ],
                        "c": true
                      },
                      "ix": 2
                    },
                    "nm": "Trazado 1",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                  },
                  {
                    "ty": "fl",
                    "c": {
                      "a": 0,
                      "k": [
                        0.894000004787,
                        0.922000002394,
                        0.969000004787,
                        1
                      ],
                      "ix": 4
                    },
                    "o": {
                      "a": 0,
                      "k": 100,
                      "ix": 5
                    },
                    "r": 1,
                    "bm": 0,
                    "nm": "Relleno 1",
                    "mn": "ADBE Vector Graphic - Fill",
                    "hd": false
                  },
                  {
                    "ty": "tr",
                    "p": {
                      "a": 0,
                      "k": [
                        42.675,
                        78.156
                      ],
                      "ix": 2
                    },
                    "a": {
                      "a": 0,
                      "k": [
                        0,
                        0
                      ],
                      "ix": 1
                    },
                    "s": {
                      "a": 0,
                      "k": [
                        100,
                        100
                      ],
                      "ix": 3
                    },
                    "r": {
                      "a": 0,
                      "k": 0,
                      "ix": 6
                    },
                    "o": {
                      "a": 0,
                      "k": 100,
                      "ix": 7
                    },
                    "sk": {
                      "a": 0,
                      "k": 0,
                      "ix": 4
                    },
                    "sa": {
                      "a": 0,
                      "k": 0,
                      "ix": 5
                    },
                    "nm": "Transformar"
                  }
                ],
                "nm": "Grupo 3",
                "np": 2,
                "cix": 2,
                "bm": 0,
                "ix": 2,
                "mn": "ADBE Vector Group",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    44.051,
                    80.388
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    44.051,
                    80.388
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 66,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "cafecito",
            "np": 2,
            "cix": 2,
            "bm": 3,
            "ix": 4,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        -11.522
                      ],
                      [
                        -11.501,
                        0
                      ],
                      [
                        0,
                        11.522
                      ],
                      [
                        11.502,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        11.522
                      ],
                      [
                        11.502,
                        0
                      ],
                      [
                        0,
                        -11.522
                      ],
                      [
                        -11.501,
                        0
                      ]
                    ],
                    "v": [
                      [
                        -64.875,
                        -80.387
                      ],
                      [
                        -44.05,
                        -59.525
                      ],
                      [
                        -23.225,
                        -80.387
                      ],
                      [
                        -44.05,
                        -101.249
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.008,
                      0.776,
                      0.612,
                      0.427,
                      0.411,
                      0.663,
                      0.498,
                      0.325,
                      0.999,
                      0.549,
                      0.384,
                      0.224
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    -62.166,
                    -72.461
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    -23.803,
                    -92.279
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    44.05,
                    80.387
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    -44,
                    -80
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "vaso_tres",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 5,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        355.099,
                        301.759
                      ],
                      [
                        359.859,
                        361.619
                      ],
                      [
                        423.303,
                        361.619
                      ],
                      [
                        428.063,
                        301.775
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.002,
                      0.2,
                      0.784,
                      0.557,
                      0.407,
                      0.129,
                      0.682,
                      0.514,
                      0.999,
                      0.059,
                      0.58,
                      0.471
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    391.541,
                    300.432
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    392.07,
                    361.389
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    40.414,
                    76.055
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    387,
                    328
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "vaso_dos",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 6,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        -5.925,
                        -132.894
                      ],
                      [
                        -14.597,
                        -23.861
                      ],
                      [
                        -74.583,
                        -23.861
                      ],
                      [
                        -83.254,
                        -132.894
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.002,
                      0.945,
                      0.859,
                      0.757,
                      0.407,
                      0.925,
                      0.804,
                      0.718,
                      1,
                      0.906,
                      0.749,
                      0.678
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    -44.228,
                    -136.108
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    -44.321,
                    -19.719
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    89.581,
                    155.114
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "vaso_uno",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 7,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 0,
        "op": 600,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 19,
        "ty": 4,
        "nm": "lampara",
        "sr": 1,
        "ks": {
          "o": {
            "a": 0,
            "k": 100,
            "ix": 11
          },
          "r": {
            "a": 0,
            "k": 0,
            "ix": 10
          },
          "p": {
            "a": 0,
            "k": [
              225.99999999999997,
              695,
              0
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              93.5,
              186.5,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 0,
            "k": [
              100,
              100,
              100
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -3.77,
                        -19.298
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -19.663,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        3.77,
                        -19.298
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        19.662,
                        0
                      ]
                    ],
                    "v": [
                      [
                        52.88,
                        16.611
                      ],
                      [
                        -52.88,
                        16.611
                      ],
                      [
                        -12.538,
                        -16.611
                      ],
                      [
                        12.538,
                        -16.611
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.002,
                      0.4,
                      0.4,
                      0.4,
                      0.407,
                      0.255,
                      0.255,
                      0.255,
                      1,
                      0.11,
                      0.11,
                      0.11
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    -38.748,
                    23.314
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    26.447,
                    -19.375
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    113.129,
                    356.071
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "base_lampara",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        2.698,
                        -4.897
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.352,
                        3.511
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -6.337,
                        11.501
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -12.431,
                        -4.232
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        -2.698,
                        4.898
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -10.219,
                        -8.245
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        6.337,
                        -11.501
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        5.293,
                        1.802
                      ]
                    ],
                    "v": [
                      [
                        49.54,
                        -25.048
                      ],
                      [
                        8.2,
                        49.987
                      ],
                      [
                        -5.316,
                        52.645
                      ],
                      [
                        -39.188,
                        25.316
                      ],
                      [
                        -45.9,
                        -8.82
                      ],
                      [
                        -29.076,
                        -39.359
                      ],
                      [
                        3.367,
                        -51.924
                      ],
                      [
                        44.564,
                        -37.893
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0,
                    0.630999995213,
                    0.933000033509,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    134.451,
                    58.277
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "lampara_uno",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 2,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        26.664,
                        -21.846
                      ],
                      [
                        -2.97,
                        31.966
                      ],
                      [
                        -26.664,
                        13.459
                      ],
                      [
                        -1.621,
                        -31.964
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0,
                    0,
                    0.779999976065,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 20,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    99.516,
                    37.058
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "lampara_dos",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 3,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.594,
                        4.704
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -5.054,
                        -1.81
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -4.23,
                        -3.309
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.592,
                        -4.701
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        32.927,
                        -19.425
                      ],
                      [
                        3.293,
                        34.387
                      ],
                      [
                        -20.401,
                        15.88
                      ],
                      [
                        -27.493,
                        10.333
                      ],
                      [
                        -30.333,
                        -3.57
                      ],
                      [
                        -17.103,
                        -27.56
                      ],
                      [
                        -3.835,
                        -32.577
                      ],
                      [
                        4.642,
                        -29.543
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0,
                    0.630999995213,
                    0.933000033509,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    93.253,
                    34.637
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "lampara_tres",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 4,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -2.628,
                        -4.036
                      ],
                      [
                        -4.036,
                        2.628
                      ],
                      [
                        2.629,
                        4.037
                      ],
                      [
                        4.036,
                        -2.628
                      ]
                    ],
                    "o": [
                      [
                        2.628,
                        4.036
                      ],
                      [
                        4.037,
                        -2.629
                      ],
                      [
                        -2.628,
                        -4.036
                      ],
                      [
                        -4.036,
                        2.629
                      ]
                    ],
                    "v": [
                      [
                        -7.308,
                        4.758
                      ],
                      [
                        4.758,
                        7.309
                      ],
                      [
                        7.308,
                        -4.759
                      ],
                      [
                        -4.76,
                        -7.309
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.180000005984,
                    0.19199999641,
                    0.573000021542,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    70.134,
                    22.219
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "bolita_uno",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 5,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -2.628,
                        -4.036
                      ],
                      [
                        -4.036,
                        2.628
                      ],
                      [
                        2.628,
                        4.037
                      ],
                      [
                        4.037,
                        -2.629
                      ]
                    ],
                    "o": [
                      [
                        2.628,
                        4.037
                      ],
                      [
                        4.037,
                        -2.628
                      ],
                      [
                        -2.629,
                        -4.036
                      ],
                      [
                        -4.036,
                        2.628
                      ]
                    ],
                    "v": [
                      [
                        -7.309,
                        4.758
                      ],
                      [
                        4.758,
                        7.309
                      ],
                      [
                        7.308,
                        -4.759
                      ],
                      [
                        -4.76,
                        -7.307
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.180000005984,
                    0.19199999641,
                    0.573000021542,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    10.187,
                    135.406
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "bolita_dos",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 6,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        55.081,
                        164.17
                      ],
                      [
                        49.503,
                        166.844
                      ],
                      [
                        -55.081,
                        -51.609
                      ],
                      [
                        6.631,
                        -166.844
                      ],
                      [
                        12.081,
                        -163.925
                      ],
                      [
                        -48.15,
                        -51.452
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.149000010771,
                    0.195999998205,
                    0.219999994016,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    61.801,
                    186.938
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "palito",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 7,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 0,
        "op": 600,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 20,
        "ty": 4,
        "nm": "matica",
        "sr": 1,
        "ks": {
          "o": {
            "a": 0,
            "k": 100,
            "ix": 11
          },
          "r": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.59
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.21
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 0,
                "s": [
                  -18.684
                ]
              },
              {
                "t": 300,
                "s": [
                  -0.683
                ]
              }
            ],
            "ix": 10,
            "x": "var $bm_rt;\n$bm_rt = loopOut('pingpong', 0);"
          },
          "p": {
            "a": 0,
            "k": [
              271.687,
              882.582,
              0
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              175.669,
              327.618,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 0,
            "k": [
              100,
              100,
              100
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        1.424,
                        -0.31
                      ],
                      [
                        -10.068,
                        -76.74
                      ],
                      [
                        2.883,
                        14.242
                      ],
                      [
                        18.534,
                        14.864
                      ],
                      [
                        0.635,
                        -0.985
                      ],
                      [
                        -19.077,
                        -94.215
                      ],
                      [
                        -13.095,
                        -14.818
                      ],
                      [
                        -0.916,
                        0.845
                      ],
                      [
                        5.607,
                        9.212
                      ],
                      [
                        7.265,
                        19.415
                      ],
                      [
                        -10.21,
                        22.113
                      ]
                    ],
                    "o": [
                      [
                        -10.334,
                        22.953
                      ],
                      [
                        -3.356,
                        -11.557
                      ],
                      [
                        -19.326,
                        -95.433
                      ],
                      [
                        -0.78,
                        0.916
                      ],
                      [
                        18.32,
                        14.699
                      ],
                      [
                        16.06,
                        79.305
                      ],
                      [
                        0.906,
                        -0.74
                      ],
                      [
                        -3.887,
                        -4.431
                      ],
                      [
                        -6.092,
                        -10.009
                      ],
                      [
                        -23.471,
                        -87.693
                      ],
                      [
                        -1.379,
                        0.131
                      ]
                    ],
                    "v": [
                      [
                        70.033,
                        -112.691
                      ],
                      [
                        40.463,
                        60.592
                      ],
                      [
                        31.025,
                        21.95
                      ],
                      [
                        -82.59,
                        -154.097
                      ],
                      [
                        -84.731,
                        -151.256
                      ],
                      [
                        27.536,
                        22.657
                      ],
                      [
                        81.999,
                        154.097
                      ],
                      [
                        84.732,
                        151.717
                      ],
                      [
                        70.18,
                        131.537
                      ],
                      [
                        49.458,
                        87.837
                      ],
                      [
                        74.244,
                        -113.358
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.411999990426,
                    0,
                    0.349000010771,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    107.027,
                    166.724
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 34,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "line",
            "np": 2,
            "cix": 2,
            "bm": 3,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.415,
                        -4.785
                      ],
                      [
                        -29.418,
                        -25.692
                      ],
                      [
                        5.627,
                        -88.364
                      ],
                      [
                        -16.305,
                        1.791
                      ],
                      [
                        7.131,
                        59.59
                      ],
                      [
                        -19.278,
                        64.821
                      ],
                      [
                        18.992,
                        -21.499
                      ],
                      [
                        15.945,
                        30.278
                      ],
                      [
                        3.161,
                        5.667
                      ],
                      [
                        1.513,
                        -2.215
                      ],
                      [
                        1.582,
                        -0.539
                      ],
                      [
                        2.163,
                        1.478
                      ],
                      [
                        -2.494,
                        3.651
                      ],
                      [
                        -1.01,
                        5.066
                      ],
                      [
                        -0.187,
                        0.906
                      ],
                      [
                        13.138,
                        0.001
                      ]
                    ],
                    "o": [
                      [
                        -30.817,
                        19.888
                      ],
                      [
                        29.419,
                        25.693
                      ],
                      [
                        -5.626,
                        88.363
                      ],
                      [
                        16.303,
                        -1.792
                      ],
                      [
                        -7.131,
                        -59.59
                      ],
                      [
                        19.278,
                        -64.821
                      ],
                      [
                        -18.992,
                        21.499
                      ],
                      [
                        -5.144,
                        -9.768
                      ],
                      [
                        -0.964,
                        2.942
                      ],
                      [
                        -1.015,
                        1.486
                      ],
                      [
                        -2.304,
                        0.784
                      ],
                      [
                        -3.652,
                        -2.494
                      ],
                      [
                        1.878,
                        -2.751
                      ],
                      [
                        0.201,
                        -1.011
                      ],
                      [
                        -4.189,
                        -5.297
                      ],
                      [
                        -5.876,
                        -0.001
                      ]
                    ],
                    "v": [
                      [
                        -196.896,
                        -322.311
                      ],
                      [
                        -169.844,
                        -226.279
                      ],
                      [
                        -133.546,
                        -92.048
                      ],
                      [
                        -53.638,
                        -0.853
                      ],
                      [
                        -8.131,
                        -99.035
                      ],
                      [
                        -29.773,
                        -228.858
                      ],
                      [
                        -80.028,
                        -257.793
                      ],
                      [
                        -126.252,
                        -272.126
                      ],
                      [
                        -138.627,
                        -295.04
                      ],
                      [
                        -142.27,
                        -287.158
                      ],
                      [
                        -146.294,
                        -284.096
                      ],
                      [
                        -153.394,
                        -285.061
                      ],
                      [
                        -155.491,
                        -296.188
                      ],
                      [
                        -151.075,
                        -311.663
                      ],
                      [
                        -150.496,
                        -314.529
                      ],
                      [
                        -176.923,
                        -328.912
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.002,
                      0.412,
                      0.337,
                      0.671,
                      0.407,
                      0.527,
                      0.498,
                      0.753,
                      1,
                      0.643,
                      0.659,
                      0.835
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    -49.637,
                    7.324
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    -158.453,
                    -157.219
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    227.884,
                    329.2
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "leaf",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 2,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 0,
        "op": 600,
        "st": 5,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 21,
        "ty": 4,
        "nm": "matica_dos",
        "sr": 1,
        "ks": {
          "o": {
            "a": 0,
            "k": 100,
            "ix": 11
          },
          "r": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.59
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.21
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 0,
                "s": [
                  5.296
                ]
              },
              {
                "t": 300,
                "s": [
                  -3.361
                ]
              }
            ],
            "ix": 10,
            "x": "var $bm_rt;\n$bm_rt = loopOut('pingpong', 0);"
          },
          "p": {
            "a": 0,
            "k": [
              286.213,
              870.279,
              0
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              264.293,
              281.767,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 0,
            "k": [
              100,
              100,
              100
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        6.074,
                        7.124
                      ],
                      [
                        2.598,
                        19.43
                      ],
                      [
                        17.943,
                        23.071
                      ],
                      [
                        0.467,
                        12.991
                      ],
                      [
                        1.292,
                        -0.472
                      ],
                      [
                        -27.134,
                        -34.887
                      ],
                      [
                        -3.143,
                        -23.497
                      ],
                      [
                        -7.406,
                        -17.713
                      ],
                      [
                        60.173,
                        31.475
                      ],
                      [
                        8.249,
                        34.897
                      ],
                      [
                        -0.963,
                        11.588
                      ],
                      [
                        1.236,
                        -0.79
                      ],
                      [
                        -5.977,
                        -25.369
                      ],
                      [
                        -60.329,
                        -31.557
                      ],
                      [
                        -18.339,
                        -20.938
                      ],
                      [
                        -6.306,
                        -7.675
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.01,
                        -0.02
                      ],
                      [
                        -0.845,
                        0.81
                      ]
                    ],
                    "o": [
                      [
                        -3.912,
                        -7.589
                      ],
                      [
                        -11.102,
                        -20.969
                      ],
                      [
                        -3.209,
                        -23.988
                      ],
                      [
                        -26.935,
                        -34.632
                      ],
                      [
                        -1.113,
                        0.24
                      ],
                      [
                        0.574,
                        13.942
                      ],
                      [
                        17.353,
                        22.31
                      ],
                      [
                        2.222,
                        16.617
                      ],
                      [
                        -20.088,
                        -19.705
                      ],
                      [
                        -59.086,
                        -30.905
                      ],
                      [
                        -6.291,
                        -26.609
                      ],
                      [
                        -1.236,
                        0.5
                      ],
                      [
                        -0.865,
                        12.434
                      ],
                      [
                        8.404,
                        35.664
                      ],
                      [
                        68.586,
                        35.876
                      ],
                      [
                        3.909,
                        7.374
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.01,
                        0.02
                      ],
                      [
                        0.898,
                        -0.787
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        117.113,
                        137.713
                      ],
                      [
                        102.477,
                        115.866
                      ],
                      [
                        86.108,
                        55.542
                      ],
                      [
                        61.926,
                        -14.175
                      ],
                      [
                        30.09,
                        -90.991
                      ],
                      [
                        26.492,
                        -89.912
                      ],
                      [
                        59.117,
                        -11.989
                      ],
                      [
                        82.581,
                        56.014
                      ],
                      [
                        94.478,
                        107.314
                      ],
                      [
                        -18.71,
                        35.155
                      ],
                      [
                        -108.281,
                        -78.736
                      ],
                      [
                        -112.963,
                        -140.127
                      ],
                      [
                        -116.675,
                        -138.209
                      ],
                      [
                        -111.777,
                        -78.054
                      ],
                      [
                        -20.361,
                        38.307
                      ],
                      [
                        99.314,
                        117.526
                      ],
                      [
                        114.456,
                        140.086
                      ],
                      [
                        114.482,
                        140.066
                      ],
                      [
                        114.511,
                        140.127
                      ],
                      [
                        117.128,
                        137.734
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Trazado 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "fl",
                "c": {
                  "a": 0,
                  "k": [
                    0.411999990426,
                    0,
                    0.349000010771,
                    1
                  ],
                  "ix": 4
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 5
                },
                "r": 1,
                "bm": 0,
                "nm": "Relleno 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    155.882,
                    141.935
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 34,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "linetwo",
            "np": 2,
            "cix": 2,
            "bm": 3,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          },
          {
            "ty": "gr",
            "it": [
              {
                "ty": "gr",
                "it": [
                  {
                    "ind": 0,
                    "ty": "sh",
                    "ix": 1,
                    "ks": {
                      "a": 0,
                      "k": {
                        "i": [
                          [
                            6.556,
                            -9.178
                          ],
                          [
                            -83.615,
                            -16.012
                          ],
                          [
                            -8.895,
                            -40.918
                          ],
                          [
                            -64.046,
                            40.918
                          ],
                          [
                            32.023,
                            26.685
                          ],
                          [
                            17.79,
                            28.466
                          ],
                          [
                            16.011,
                            -7.116
                          ],
                          [
                            -17.791,
                            -21.348
                          ],
                          [
                            7.117,
                            3.558
                          ],
                          [
                            37.36,
                            1.778
                          ],
                          [
                            3.558,
                            23.128
                          ],
                          [
                            3.453,
                            6.955
                          ],
                          [
                            2.636,
                            0
                          ],
                          [
                            0,
                            6.968
                          ],
                          [
                            -2.387,
                            2.314
                          ],
                          [
                            7.253,
                            0.001
                          ]
                        ],
                        "o": [
                          [
                            -17.79,
                            24.907
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            8.896,
                            40.919
                          ],
                          [
                            64.045,
                            -40.918
                          ],
                          [
                            -32.023,
                            -26.686
                          ],
                          [
                            -17.79,
                            -28.465
                          ],
                          [
                            -16.012,
                            7.117
                          ],
                          [
                            17.79,
                            21.349
                          ],
                          [
                            -7.116,
                            -3.558
                          ],
                          [
                            -37.36,
                            -1.78
                          ],
                          [
                            -1.437,
                            -9.346
                          ],
                          [
                            -1.863,
                            1.87
                          ],
                          [
                            -5.895,
                            0
                          ],
                          [
                            0,
                            -3.932
                          ],
                          [
                            -3.647,
                            -2.513
                          ],
                          [
                            -6.579,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -131.65,
                            -153.188
                          ],
                          [
                            -78.278,
                            10.486
                          ],
                          [
                            -35.581,
                            58.52
                          ],
                          [
                            97.848,
                            124.344
                          ],
                          [
                            96.069,
                            -32.212
                          ],
                          [
                            50.703,
                            -88.253
                          ],
                          [
                            15.122,
                            -111.38
                          ],
                          [
                            32.913,
                            -43.776
                          ],
                          [
                            25.796,
                            -11.753
                          ],
                          [
                            -25.796,
                            -49.113
                          ],
                          [
                            -72.052,
                            -114.938
                          ],
                          [
                            -80.269,
                            -139.855
                          ],
                          [
                            -87.174,
                            -136.853
                          ],
                          [
                            -97.848,
                            -149.469
                          ],
                          [
                            -93.936,
                            -159.227
                          ],
                          [
                            -111.239,
                            -165.263
                          ]
                        ],
                        "c": true
                      },
                      "ix": 2
                    },
                    "nm": "Trazado 2",
                    "mn": "ADBE Vector Shape - Group",
                    "hd": false
                  },
                  {
                    "ty": "tr",
                    "p": {
                      "a": 0,
                      "k": [
                        0,
                        0
                      ],
                      "ix": 2
                    },
                    "a": {
                      "a": 0,
                      "k": [
                        0,
                        0
                      ],
                      "ix": 1
                    },
                    "s": {
                      "a": 0,
                      "k": [
                        100,
                        100
                      ],
                      "ix": 3
                    },
                    "r": {
                      "a": 0,
                      "k": 0,
                      "ix": 6
                    },
                    "o": {
                      "a": 0,
                      "k": 100,
                      "ix": 7
                    },
                    "sk": {
                      "a": 0,
                      "k": 0,
                      "ix": 4
                    },
                    "sa": {
                      "a": 0,
                      "k": 0,
                      "ix": 5
                    },
                    "nm": "Transformar"
                  }
                ],
                "nm": "Grupo 1",
                "np": 1,
                "cix": 2,
                "bm": 0,
                "ix": 1,
                "mn": "ADBE Vector Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.002,
                      0.412,
                      0.337,
                      0.671,
                      0.407,
                      0.527,
                      0.498,
                      0.753,
                      1,
                      0.643,
                      0.659,
                      0.835
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    84.504,
                    113.688
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    -103.77,
                    -119.629
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    162.143,
                    165.513
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "leaftwo",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 2,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 0,
        "op": 600,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 22,
        "ty": 4,
        "nm": "lineas",
        "sr": 1,
        "ks": {
          "o": {
            "a": 0,
            "k": 100,
            "ix": 11
          },
          "r": {
            "a": 0,
            "k": 0,
            "ix": 10
          },
          "p": {
            "a": 0,
            "k": [
              660.173,
              498.037,
              0
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              797.173,
              661.037,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 0,
            "k": [
              100,
              100,
              100
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "hasMask": true,
        "masksProperties": [
          {
            "inv": false,
            "mode": "a",
            "pt": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 0,
                  "s": [
                    {
                      "i": [
                        [
                          21.066,
                          -112.431
                        ],
                        [
                          13.221,
                          -12.263
                        ],
                        [
                          31.488,
                          -0.768
                        ],
                        [
                          0.285,
                          0
                        ],
                        [
                          9.159,
                          67.471
                        ],
                        [
                          -145.609,
                          20.335
                        ],
                        [
                          -60.973,
                          27.949
                        ],
                        [
                          -35.65,
                          40.798
                        ],
                        [
                          -133.894,
                          -8.567
                        ]
                      ],
                      "o": [
                        [
                          -19.2,
                          117.084
                        ],
                        [
                          -1.013,
                          4.374
                        ],
                        [
                          -189.521,
                          -0.001
                        ],
                        [
                          0.028,
                          0.026
                        ],
                        [
                          -20.955,
                          -175.257
                        ],
                        [
                          88.749,
                          -17.083
                        ],
                        [
                          45.664,
                          -21.826
                        ],
                        [
                          89.912,
                          -117.727
                        ],
                        [
                          243.025,
                          20.347
                        ]
                      ],
                      "v": [
                        [
                          1406.648,
                          858.794
                        ],
                        [
                          1317.127,
                          1049.731
                        ],
                        [
                          1274.048,
                          1052.083
                        ],
                        [
                          295.508,
                          1052.082
                        ],
                        [
                          256.205,
                          929.229
                        ],
                        [
                          505.105,
                          689.328
                        ],
                        [
                          720.173,
                          631.591
                        ],
                        [
                          828.022,
                          542.663
                        ],
                        [
                          1163.282,
                          361.817
                        ]
                      ],
                      "c": true
                    }
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 75,
                  "s": [
                    {
                      "i": [
                        [
                          15.415,
                          -117.814
                        ],
                        [
                          13.221,
                          -12.263
                        ],
                        [
                          31.488,
                          -0.768
                        ],
                        [
                          0.285,
                          0
                        ],
                        [
                          9.41,
                          81.515
                        ],
                        [
                          -143.885,
                          23.957
                        ],
                        [
                          -43.293,
                          98.223
                        ],
                        [
                          -91.909,
                          -12.837
                        ],
                        [
                          -107.069,
                          -86.156
                        ]
                      ],
                      "o": [
                        [
                          -19.2,
                          117.084
                        ],
                        [
                          -1.013,
                          4.374
                        ],
                        [
                          -189.521,
                          -0.001
                        ],
                        [
                          0.028,
                          0.026
                        ],
                        [
                          -20.84,
                          -175.101
                        ],
                        [
                          86.818,
                          -19.483
                        ],
                        [
                          29.023,
                          -75.595
                        ],
                        [
                          137.945,
                          31.771
                        ],
                        [
                          157.014,
                          123.366
                        ]
                      ],
                      "v": [
                        [
                          1397.849,
                          866.618
                        ],
                        [
                          1316.627,
                          1049.231
                        ],
                        [
                          1273.548,
                          1051.583
                        ],
                        [
                          297.805,
                          1051.663
                        ],
                        [
                          246.516,
                          910.75
                        ],
                        [
                          444.72,
                          666.037
                        ],
                        [
                          662.122,
                          488.521
                        ],
                        [
                          843.404,
                          367.829
                        ],
                        [
                          1104.355,
                          586.3
                        ]
                      ],
                      "c": true
                    }
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 149,
                  "s": [
                    {
                      "i": [
                        [
                          0.134,
                          -103.948
                        ],
                        [
                          13.221,
                          -12.263
                        ],
                        [
                          31.488,
                          -0.768
                        ],
                        [
                          0.285,
                          0
                        ],
                        [
                          9.681,
                          96.685
                        ],
                        [
                          -117.951,
                          112.883
                        ],
                        [
                          -74.804,
                          65.995
                        ],
                        [
                          -123.407,
                          -128.653
                        ],
                        [
                          -129.63,
                          -49.09
                        ]
                      ],
                      "o": [
                        [
                          -19.2,
                          117.084
                        ],
                        [
                          -1.013,
                          4.374
                        ],
                        [
                          -189.521,
                          -0.001
                        ],
                        [
                          0.028,
                          0.026
                        ],
                        [
                          6.442,
                          -137.919
                        ],
                        [
                          64.297,
                          -58.462
                        ],
                        [
                          72.806,
                          -64.233
                        ],
                        [
                          71.77,
                          60.606
                        ],
                        [
                          154.269,
                          46.493
                        ]
                      ],
                      "v": [
                        [
                          1363.934,
                          906.338
                        ],
                        [
                          1317.319,
                          1048.399
                        ],
                        [
                          1274.24,
                          1050.751
                        ],
                        [
                          301.518,
                          1050.918
                        ],
                        [
                          237.281,
                          890.497
                        ],
                        [
                          467.517,
                          655.38
                        ],
                        [
                          624.976,
                          339.601
                        ],
                        [
                          964.763,
                          361.94
                        ],
                        [
                          1167.239,
                          701.972
                        ]
                      ],
                      "c": true
                    }
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 224,
                  "s": [
                    {
                      "i": [
                        [
                          -3.454,
                          -121.671
                        ],
                        [
                          13.221,
                          -12.263
                        ],
                        [
                          31.488,
                          -0.768
                        ],
                        [
                          0.285,
                          0
                        ],
                        [
                          10.046,
                          117.079
                        ],
                        [
                          -98.926,
                          99.847
                        ],
                        [
                          -75.258,
                          0.225
                        ],
                        [
                          -87.321,
                          -90.315
                        ],
                        [
                          -130.695,
                          -36.474
                        ]
                      ],
                      "o": [
                        [
                          -19.2,
                          117.084
                        ],
                        [
                          -1.013,
                          4.374
                        ],
                        [
                          -189.521,
                          -0.001
                        ],
                        [
                          0.028,
                          0.026
                        ],
                        [
                          -7.06,
                          -156.321
                        ],
                        [
                          59.096,
                          -56.168
                        ],
                        [
                          74.228,
                          -2.949
                        ],
                        [
                          73.991,
                          62.529
                        ],
                        [
                          186.002,
                          43.169
                        ]
                      ],
                      "v": [
                        [
                          1365.109,
                          902.435
                        ],
                        [
                          1317.639,
                          1048.293
                        ],
                        [
                          1274.56,
                          1050.646
                        ],
                        [
                          305.899,
                          1050.93
                        ],
                        [
                          224.257,
                          864.282
                        ],
                        [
                          431.075,
                          492.877
                        ],
                        [
                          649.171,
                          381.549
                        ],
                        [
                          856.792,
                          486.017
                        ],
                        [
                          1120.772,
                          690.036
                        ]
                      ],
                      "c": true
                    }
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 300,
                  "s": [
                    {
                      "i": [
                        [
                          -7.028,
                          -139.794
                        ],
                        [
                          13.221,
                          -12.263
                        ],
                        [
                          31.488,
                          -0.768
                        ],
                        [
                          0.285,
                          0
                        ],
                        [
                          10.415,
                          137.771
                        ],
                        [
                          -137.485,
                          36.672
                        ],
                        [
                          -75.553,
                          -66.591
                        ],
                        [
                          -50.402,
                          -50.663
                        ],
                        [
                          -131.662,
                          -23.817
                        ]
                      ],
                      "o": [
                        [
                          -19.2,
                          117.084
                        ],
                        [
                          -1.013,
                          4.374
                        ],
                        [
                          -189.521,
                          -0.001
                        ],
                        [
                          0.028,
                          0.026
                        ],
                        [
                          -20.955,
                          -175.257
                        ],
                        [
                          79.515,
                          -28.328
                        ],
                        [
                          75.447,
                          59.409
                        ],
                        [
                          76.598,
                          64.337
                        ],
                        [
                          218.338,
                          40.183
                        ]
                      ],
                      "v": [
                        [
                          1365.648,
                          898.794
                        ],
                        [
                          1317.127,
                          1048.731
                        ],
                        [
                          1274.048,
                          1051.083
                        ],
                        [
                          309.508,
                          1051.486
                        ],
                        [
                          210.205,
                          838.229
                        ],
                        [
                          405.105,
                          372.328
                        ],
                        [
                          673.173,
                          425.591
                        ],
                        [
                          826.022,
                          576.663
                        ],
                        [
                          1072.282,
                          677.817
                        ]
                      ],
                      "c": true
                    }
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 376,
                  "s": [
                    {
                      "i": [
                        [
                          -3.454,
                          -121.671
                        ],
                        [
                          13.221,
                          -12.263
                        ],
                        [
                          31.488,
                          -0.768
                        ],
                        [
                          0.285,
                          0
                        ],
                        [
                          10.046,
                          117.079
                        ],
                        [
                          -98.926,
                          99.847
                        ],
                        [
                          -75.258,
                          0.225
                        ],
                        [
                          -87.321,
                          -90.315
                        ],
                        [
                          -130.695,
                          -36.474
                        ]
                      ],
                      "o": [
                        [
                          -19.2,
                          117.084
                        ],
                        [
                          -1.013,
                          4.374
                        ],
                        [
                          -189.521,
                          -0.001
                        ],
                        [
                          0.028,
                          0.026
                        ],
                        [
                          -7.06,
                          -156.321
                        ],
                        [
                          59.096,
                          -56.168
                        ],
                        [
                          74.228,
                          -2.949
                        ],
                        [
                          73.991,
                          62.529
                        ],
                        [
                          186.002,
                          43.169
                        ]
                      ],
                      "v": [
                        [
                          1365.109,
                          902.435
                        ],
                        [
                          1317.639,
                          1048.293
                        ],
                        [
                          1274.56,
                          1050.646
                        ],
                        [
                          305.899,
                          1050.93
                        ],
                        [
                          224.257,
                          864.282
                        ],
                        [
                          431.075,
                          492.877
                        ],
                        [
                          649.171,
                          381.549
                        ],
                        [
                          856.792,
                          486.017
                        ],
                        [
                          1120.772,
                          690.036
                        ]
                      ],
                      "c": true
                    }
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 451,
                  "s": [
                    {
                      "i": [
                        [
                          0.134,
                          -103.948
                        ],
                        [
                          13.221,
                          -12.263
                        ],
                        [
                          31.488,
                          -0.768
                        ],
                        [
                          0.285,
                          0
                        ],
                        [
                          9.681,
                          96.685
                        ],
                        [
                          -117.951,
                          112.883
                        ],
                        [
                          -74.804,
                          65.995
                        ],
                        [
                          -123.407,
                          -128.653
                        ],
                        [
                          -129.63,
                          -49.09
                        ]
                      ],
                      "o": [
                        [
                          -19.2,
                          117.084
                        ],
                        [
                          -1.013,
                          4.374
                        ],
                        [
                          -189.521,
                          -0.001
                        ],
                        [
                          0.028,
                          0.026
                        ],
                        [
                          6.442,
                          -137.919
                        ],
                        [
                          64.297,
                          -58.462
                        ],
                        [
                          72.806,
                          -64.233
                        ],
                        [
                          71.77,
                          60.606
                        ],
                        [
                          154.269,
                          46.493
                        ]
                      ],
                      "v": [
                        [
                          1363.934,
                          906.338
                        ],
                        [
                          1317.319,
                          1048.399
                        ],
                        [
                          1274.24,
                          1050.751
                        ],
                        [
                          301.518,
                          1050.918
                        ],
                        [
                          237.281,
                          890.497
                        ],
                        [
                          467.517,
                          655.38
                        ],
                        [
                          624.976,
                          339.601
                        ],
                        [
                          964.763,
                          361.94
                        ],
                        [
                          1167.239,
                          701.972
                        ]
                      ],
                      "c": true
                    }
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 525,
                  "s": [
                    {
                      "i": [
                        [
                          15.415,
                          -117.814
                        ],
                        [
                          13.221,
                          -12.263
                        ],
                        [
                          31.488,
                          -0.768
                        ],
                        [
                          0.285,
                          0
                        ],
                        [
                          9.41,
                          81.515
                        ],
                        [
                          -143.885,
                          23.957
                        ],
                        [
                          -43.293,
                          98.223
                        ],
                        [
                          -91.909,
                          -12.837
                        ],
                        [
                          -107.069,
                          -86.156
                        ]
                      ],
                      "o": [
                        [
                          -19.2,
                          117.084
                        ],
                        [
                          -1.013,
                          4.374
                        ],
                        [
                          -189.521,
                          -0.001
                        ],
                        [
                          0.028,
                          0.026
                        ],
                        [
                          -20.84,
                          -175.101
                        ],
                        [
                          86.818,
                          -19.483
                        ],
                        [
                          29.023,
                          -75.595
                        ],
                        [
                          137.945,
                          31.771
                        ],
                        [
                          157.014,
                          123.366
                        ]
                      ],
                      "v": [
                        [
                          1397.849,
                          866.618
                        ],
                        [
                          1316.627,
                          1049.231
                        ],
                        [
                          1273.548,
                          1051.583
                        ],
                        [
                          297.805,
                          1051.663
                        ],
                        [
                          246.516,
                          910.75
                        ],
                        [
                          444.72,
                          666.037
                        ],
                        [
                          662.122,
                          488.521
                        ],
                        [
                          843.404,
                          367.829
                        ],
                        [
                          1104.355,
                          586.3
                        ]
                      ],
                      "c": true
                    }
                  ]
                },
                {
                  "t": 599,
                  "s": [
                    {
                      "i": [
                        [
                          21.066,
                          -112.431
                        ],
                        [
                          13.221,
                          -12.263
                        ],
                        [
                          31.488,
                          -0.768
                        ],
                        [
                          0.285,
                          0
                        ],
                        [
                          9.159,
                          67.471
                        ],
                        [
                          -145.609,
                          20.335
                        ],
                        [
                          -60.973,
                          27.949
                        ],
                        [
                          -35.65,
                          40.798
                        ],
                        [
                          -133.894,
                          -8.567
                        ]
                      ],
                      "o": [
                        [
                          -19.2,
                          117.084
                        ],
                        [
                          -1.013,
                          4.374
                        ],
                        [
                          -189.521,
                          -0.001
                        ],
                        [
                          0.028,
                          0.026
                        ],
                        [
                          -20.955,
                          -175.257
                        ],
                        [
                          88.749,
                          -17.083
                        ],
                        [
                          45.664,
                          -21.826
                        ],
                        [
                          89.912,
                          -117.727
                        ],
                        [
                          243.025,
                          20.347
                        ]
                      ],
                      "v": [
                        [
                          1406.648,
                          858.794
                        ],
                        [
                          1317.127,
                          1049.731
                        ],
                        [
                          1274.048,
                          1052.083
                        ],
                        [
                          295.508,
                          1052.082
                        ],
                        [
                          256.205,
                          929.229
                        ],
                        [
                          505.105,
                          689.328
                        ],
                        [
                          720.173,
                          631.591
                        ],
                        [
                          828.022,
                          542.663
                        ],
                        [
                          1163.282,
                          361.817
                        ]
                      ],
                      "c": true
                    }
                  ]
                }
              ],
              "ix": 1
            },
            "o": {
              "a": 0,
              "k": 100,
              "ix": 3
            },
            "x": {
              "a": 0,
              "k": 0,
              "ix": 4
            },
            "nm": "Máscara 1"
          }
        ],
        "shapes": [
          {
            "ind": 0,
            "ty": "sh",
            "ix": 1,
            "ks": {
              "a": 0,
              "k": {
                "i": [
                  [
                    0,
                    0
                  ],
                  [
                    287,
                    3
                  ],
                  [
                    48,
                    191
                  ],
                  [
                    -258.98,
                    -131.778
                  ],
                  [
                    69,
                    367
                  ],
                  [
                    -40,
                    -134
                  ],
                  [
                    186.311,
                    -177.248
                  ],
                  [
                    0,
                    0
                  ]
                ],
                "o": [
                  [
                    0,
                    0
                  ],
                  [
                    -287,
                    -3
                  ],
                  [
                    -48,
                    -191
                  ],
                  [
                    258.98,
                    131.777
                  ],
                  [
                    -69,
                    -367
                  ],
                  [
                    40,
                    134
                  ],
                  [
                    -175.948,
                    167.392
                  ],
                  [
                    0,
                    0
                  ]
                ],
                "v": [
                  [
                    849,
                    1240
                  ],
                  [
                    597,
                    959
                  ],
                  [
                    98,
                    751
                  ],
                  [
                    590.02,
                    778.223
                  ],
                  [
                    1073,
                    428
                  ],
                  [
                    649.5,
                    151.5
                  ],
                  [
                    904.189,
                    712.748
                  ],
                  [
                    17.5,
                    151.5
                  ]
                ],
                "c": false
              },
              "ix": 2
            },
            "nm": "Trazado 1",
            "mn": "ADBE Vector Shape - Group",
            "hd": false
          },
          {
            "ty": "st",
            "c": {
              "a": 0,
              "k": [
                1,
                1,
                1,
                1
              ],
              "ix": 3
            },
            "o": {
              "a": 0,
              "k": 100,
              "ix": 4
            },
            "w": {
              "a": 0,
              "k": 7,
              "ix": 5
            },
            "lc": 1,
            "lj": 1,
            "ml": 10,
            "bm": 0,
            "nm": "Trazo 1",
            "mn": "ADBE Vector Graphic - Stroke",
            "hd": false
          },
          {
            "ind": 2,
            "ty": "sh",
            "ix": 3,
            "ks": {
              "a": 0,
              "k": {
                "i": [
                  [
                    0,
                    0
                  ],
                  [
                    187.843,
                    -118.539
                  ],
                  [
                    0,
                    0
                  ],
                  [
                    -68,
                    92
                  ],
                  [
                    -223.746,
                    67.768
                  ],
                  [
                    0,
                    0
                  ]
                ],
                "o": [
                  [
                    223.774,
                    248.673
                  ],
                  [
                    -443.544,
                    279.903
                  ],
                  [
                    0,
                    0
                  ],
                  [
                    68,
                    -92
                  ],
                  [
                    223.745,
                    -67.768
                  ],
                  [
                    0,
                    0
                  ]
                ],
                "v": [
                  [
                    1094.588,
                    201.846
                  ],
                  [
                    1270.455,
                    818.901
                  ],
                  [
                    932,
                    1192
                  ],
                  [
                    1038.5,
                    1163.5
                  ],
                  [
                    1370.755,
                    980.268
                  ],
                  [
                    1576.5,
                    593.5
                  ]
                ],
                "c": false
              },
              "ix": 2
            },
            "nm": "Trazado 2",
            "mn": "ADBE Vector Shape - Group",
            "hd": false
          },
          {
            "ty": "st",
            "c": {
              "a": 0,
              "k": [
                1,
                1,
                1,
                1
              ],
              "ix": 3
            },
            "o": {
              "a": 0,
              "k": 100,
              "ix": 4
            },
            "w": {
              "a": 0,
              "k": 7,
              "ix": 5
            },
            "lc": 1,
            "lj": 1,
            "ml": 10,
            "bm": 0,
            "nm": "Trazo 2",
            "mn": "ADBE Vector Graphic - Stroke",
            "hd": false
          }
        ],
        "ip": 0,
        "op": 600,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 23,
        "ty": 4,
        "nm": "mancha",
        "sr": 1,
        "ks": {
          "o": {
            "a": 0,
            "k": 100,
            "ix": 11
          },
          "r": {
            "a": 0,
            "k": 0,
            "ix": 10
          },
          "p": {
            "a": 0,
            "k": [
              694.88,
              540,
              0
            ],
            "ix": 2
          },
          "a": {
            "a": 0,
            "k": [
              596.5,
              351,
              0
            ],
            "ix": 1
          },
          "s": {
            "a": 0,
            "k": [
              100,
              100,
              100
            ],
            "ix": 6
          }
        },
        "ao": 0,
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 1,
                  "k": [
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 0,
                      "s": [
                        {
                          "i": [
                            [
                              21.066,
                              -112.431
                            ],
                            [
                              13.221,
                              -12.263
                            ],
                            [
                              31.488,
                              -0.768
                            ],
                            [
                              0.285,
                              0
                            ],
                            [
                              9.159,
                              67.471
                            ],
                            [
                              -145.609,
                              20.335
                            ],
                            [
                              -60.973,
                              27.949
                            ],
                            [
                              -35.65,
                              40.798
                            ],
                            [
                              -133.894,
                              -8.567
                            ]
                          ],
                          "o": [
                            [
                              -19.2,
                              117.084
                            ],
                            [
                              -1.013,
                              4.374
                            ],
                            [
                              -189.521,
                              -0.001
                            ],
                            [
                              0.028,
                              0.026
                            ],
                            [
                              -20.955,
                              -175.257
                            ],
                            [
                              88.749,
                              -17.083
                            ],
                            [
                              45.664,
                              -21.826
                            ],
                            [
                              89.912,
                              -117.727
                            ],
                            [
                              243.025,
                              20.347
                            ]
                          ],
                          "v": [
                            [
                              575.166,
                              155.116
                            ],
                            [
                              485.645,
                              346.053
                            ],
                            [
                              442.566,
                              348.405
                            ],
                            [
                              -535.974,
                              348.404
                            ],
                            [
                              -575.277,
                              225.551
                            ],
                            [
                              -326.377,
                              -14.349
                            ],
                            [
                              -111.309,
                              -72.086
                            ],
                            [
                              -3.46,
                              -161.015
                            ],
                            [
                              331.8,
                              -341.86
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 75,
                      "s": [
                        {
                          "i": [
                            [
                              15.479,
                              -117.873
                            ],
                            [
                              13.221,
                              -12.263
                            ],
                            [
                              31.488,
                              -0.768
                            ],
                            [
                              0.285,
                              0
                            ],
                            [
                              9.409,
                              81.451
                            ],
                            [
                              -143.993,
                              23.584
                            ],
                            [
                              -43.161,
                              98.358
                            ],
                            [
                              -91.777,
                              -12.351
                            ],
                            [
                              -106.974,
                              -86.311
                            ]
                          ],
                          "o": [
                            [
                              -19.2,
                              117.084
                            ],
                            [
                              -1.013,
                              4.374
                            ],
                            [
                              -189.521,
                              -0.001
                            ],
                            [
                              0.028,
                              0.026
                            ],
                            [
                              -20.955,
                              -175.257
                            ],
                            [
                              86.913,
                              -19.319
                            ],
                            [
                              28.839,
                              -75.642
                            ],
                            [
                              138.223,
                              31.649
                            ],
                            [
                              157.026,
                              123.689
                            ]
                          ],
                          "v": [
                            [
                              567.013,
                              163.27
                            ],
                            [
                              485.645,
                              346.053
                            ],
                            [
                              442.566,
                              348.405
                            ],
                            [
                              -533.19,
                              348.485
                            ],
                            [
                              -584.425,
                              207.654
                            ],
                            [
                              -386.354,
                              -37.099
                            ],
                            [
                              -168.701,
                              -214.035
                            ],
                            [
                              11.915,
                              -335.327
                            ],
                            [
                              273.112,
                              -117.366
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 149,
                      "s": [
                        {
                          "i": [
                            [
                              0.056,
                              -103.877
                            ],
                            [
                              13.221,
                              -12.263
                            ],
                            [
                              31.488,
                              -0.768
                            ],
                            [
                              0.285,
                              0
                            ],
                            [
                              9.682,
                              96.763
                            ],
                            [
                              -117.818,
                              113.339
                            ],
                            [
                              -74.97,
                              65.826
                            ],
                            [
                              -123.568,
                              -129.246
                            ],
                            [
                              -129.745,
                              -48.9
                            ]
                          ],
                          "o": [
                            [
                              -19.2,
                              117.084
                            ],
                            [
                              -1.013,
                              4.374
                            ],
                            [
                              -189.521,
                              -0.001
                            ],
                            [
                              0.028,
                              0.026
                            ],
                            [
                              6.582,
                              -137.729
                            ],
                            [
                              64.182,
                              -58.661
                            ],
                            [
                              73.03,
                              -64.174
                            ],
                            [
                              71.432,
                              60.754
                            ],
                            [
                              154.255,
                              46.1
                            ]
                          ],
                          "v": [
                            [
                              532.083,
                              204.2
                            ],
                            [
                              485.645,
                              346.053
                            ],
                            [
                              442.566,
                              348.405
                            ],
                            [
                              -530.141,
                              348.573
                            ],
                            [
                              -594.444,
                              188.051
                            ],
                            [
                              -364.044,
                              -47.016
                            ],
                            [
                              -206.892,
                              -363.503
                            ],
                            [
                              133.707,
                              -340.431
                            ],
                            [
                              335.883,
                              0.223
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 224,
                      "s": [
                        {
                          "i": [
                            [
                              -3.457,
                              -121.687
                            ],
                            [
                              13.221,
                              -12.263
                            ],
                            [
                              31.488,
                              -0.768
                            ],
                            [
                              0.285,
                              0
                            ],
                            [
                              10.046,
                              117.097
                            ],
                            [
                              -98.909,
                              99.834
                            ],
                            [
                              -75.259,
                              0.164
                            ],
                            [
                              -87.287,
                              -90.279
                            ],
                            [
                              -130.696,
                              -36.462
                            ]
                          ],
                          "o": [
                            [
                              -19.2,
                              117.084
                            ],
                            [
                              -1.013,
                              4.374
                            ],
                            [
                              -189.521,
                              -0.001
                            ],
                            [
                              0.028,
                              0.026
                            ],
                            [
                              -7.073,
                              -156.338
                            ],
                            [
                              59.091,
                              -56.166
                            ],
                            [
                              74.229,
                              -2.893
                            ],
                            [
                              73.993,
                              62.531
                            ],
                            [
                              186.032,
                              43.166
                            ]
                          ],
                          "v": [
                            [
                              533.116,
                              200.191
                            ],
                            [
                              485.645,
                              346.053
                            ],
                            [
                              442.566,
                              348.405
                            ],
                            [
                              -526.091,
                              348.69
                            ],
                            [
                              -607.749,
                              162.018
                            ],
                            [
                              -400.953,
                              -209.512
                            ],
                            [
                              -182.801,
                              -320.652
                            ],
                            [
                              24.698,
                              -216.109
                            ],
                            [
                              288.735,
                              -12.215
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 300,
                      "s": [
                        {
                          "i": [
                            [
                              -7.028,
                              -139.794
                            ],
                            [
                              13.221,
                              -12.263
                            ],
                            [
                              31.488,
                              -0.768
                            ],
                            [
                              0.285,
                              0
                            ],
                            [
                              10.415,
                              137.771
                            ],
                            [
                              -137.485,
                              36.672
                            ],
                            [
                              -75.553,
                              -66.591
                            ],
                            [
                              -50.402,
                              -50.663
                            ],
                            [
                              -131.662,
                              -23.817
                            ]
                          ],
                          "o": [
                            [
                              -19.2,
                              117.084
                            ],
                            [
                              -1.013,
                              4.374
                            ],
                            [
                              -189.521,
                              -0.001
                            ],
                            [
                              0.028,
                              0.026
                            ],
                            [
                              -20.955,
                              -175.257
                            ],
                            [
                              79.515,
                              -28.328
                            ],
                            [
                              75.447,
                              59.409
                            ],
                            [
                              76.598,
                              64.337
                            ],
                            [
                              218.338,
                              40.183
                            ]
                          ],
                          "v": [
                            [
                              534.166,
                              196.116
                            ],
                            [
                              485.645,
                              346.053
                            ],
                            [
                              442.566,
                              348.405
                            ],
                            [
                              -521.974,
                              348.809
                            ],
                            [
                              -621.277,
                              135.551
                            ],
                            [
                              -426.377,
                              -330.349
                            ],
                            [
                              -158.309,
                              -277.086
                            ],
                            [
                              -5.46,
                              -126.015
                            ],
                            [
                              240.8,
                              -24.86
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 376,
                      "s": [
                        {
                          "i": [
                            [
                              -3.457,
                              -121.687
                            ],
                            [
                              13.221,
                              -12.263
                            ],
                            [
                              31.488,
                              -0.768
                            ],
                            [
                              0.285,
                              0
                            ],
                            [
                              10.046,
                              117.097
                            ],
                            [
                              -98.909,
                              99.834
                            ],
                            [
                              -75.259,
                              0.164
                            ],
                            [
                              -87.287,
                              -90.279
                            ],
                            [
                              -130.696,
                              -36.462
                            ]
                          ],
                          "o": [
                            [
                              -19.2,
                              117.084
                            ],
                            [
                              -1.013,
                              4.374
                            ],
                            [
                              -189.521,
                              -0.001
                            ],
                            [
                              0.028,
                              0.026
                            ],
                            [
                              -7.073,
                              -156.338
                            ],
                            [
                              59.091,
                              -56.166
                            ],
                            [
                              74.229,
                              -2.893
                            ],
                            [
                              73.993,
                              62.531
                            ],
                            [
                              186.032,
                              43.166
                            ]
                          ],
                          "v": [
                            [
                              533.116,
                              200.191
                            ],
                            [
                              485.645,
                              346.053
                            ],
                            [
                              442.566,
                              348.405
                            ],
                            [
                              -526.091,
                              348.69
                            ],
                            [
                              -607.749,
                              162.018
                            ],
                            [
                              -400.953,
                              -209.512
                            ],
                            [
                              -182.801,
                              -320.652
                            ],
                            [
                              24.698,
                              -216.109
                            ],
                            [
                              288.735,
                              -12.215
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 451,
                      "s": [
                        {
                          "i": [
                            [
                              0.056,
                              -103.877
                            ],
                            [
                              13.221,
                              -12.263
                            ],
                            [
                              31.488,
                              -0.768
                            ],
                            [
                              0.285,
                              0
                            ],
                            [
                              9.682,
                              96.763
                            ],
                            [
                              -117.818,
                              113.339
                            ],
                            [
                              -74.97,
                              65.826
                            ],
                            [
                              -123.568,
                              -129.246
                            ],
                            [
                              -129.745,
                              -48.9
                            ]
                          ],
                          "o": [
                            [
                              -19.2,
                              117.084
                            ],
                            [
                              -1.013,
                              4.374
                            ],
                            [
                              -189.521,
                              -0.001
                            ],
                            [
                              0.028,
                              0.026
                            ],
                            [
                              6.582,
                              -137.729
                            ],
                            [
                              64.182,
                              -58.661
                            ],
                            [
                              73.03,
                              -64.174
                            ],
                            [
                              71.431,
                              60.754
                            ],
                            [
                              154.255,
                              46.1
                            ]
                          ],
                          "v": [
                            [
                              532.082,
                              204.2
                            ],
                            [
                              485.645,
                              346.053
                            ],
                            [
                              442.566,
                              348.405
                            ],
                            [
                              -530.141,
                              348.573
                            ],
                            [
                              -594.444,
                              188.051
                            ],
                            [
                              -364.044,
                              -47.016
                            ],
                            [
                              -206.892,
                              -363.503
                            ],
                            [
                              133.706,
                              -340.431
                            ],
                            [
                              335.883,
                              0.223
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "i": {
                        "x": 0.833,
                        "y": 0.833
                      },
                      "o": {
                        "x": 0.167,
                        "y": 0.167
                      },
                      "t": 525,
                      "s": [
                        {
                          "i": [
                            [
                              15.479,
                              -117.873
                            ],
                            [
                              13.221,
                              -12.263
                            ],
                            [
                              31.488,
                              -0.768
                            ],
                            [
                              0.285,
                              0
                            ],
                            [
                              9.409,
                              81.451
                            ],
                            [
                              -143.993,
                              23.584
                            ],
                            [
                              -43.161,
                              98.358
                            ],
                            [
                              -91.777,
                              -12.351
                            ],
                            [
                              -106.974,
                              -86.311
                            ]
                          ],
                          "o": [
                            [
                              -19.2,
                              117.084
                            ],
                            [
                              -1.013,
                              4.374
                            ],
                            [
                              -189.521,
                              -0.001
                            ],
                            [
                              0.028,
                              0.026
                            ],
                            [
                              -20.955,
                              -175.257
                            ],
                            [
                              86.913,
                              -19.319
                            ],
                            [
                              28.839,
                              -75.642
                            ],
                            [
                              138.223,
                              31.649
                            ],
                            [
                              157.026,
                              123.689
                            ]
                          ],
                          "v": [
                            [
                              567.013,
                              163.27
                            ],
                            [
                              485.645,
                              346.053
                            ],
                            [
                              442.566,
                              348.405
                            ],
                            [
                              -533.19,
                              348.485
                            ],
                            [
                              -584.425,
                              207.654
                            ],
                            [
                              -386.354,
                              -37.099
                            ],
                            [
                              -168.701,
                              -214.035
                            ],
                            [
                              11.915,
                              -335.327
                            ],
                            [
                              273.112,
                              -117.366
                            ]
                          ],
                          "c": true
                        }
                      ]
                    },
                    {
                      "t": 599,
                      "s": [
                        {
                          "i": [
                            [
                              21.066,
                              -112.431
                            ],
                            [
                              13.221,
                              -12.263
                            ],
                            [
                              31.488,
                              -0.768
                            ],
                            [
                              0.285,
                              0
                            ],
                            [
                              9.159,
                              67.471
                            ],
                            [
                              -145.609,
                              20.335
                            ],
                            [
                              -60.973,
                              27.949
                            ],
                            [
                              -35.65,
                              40.798
                            ],
                            [
                              -133.894,
                              -8.567
                            ]
                          ],
                          "o": [
                            [
                              -19.2,
                              117.084
                            ],
                            [
                              -1.013,
                              4.374
                            ],
                            [
                              -189.521,
                              -0.001
                            ],
                            [
                              0.028,
                              0.026
                            ],
                            [
                              -20.955,
                              -175.257
                            ],
                            [
                              88.749,
                              -17.083
                            ],
                            [
                              45.664,
                              -21.826
                            ],
                            [
                              89.912,
                              -117.727
                            ],
                            [
                              243.025,
                              20.347
                            ]
                          ],
                          "v": [
                            [
                              575.166,
                              155.116
                            ],
                            [
                              485.645,
                              346.053
                            ],
                            [
                              442.566,
                              348.405
                            ],
                            [
                              -535.974,
                              348.404
                            ],
                            [
                              -575.277,
                              225.551
                            ],
                            [
                              -326.377,
                              -14.349
                            ],
                            [
                              -111.309,
                              -72.086
                            ],
                            [
                              -3.46,
                              -161.015
                            ],
                            [
                              331.8,
                              -341.86
                            ]
                          ],
                          "c": true
                        }
                      ]
                    }
                  ],
                  "ix": 2
                },
                "nm": "forma",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ty": "gf",
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 10
                },
                "r": 1,
                "bm": 0,
                "g": {
                  "p": 3,
                  "k": {
                    "a": 0,
                    "k": [
                      0.008,
                      1,
                      0.9450980392156862,
                      0.8117647058823529,
                      0.41,
                      0.9921568627450981,
                      0.9254901960784314,
                      0.7686274509803922,
                      1,
                      0.9803921568627451,
                      0.8823529411764706,
                      0.6352941176470588
                    ],
                    "ix": 9
                  }
                },
                "s": {
                  "a": 0,
                  "k": [
                    -42.523,
                    -52.922
                  ],
                  "ix": 5
                },
                "e": {
                  "a": 0,
                  "k": [
                    82.414,
                    489.625
                  ],
                  "ix": 6
                },
                "t": 1,
                "nm": "Relleno de degradado 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
              },
              {
                "ty": "tr",
                "p": {
                  "a": 0,
                  "k": [
                    596.482,
                    350.678
                  ],
                  "ix": 2
                },
                "a": {
                  "a": 0,
                  "k": [
                    0,
                    0
                  ],
                  "ix": 1
                },
                "s": {
                  "a": 0,
                  "k": [
                    100,
                    100
                  ],
                  "ix": 3
                },
                "r": {
                  "a": 0,
                  "k": 0,
                  "ix": 6
                },
                "o": {
                  "a": 0,
                  "k": 100,
                  "ix": 7
                },
                "sk": {
                  "a": 0,
                  "k": 0,
                  "ix": 4
                },
                "sa": {
                  "a": 0,
                  "k": 0,
                  "ix": 5
                },
                "nm": "Transformar"
              }
            ],
            "nm": "shape",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ],
        "ip": 0,
        "op": 600,
        "st": 0,
        "bm": 0
      }
    ],
    "markers": []
  }