import React from 'react'
import cls from './Minicard.module.scss'
import { Chip } from 'primereact/chip';

function MiniCard({data}){
    return(   
        <>
            <a href="/" className={cls.card}>
                <img 
                    src="/images/weeknd.jpeg" 
                    className={cls.img} 
                    alt="alt img"
                    width={70}
                    height={70}
                />
                <div className={cls.content}>
                    <h4 className={cls.author}>{data.name}</h4>
                    <Chip label={data.rang} className={cls.title}/>
                </div>
            </a>
        </>
    )
    
}

export default MiniCard