import React, { useRef, useState } from 'react'
import i18n from '../../../i18n'
import i18next from 'i18next'

const LanguagePicker = () => {
  const langs = [
    {
      name: 'uz',
      title: "O'Z",
      full: "O'zbek",
      image: '/images/uzb.svg',
      abr: 'uz-UZ',
    },
    {
      name: 'ru',
      title: 'РУ',
      full: "Русский",
      image: '/images/russia.svg',
      abr: 'ru-RU',
    },
    {
      name: 'en',
      title: 'EN',
      full: "English",
      image: '/images/russia.svg',
      abr: 'en-EN',
    },
  ]

  const [open, setOpen] = useState(false)

  const [selected, setSelected] = useState(
    i18n.language === 'uz-UZ' ? langs[0] :
    i18n.language === 'ru-RU' ? langs[1] :
    langs[2]
  )
  // const [selected, setSelected] = useState(langs[1])

  const handleChange = (index) => (event) => {
    let lang = langs[index]
    i18next.changeLanguage(lang.abr)
    setSelected(lang)
    setOpen(false)
    window.location.reload()
  }
  const wrapperRef = useRef(null)

  return (
    <div className="selected_lang">
      <button
        className="selected_item"
        style={{
          cursor: 'pointer',
        }}
        onClick={() => {
          setOpen(!open)
        }}
        aria-label="selected_lang_btn"
        id="selected_lang_btn"
      >
        <span>{selected.title}</span>
      </button>
      {open ? (
        <ul className="language_list shadow" ref={wrapperRef}>
          {langs.map((lang, i) => (
            <li className="language_item" onClick={handleChange(i)} key={i}>
              <span>{lang.full}</span>
            </li>
          ))}
        </ul>
      ) : (
        ''
      )}
    </div>
  )
}

export default LanguagePicker
