import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPermissions } from '../../../redux/actions/commonReducer'
import Box from '../../ui/box'
import PermissionTable from '../../ui/permission-table'

const Settings = () => {

    const permissions = useSelector(store => store.common.permissions)
    const dispatch = useDispatch()
    const [perm,setPerm] = useState()

    useEffect(()=>{
        setPerm(permissions)
    },[permissions])


    const handleChange = (idx, per , value) => {
        let items = [...permissions]
        items[idx].permissions[per].value = value

        setPerm([...items])
    }

    const handleConfirm = () => {
        dispatch(setPermissions([...perm]))
    }

    return(
        <Box allow={true}>
            <div className="p-mt-3">
                <PermissionTable
                    data={permissions}
                    handleChange={handleChange}
                    allow={true}
                />
            </div>
            <div className="p-mt-2 p-d-flex p-jc-end">
                <Button
                    icon="pi pi-save"
                    label="Сохранить"
                    onClick={handleConfirm}
                />
            </div>
        </Box>
    )
}

export default Settings