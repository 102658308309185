import React, { useEffect, useRef, useState } from 'react'

function RecordsTable({
    data,
    handleOpenModal,
    setAudioSrc,
    audioRef,
    handlePlay
}){
    const player = useRef()
    const [duration, setDuration] = useState(0)

    useEffect(()=>{
        let elementRef = player.current
        setTimeout(()=>{
                setDuration(toMinuteSecund(elementRef.duration))
        },1000)
    },[player])

    const handleOnPlay = src => e => {
        setAudioSrc(src)
        handleOpenModal()
        handlePlay(audioRef)
    }
    const handleOnPause = (e) => {
        setDuration(toMinuteSecund(e.target.duration))
    }

    const toMinuteSecund = (value) =>{
        return Math.floor(value / 60)+'.'+ Math.floor(value % 60)
    }

    return(
        <div className="custom-table datatable-responsive-demo">
            <div className="card">
                <div 
                    className="p-datatable p-component p-datatable-responsive-demo"
                    data-scrollselectors=".p-datatable-scrollable-body, .p-datatable-unfrozen-view .p-datatable-scrollable-body"
                >
                    <div className="p-datatable-wrapper">
                        <table 
                            role="grid" 
                        >
                            <thead className="p-datatable-thead">
                                <tr>
                                    <th className="p-pl-5">
                                        <span className="p-ml-1 pi pi-clock"></span>
                                    </th>
                                    <th>Название</th>
                                    <th>Исполнитель</th>
                                    <th>Композитор</th>
                                    <th>Автор текста</th>
                                    <th>ISRC</th>
                                    <th>Lable</th>
                                </tr>
                            </thead>
                            <tbody className="p-datatable-tbody">
                                {
                                    data.map((item,i) => 
                                    <tr key={i}>
                                        <td role="cell">
                                            <audio 
                                                ref={player}
                                                className="hide-player" 
                                                controls 
                                                controlsList="noplaybackrate"
                                                onPlay={handleOnPlay}
                                                onPause={handleOnPause}
                                            >
                                                <source src="/media/blinding_lights.mp3" type="audio/mpeg"/>
                                                Your browser does not support the audio tag.
                                            </audio>
                                            <div className="custom-audio show-player">
                                                <img 
                                                    src="/images/play.svg" 
                                                    width={17} 
                                                    height={17} 
                                                    onClick={handleOnPlay(`/media/blinding_lights.mp3`)} 
                                                    alt="img"
                                                />
                                                <div>
                                                    {/* <span>{played}</span> /  */}
                                                    <span>{!isNaN(duration) ?duration:0}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td role="cell" className="p-row-odd">
                                            {item.name}
                                        </td>
                                        <td role="cell">
                                            {item.singer}
                                        </td>
                                        <td role="cell" className="p-row-odd">
                                            {item.composer}
                                        </td>
                                        <td role="cell">
                                            {item.author}
                                        </td>
                                        <td role="cell" className="p-row-odd">
                                            {item.isrc}
                                        </td>
                                        <td role="cell">
                                            {item.label}
                                        </td>
                                    </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecordsTable