import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { Button } from 'primereact/button';

function HistoryTable({history}){
    const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
    const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

    return (
        <div className="datatable-responsive-demo table-nevo">
            <DataTable 
                value={history} 
                // paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                currentPageReportTemplate="{first} - {last} из {totalRecords}" 
                rows={10} 
                className="p-datatable-responsive-demo"
                rowsPerPageOptions={[10,20,50]}
                paginatorLeft={paginatorLeft} 
                paginatorRight={paginatorRight}
                paginator
            >
                <Column field="created_at" header="Дата и время"/>
                <Column field="staff" header="Сотрудник"/>
                <Column field="action" header="Действии"/>
                <Column field="data" header="Данные"/>
            </DataTable>
        </div>
    )
}

export default HistoryTable