export const RecordFaker = {
    author:"The Weeknd",
    record:"Blinding lights",
    img:"/images/weeknd.jpeg",
    added_at:"05.08.2020",
    nv_asset:"9958581650",
    yt_asset:"A863288910453914",
    released_at:"05.08.2020",
    records:[
        {
            name:"Blinding lights",
            singer:"The Weeknd",
            composer:"The Weeknd",
            author:"The Weeknd",
            isrc:"NLRD52033709",
            label:"NEVO"
        }
    ],
    performers:[
        {
            name:"The Weeknd",
            rang:"Основной артист"
        }
    ],
    authors:[
        {
            name:"The Weeknd",
            rang:"Основной артист"
        },
        {
            name:"The Weeknd",
            rang:"Основной артист"
        }
    ],
    isrc: "NLRD52033709",
    label:"NEVO",
    history: [
        {
            created_at:"06.08.2020 11:38",
            staff: "Otabek Nosirov (otabektuit11@gmail.com)",
            action:"Изменен",
            data:"Добавлен: Weeknd - Композитор"
        }
    ]
} 
