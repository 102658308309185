import { commonActionTypes } from './commonActionTypes'

export const setOpenModal = (payload) => {
  return {
    type: commonActionTypes.OPEN_CREATE_OBJECT_MODAL,
    payload: payload,
  }
}

export const setOpenModalSinger = (payload) => {
  return {
    type: commonActionTypes.OPEN_CREATE_SINGER_MODAL,
    payload: payload,
  }
}

export const setOpenModalStaff = (payload) => {
  return {
    type: commonActionTypes.OPEN_CREATE_STAFF_MODAL,
    payload: payload,
  }
}

export const setOpenConfirmModal = (payload) => {
  return {
    type: commonActionTypes.OPEN_CONFIRM_MODAL,
    payload: payload,
  }
}

export const setUser = (payload) => {
  return {
    type: commonActionTypes.SET_USER,
    payload: payload,
  }
}

export const setPermissions = (payload) => {
  return {
    type: commonActionTypes.SET_PERMISSIONS,
    payload: payload,
  }
}

export const setLoading = (payload) => {
  return {
    type: commonActionTypes.SET_LOADING,
    payload: payload,
  }
}
