import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../../redux/actions/commonReducer'
import { requests } from '../../../../services/requests'
import SingerCard from '../../../ui/SingerCard'

function Singers() {
  const [singers, setSingers] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setLoading(true))
    requests.account
      .getAllPersons()
      .then((res) => {
        const { data } = res
        let items = []
        items = data.map((res) => {
          return {
            pseudonym: res.pseudonym,
            photo: res.photo,
            is_active: res.is_active,
            id: res.id,
          }
        })
        setSingers([...items])
        dispatch(setLoading(false))
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <div className="singers">
      <div className="container">
        <div className="p-grid">
          {singers?.map((item, i) => (
            <div className="p-col-6 p-sm-4 p-md-3 p-lg-2" key={i}>
              <SingerCard
                name={item.pseudonym}
                img={item.photo || '/images/user.png'}
                checked={item.is_active}
                id={item.id}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Singers
