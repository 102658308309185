import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import { useSelector } from 'react-redux'
import { lottie_permission } from '../../styles/lottie/no_permission'
import { getPermission } from '../../utils/permission'


function Box({
    children, 
    className, 
    block, 
    permission_key,
    allow
}){
    const [ active , setActive] = useState(false)

    const permissions = useSelector(store => store.common.permissions)

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: lottie_permission,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    useEffect(()=>{
        setActive(getPermission(permissions,block,permission_key,allow))
    },[permissions])

    return(
        <div className={`container ${className ? className : ''}`}>
            {
                active?
                children
                :
                <div className="no-permission-empty">
                    <h2>У вас нет доступа на этот раздел</h2>
                    <Lottie 
                        options={defaultOptions}
                        height="80vh"
                        width="60vw"
                    />
                    
                </div>
            }
            
        </div>
    )
}

export default Box