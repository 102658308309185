import React from 'react'
import { BreadCrumb } from 'primereact/breadcrumb';

function BreadcrumbNevo({items}){
    return(
        <BreadCrumb model={items} home={{
            icon:"pi pi-home",
            url:"/"
        }}/>
    )
}

export default BreadcrumbNevo