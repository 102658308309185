import { authRequest, customRequest, request } from "../api"

const auth_api = 'api/v1/managers/'

export const auth = {
    // JWT API
    createJwt: (email,password) => authRequest.post(`${auth_api}jwt/create/`, {email,password}),
    refreshJwt: (refresh) => authRequest.post(`${auth_api}jwt/refresh/`, {refresh}),
    verifyJwt: (token) => authRequest.post(`${auth_api}jwt/verify/`, {token}) ,

    // Users API
    getUsersList: () => authRequest.get(`${auth_api}users/`),
    getCurrentUser: () => authRequest.get(`${auth_api}users/me/`),
    getCurrentUserToken: (token) => customRequest(token).get(`${auth_api}users/me/`),
    deleteCurrentUser: () => authRequest.delete(`${auth_api}users/me/`),
    putCurrentUser: (
        username,
        first_name,
        last_name,
        id
    ) => 
        authRequest.put(`${auth_api}users/me`,{
            username,
            first_name,
            last_name,
            id
    }),
    putCurrentUser: (
        username,
        first_name,
        last_name,
        id
    ) => authRequest.patch(`${auth_api}users/me`,{
            username,
            first_name,
            last_name,
            id
    }),
    createUser: (data) => 
        request.post(`${auth_api}users/`,{
            username: data.username,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            id: data.id,
            password: data.password,
            re_password: data.re_password,
            role: "moderator"
        })
    ,
    activateUser: (
        uid,
        token
    ) => authRequest.post(`${auth_api}users/activation`, {
            uid,
            token
    }),
    getUserById: (id) => authRequest.get(`${auth_api}users/${id}/`),
    deleteUserById: (id) => authRequest.delete(`${auth_api}users/${id}/`),
    putUserById: (
        id,
        username,
        first_name,
        last_name
    ) =>
        authRequest.put(`${auth_api}users/${id}`, {
            id,
            username,
            first_name,
            last_name
    }),
    patchUserById: (
        id,
        username,
        first_name,
        last_name
    ) => 
        authRequest.patch(`${auth_api}users/${id}`, {
            id,
            username,
            first_name,
            last_name
    }),

    // Config API
    resendActivation: ( email ) => 
        authRequest.post(`${auth_api}users/resnd_activation` , {
            email
        })
    ,
    resetEmail: ( email ) => 
        authRequest.post(`${auth_api}users/reset_email` , {
            email
    }),
    resetEmailConfirm: ( email ) => 
        authRequest.post(`${auth_api}users/reset_email_confirm` , {
            email
    }),
    resetPassword: ( email ) => 
        authRequest.post(`${auth_api}users/reset_password` , {
            email
    }),
    resetPasswordConfirm: ( 
        uid,
        token,
        password
     ) => 
        authRequest.post(`${auth_api}users/reset_password_confirm` , {
            uid,
            token,
            password
    }),
    setEmail: (
        current_password,
        new_email,
        re_new_email
    ) => 
        authRequest.post(`${auth_api}users/set_email` , {
            current_password,
            new_email,
            re_new_email
    }),
    setPassword: (
        current_password,
        new_password,
        re_new_password
    ) => 
        authRequest.post(`${auth_api}users/set_password` , {
            current_password,
            new_password,
            re_new_password
    }),
}