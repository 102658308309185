import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import { useSelector } from 'react-redux'
import { getPermission } from '../../../utils/permission'

const PermissionButton = (props) => {
    /* 
        --------Instruction--------

            props => 
                block - {author,user,asset,partner} => pages
                permission_key - {read,read_passport,read_contact,edit,delete,create} => action key
                allow - {true,false} default false => force allow (f.e. for admin)
    
    */

    const { block, permission_key, allow } = props
    const [active,setActive] = useState(false)
    const permissions = useSelector(store => store.common.permissions)
    
    useEffect(() => {
        setActive(
            getPermission(
                permissions,
                block,
                permission_key,
                allow
            )
        );

    },[permissions])

    return(
        <>
            {
                active?
                <Button
                    {...props}
                />
                :''
            }
        </>
    )
}

export default PermissionButton