import axios from 'axios'
import { toast } from 'react-toastify'
import { requests } from '../requests'

const token = localStorage.getItem('access_token')
const reqHandler = {
  baseURL: process.env.REACT_APP_BASE_URL,
  'access-control-allow-origin': '*',
  'Content-type': 'application/json; charset=UTF-8',
}

export const request = axios.create({ ...reqHandler })

export const authRequest = axios.create({
  ...reqHandler,
  headers: {
    Authorization: `Bearer ${token}`,
  },
})

export const customRequest = (tkn) =>
  axios.create({
    ...reqHandler,
    headers: {
      Authorization: `Bearer ${tkn}`,
    },
  })

authRequest.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

authRequest.interceptors.response.use(
  function (config) {
    return config
  },
  function (err) {
    const refresh = localStorage.getItem('refresh_token')

    if (err.response.status === 401) {
      if (err.response.data.messages[0].token_type === 'access') {
        requests.auth
          .refreshJwt(refresh)
          .then((res) => {
            if (res.access && res.refresh) {
              localStorage.setItem('access_token', res.access)
              localStorage.setItem('refresh_token', res.refresh)
            } else {
              localStorage.clear()
              window.location.reload()
            }
          })
          .catch((_) => {
            localStorage.clear()
            window.location.reload()
          })
      } else {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        window.location.reload()
      }
    }
    if (err.response.status === 500) toast.error('Ошибка при запросе')

    return err
  }
)
