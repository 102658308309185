import React, { useEffect, useState } from 'react'
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { getPermission } from '../../utils/permission';
import { useSelector } from 'react-redux';

function PanelNevo({children,title, block, permission_key,allow}){
    const [active, setActive] = useState(false)
    const permissions = useSelector(store => store.common.permissions)
    useEffect(()=>{
        setActive(
            getPermission(
                permissions,
                block,
                permission_key,
                allow
            )
        )
    },[permissions])

    const template = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} p-d-flex p-jc-between`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className}>
                <span className={titleClassName}>
                    {title}
                </span>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    return(
        active?
        <Panel headerTemplate={template} toggleable>
            {children}
        </Panel>
        :''
    )
}

export default PanelNevo