import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getPermission } from '../../../utils/permission'
import Box from '../../ui/box'
import HistoryTable from '../../ui/histroy-table'
import PanelNevo from '../../ui/panel'
import PermissionTable from '../../ui/permission-table'
import StaffSingleCard from '../../ui/StaffSingleCard'
import { RecordFaker } from '../assets/record/const'

const StaffSingle = (props) => {
    const { block } = props
    const item = RecordFaker
    const [active,setActive] = useState(false)
    const [data] = useState({
        name: 'Otabek Nosirov',
        phone: '+998911234567',
        login: 'otabek',
        email: 'info@bek.com',
        img: '/images/weeknd.jpeg',
        roles: [ 'Админ' , 'Модератор']
    })


    const [staff_permissions,setPermissions] = useState([
        {
            label: "Блок авторов",
            permissions: [
                {
                    label: 'Просмотр',
                    key: 'read',
                    value: true
                },
                {
                    label: 'Просмотр паспортные данные ',
                    key: 'read_passport',
                    value: false
                },
                {
                    label: 'Просмотр контракта',
                    key: 'read_contract',
                    value: false
                },
                {
                    label: 'Просмотр аналитику',
                    key: 'read_analytics',
                    value: true
                },
                {
                    label: 'Создать',
                    key: 'create',
                    value: false
                },
                {
                    label: 'Редактировать',
                    key: 'edit',
                    value: false
                },
                {
                    label: 'Удалить',
                    key: 'delete',
                    value: false
                },
            ]
        },
        {
            label: "Блок объектов",
            permissions: [
                {
                    label: 'Просмотр',
                    key: 'read',
                    value: true
                },
                {
                    label: 'Просмотр аналитику',
                    key: 'read_analytics',
                    value: true
                },
                {
                    label: 'Создать',
                    key: 'create',
                    value: false
                },
                {
                    label: 'Редактировать',
                    key: 'edit',
                    value: false
                },
                {
                    label: 'Удалить',
                    key: 'delete',
                    value: false
                },
            ]
        },
        {
            label: "Блок партнеров",
            permissions: [
                {
                    label: 'Просмотр',
                    key: 'read',
                    value: true
                },
                {
                    label: 'Создать',
                    key: 'create',
                    value: false
                },
                {
                    label: 'Редактировать',
                    key: 'edit',
                    value: false
                },
                {
                    label: 'Принимать отчет',
                    key: 'accept',
                    value: false
                },
            ]
        },
        {
            label: "Блок пользователей",
            permissions: [
                {
                    label: 'Просмотр',
                    key: 'read',
                    value: true
                },
                {
                    label: 'Просмотр отчетов',
                    key: 'read_report',
                    value: false
                },
                {
                    label: 'Создать',
                    key: 'create',
                    value: false
                },
                {
                    label: 'Редактировать',
                    key: 'edit',
                    value: false
                },
                {
                    label: 'Удалить',
                    key: 'delete',
                    value: false
                },
            ]
        },
    ])
    const permissions = useSelector(store => store.common.permissions)

    useEffect(()=>{
        setActive(
            getPermission(
                permissions,
                block,
                'edit'
            )
        )
    },[permissions])

    const handleChange = (idx, per , value) => {
        let items = [...staff_permissions]
        items[idx].staff_permissions[per].value = value
        setPermissions([...staff_permissions])
    }

    return (
        <Box
            block={block} 
            permission_key="read"
        >
            <div className="p-mt-3">
                <StaffSingleCard
                    img = { data.img }
                    phone = { data.phone }
                    login = { data.login }
                    email = { data.email }
                    name = { data.name }
                    roles =  { data.roles } 
                    blocks = { block }
                />
            </div>
            <div className="p-mt-3">
                <PanelNevo 
                    title="Разрешения"
                    block={block}
                    permission_key="read_permission"
                >
                    <PermissionTable
                        data={staff_permissions}
                        handleChange={handleChange}
                        block={block}
                        permission_key="edit"
                        showAllCheck={false}
                    />
                    {
                        active?
                        <div className="p-d-flex p-jc-end p-my-3 p-mx-2">
                            <Button
                                icon="pi pi-save"
                                label="Сохранить"
                            />
                        </div>
                        :''
                    }
                </PanelNevo>
            </div>
            <div className="p-mt-3">
                <PanelNevo 
                    title="История действий сотрудника"
                    block={block}
                    permission_key="read_history"
                >
                    <HistoryTable history={item.history}/>
                </PanelNevo>
            </div>
        </Box>
    )
}

export default StaffSingle