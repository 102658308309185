import { commonActionTypes } from '../actions/commonActionTypes'

const initialState = {
  permissions: [
    {
      label: 'Блок авторов',
      key: 'author',
      permissions: [
        {
          label: 'Просмотр',
          key: 'read',
          value: true,
        },
        {
          label: 'Просмотр паспортные данные ',
          key: 'read_passport',
          value: true,
        },
        {
          label: 'Просмотр контракта',
          key: 'read_contract',
          value: true,
        },
        {
          label: 'Просмотр аккаунта',
          key: 'read_account',
          value: true,
        },
        {
          label: 'Просмотр контактных данных',
          key: 'read_contact',
          value: true,
        },
        {
          label: 'Просмотр аналитику',
          key: 'read_analytics',
          value: true,
        },
        {
          label: 'Просмотр историю',
          key: 'read_history',
          value: true,
        },
        {
          label: 'Просмотр отчётов',
          key: 'read_report',
          value: true,
        },
        {
          label: 'Изменить паспортные данные ',
          key: 'edit_passport',
          value: true,
        },
        {
          label: 'Создать контракт',
          key: 'create_contract',
          value: true,
        },
        {
          label: 'Изменить контракт',
          key: 'edit_contract',
          value: false,
        },
        {
          label: 'Изменить аккаунта',
          key: 'edit_account',
          value: true,
        },
        {
          label: 'Изменить контактных данных',
          key: 'edit_contact',
          value: false,
        },
        {
          label: 'Удалить паспортные данные ',
          key: 'delete_passport',
          value: false,
        },
        {
          label: 'Удалить контракта',
          key: 'delete_contract',
          value: true,
        },
        {
          label: 'Удалить аккаунта',
          key: 'delete_account',
          value: true,
        },
        {
          label: 'Удалить контактных данных',
          key: 'delete_contact',
          value: false,
        },
        {
          label: 'Добавить отчёт',
          key: 'create_report',
          value: true,
        },
        {
          label: 'Создать',
          key: 'create',
          value: false,
        },
        {
          label: 'Редактировать',
          key: 'edit',
          value: false,
        },
        {
          label: 'Удалить',
          key: 'delete',
          value: false,
        },
      ],
    },
    {
      label: 'Блок объектов',
      key: 'asset',
      permissions: [
        {
          label: 'Просмотр',
          key: 'read',
          value: true,
        },
        {
          label: 'Просмотр историй',
          key: 'read_history',
          value: true,
        },
        {
          label: 'Просмотр мета данных',
          key: 'read_meta_data',
          value: true,
        },
        {
          label: 'Просмотр участвующих',
          key: 'read_members',
          value: true,
        },
        {
          label: 'Просмотр аналитику',
          key: 'read_analytics',
          value: true,
        },
        {
          label: 'Создать',
          key: 'create',
          value: false,
        },
        {
          label: 'Редактировать',
          key: 'edit',
          value: true,
        },
        {
          label: 'Удалить',
          key: 'delete',
          value: false,
        },
      ],
    },
    {
      label: 'Блок партнеров',
      key: 'partner',
      permissions: [
        {
          label: 'Просмотр',
          key: 'read',
          value: true,
        },
        {
          label: 'Создать',
          key: 'create',
          value: false,
        },
        {
          label: 'Редактировать',
          key: 'edit',
          value: false,
        },
        {
          label: 'Принимать отчет',
          key: 'accept',
          value: false,
        },
      ],
    },
    {
      label: 'Блок пользователей',
      key: 'user',
      permissions: [
        {
          label: 'Просмотр',
          key: 'read',
          value: true,
        },
        {
          label: 'Просмотр отчетов',
          key: 'read_report',
          value: false,
        },
        {
          label: 'Создать',
          key: 'create',
          value: false,
        },
        {
          label: 'Редактировать',
          key: 'edit',
          value: false,
        },
        {
          label: 'Удалить',
          key: 'delete',
          value: false,
        },
      ],
    },
    {
      label: 'Блок сотрудников',
      key: 'staff',
      permissions: [
        {
          label: 'Просмотр',
          key: 'read',
          value: true,
        },
        {
          label: 'Просмотр разрешений',
          key: 'read_permission',
          value: false,
        },
        {
          label: 'Просмотр историй',
          key: 'read_history',
          value: false,
        },
        {
          label: 'Создать',
          key: 'create',
          value: false,
        },
        {
          label: 'Редактировать',
          key: 'edit',
          value: false,
        },
        {
          label: 'Удалить',
          key: 'delete',
          value: false,
        },
      ],
    },
  ],
  openModal: false,
  openModalSinger: false,
  openModalStaff: false,
  openComfirmModal: false,
  // user: {
  //     name: "asdsas",
  //     role: "moderator"
  // } ,
  user: null,
  loading: false,
}

const commonReducer = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case commonActionTypes.OPEN_CREATE_OBJECT_MODAL:
      return {
        ...state,
        openModal: payload,
      }
    case commonActionTypes.OPEN_CREATE_SINGER_MODAL:
      return {
        ...state,
        openModalSinger: payload,
      }
    case commonActionTypes.OPEN_CREATE_STAFF_MODAL:
      return {
        ...state,
        openModalStaff: payload,
      }
    case commonActionTypes.OPEN_CONFIRM_MODAL:
      return {
        ...state,
        openComfirmModal: payload,
      }
    case commonActionTypes.SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      }
    case commonActionTypes.SET_USER:
      return {
        ...state,
        user: payload,
      }
    case commonActionTypes.SET_LOADING:
      return {
        ...state,
        loading: payload,
      }
    default:
      return state
  }
}

export default commonReducer
